import axios from "axios";
import { BarcodeAlertDto, createBarcodeAlertDto } from "./types";

const BARCODE_ALERT_URL = "https://www.atterlic.com/v1/api/barcodes";


export const barcodeAlertAPi = {
  getBarcodeAlertList: async (params: getBarcodeAlertListDto): Promise<BarcodeAlertDto[]> => {
    return await axios.get(`${BARCODE_ALERT_URL}`, { params }).then((res) => res.data.map((v: any) => ({ ...v, is_active: !!v.is_active })));
  },

  getActiveBarCodeAlertList: async (params: getBarcodeAlertListDto): Promise<BarcodeAlertDto[]> => {
    return await axios.get(`${BARCODE_ALERT_URL}/active`, { params }).then((res) => res.data.map((v: any) => ({ ...v, is_active: !!v.is_active })));
  },

  createBarcodeAlert: async (body: createBarcodeAlertDto[]): Promise<createBarcodeAlertDto[]> => {
    return await axios.post(BARCODE_ALERT_URL, body).then((res) => res.data);
  },

  updateBarcodeAlert: async (body: BarcodeAlertDto[]): Promise<BarcodeAlertDto[]> => {
    return await axios.put(BARCODE_ALERT_URL, body).then((res) => res.data);
  },

  deleteBarcodeAlert: async (body: number[]): Promise<number[]> => {
    return await axios.post(`${BARCODE_ALERT_URL}/delete`, body).then((res) => res.data);
  },
};

export interface getBarcodeAlertListDto {
  barcode: string;
  type?:string;
}
