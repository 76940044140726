import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";
import { getOrderListResponseDto } from "../../../service/order/types";
import { Id } from "../../../shared/type/Id";
import RowStack from "../../../shared/ui/RowStack";
import { useBrandCodeStore } from "../../../store/brandStore";
import SearchInputField from "../../../widget/form/ui/SearchInpuField";
import RefreshIconButton from "../../../shared/ui/buttom/RefreshIconButton";
import { useOrderStatusStore } from "../../../store/orderStatus";
import { FormControl, RadioGroup, Radio, Chip, Pagination } from "@mui/material";
import { customToast } from "../../../shared/lib/customToast";
import { useDeleteOrder } from "../../../service/order/orderHooks";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate } from "react-router-dom";
import { dataGridStyle } from "../../../shared/style/mui-table-style/MuiTableStyle";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string | any }, b: { [key in Key]: number | string | any }) => number {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  width: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: "OrderAlias",
    numeric: false,
    disablePadding: false,
    width: "400px",
    label: "발주서 별명",
  },
  {
    id: "Center",
    numeric: true,
    disablePadding: false,
    width: "90px",
    label: "센터",
  },
  {
    id: "OrderDate",
    numeric: true,
    disablePadding: false,
    width: "90px",
    label: "발주일",
  },
  {
    id: "ReceiveDate",
    numeric: true,
    disablePadding: false,
    width: "90px",
    label: "입고일",
  },
  {
    id: "sumQuantity",
    numeric: true,
    disablePadding: false,
    width: "90px",
    label: "발주수량",
  },
  {
    id: "sumQuantityOK",
    numeric: true,
    disablePadding: false,
    width: "90px",
    label: "확정수량",
  },
  {
    id: "sumQuantityReady",
    numeric: true,
    disablePadding: false,
    width: "90px",
    label: "준비수량",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    width: "60px",
    label: "상태",
  },
  {
    id: "delete",
    numeric: true,
    disablePadding: false,
    width: "90px",
    label: "삭제",
  },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: headCell.width }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface Props {
  data: (getOrderListResponseDto & Id)[] | undefined;
  refetch: () => void;
}

const OrderListTable2: React.FC<Props> = ({ data, refetch }) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("ReceiveDate");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const { BrandName, BrandImage } = useBrandCodeStore((c) => c);
  const { status, setStatus } = useOrderStatusStore((v) => v);
  const [filter, setFilter] = React.useState<string>("");
  const { BrandCode } = useBrandCodeStore((s) => s);
  const { mutate: deleteOrder } = useDeleteOrder({ BrandCode, status });

  const navigate = useNavigate();

  const handleDeleteOrder = (row: any) => {
    if (row.sumQuantityReady === 0 && confirm("삭제하면 다시는 복구할 수 없습니다. 정말 삭제하시겠습니까?")) {
      const text = prompt(`삭제하시려면 [${row.OrderAlias}] 을/를 입력해주세요.`);
      if (text === row.OrderAlias) {
        deleteOrder({ OrderID: row.OrderID });
      } else {
        customToast("warn", "입력하신 발주서 별명이 일치하지 않습니다.");
      }
    } else {
      customToast("warn", "준비수량이 0 이상인 발주서는 삭제할 수 없습니다. ");
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeStatus = (event: any) => {
    setStatus(event.target.value);
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const filteredRow = React.useMemo(() => {
    if (filter === "") return data;
    return data?.filter((row) => row.OrderAlias.includes(filter));
  }, [data, filter]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (data?.length || 0)) : 0;

  const visibleRows = React.useMemo(
    () => (filteredRow ? [...filteredRow].sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : []),
    [order, orderBy, page, rowsPerPage, filteredRow]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <RowStack sx={{ p: 2 }}>
            <RowStack gap={2}>
              <img width={30} src={BrandImage} alt="브랜드" />
              <Typography>{BrandName}</Typography>
            </RowStack>
            <RowStack gap={2}>
              <FormControl sx={{ ml: 3, mr: "auto" }}>
                <RadioGroup sx={{ display: "flex", flexDirection: "row", gap: 5 }} value={status} onChange={handleChangeStatus}>
                  <FormControlLabel value="전체" control={<Radio />} label="전체" />
                  <FormControlLabel value="R" control={<Radio />} label="준비" />
                  <FormControlLabel value="E" control={<Radio />} label="완료" />
                </RadioGroup>
              </FormControl>
              <SearchInputField value={filter} onChange={(e) => setFilter(e.target.value)} />
              <RefreshIconButton onClick={() => refetch()} />
            </RowStack>
          </RowStack>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const isPreConfirm = row.OrderAlias.includes("사전확정");
                const isZero = row.sumQuantityReady === 0;
                const isDeletable = !isPreConfirm && isZero;

                return (
                  <TableRow
                    hover
                    onClick={(event) => {
                      navigate(`/orders/${row.OrderID}`);
                    }}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: "pointer", borderLeft: `10px solid ${colorSet(row)}` }}
                  >
                    <TableCell component="th" id={labelId} scope="row">
                      {row.OrderAlias} {row.IsFlex ? <Chip size="small" label="flex" color="primary" variant="outlined" /> : null}
                    </TableCell>
                    <TableCell align="right">{row.Center}</TableCell>
                    <TableCell align="right">{row.OrderDate}</TableCell>
                    <TableCell align="right">{row.ReceiveDate}</TableCell>
                    <TableCell align="right">{row.sumQuantity.toLocaleString()}</TableCell>
                    <TableCell align="right">{row.sumQuantityOK.toLocaleString()}</TableCell>
                    <TableCell align="right">{row.sumQuantityReady.toLocaleString()}</TableCell>
                    <TableCell align="right">
                      {row.Status === "R" ? <Chip label="준비" variant="outlined" color="primary" /> : <Chip label="완료" />}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleDeleteOrder(row)}>
                        <DeleteOutlineIcon color={isDeletable ? "primary" : "disabled"} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[30, 40, 50]}
          component="div"
          count={data?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="페이지당 행:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count} 개`}
        />
      </Paper>

      <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="작게보기" />
    </Box>
  );
};

export default OrderListTable2;

function colorSet(row: any) {
  if (row.OrderAlias.includes("사전확정")) {
    return "#dbfcfe";
  } else if (row.IsFlex) {
    return "#6799ff";
  } else {
    return "#efefef";
  }
}
