import { createBrowserRouter } from "react-router-dom";
import Layout from "../pages/Layout";
import ErrorBoundary from "../entities/error/ui/ErrorBoundary";
import Login from "../pages/Login";
import { nav_items2, NavItem } from "./nav-config";

import IndividualOrder from "../pages/IndividualOrder";
import CreateProductForm from "../pages/CreateProductForm";
import UpdateProductForm from "../pages/UpdateProductForm";

export const MODE = process.env.REACT_APP_MODE;

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      ...nav_items2
        .filter((v: any) => {
          if (MODE === "PROV" && v.title === "개발") return false;
          return true;
        })
        .reduce((acc: any[], cur: NavItem) => {
          const result = cur.children
            ? cur.children.map((item: NavItem) => ({
                path: item.link,
                element: item.element,
              }))
            : [
                {
                  path: cur.link,
                  element: cur.element,
                },
              ];

          return [...acc, ...result];
        }, []),
      {
        path: "orders/:id",
        element: <IndividualOrder />,
      },
      {
        path: "products/update/:id",
        element: <UpdateProductForm />,
      },
      {
        path: "products/create",
        element: <CreateProductForm />,
      },
    ],
  },
]);
