import axios from "axios";

import { clearUserInfo } from "../store/UserInfoStore";

// 원격 서버

// export const URL = process.env.REACT_APP_API_URL;
export const URL = process.env.REACT_APP_API_URL;
export const MODE = process.env.REACT_APP_MODE;

const dev = {
  // baseURL: URL,
  // withCredentials: true, // withCredentials를 true로 설정하여 CORS 요청 시 쿠키를 포함합니다
};

const prov = {
  baseURL: URL,
  withCredentials: true, // withCredentials를 true로 설정하여 CORS 요청 시 쿠키를 포함합니다
};

export const customAxios = axios.create(MODE === "DEV" ? dev : prov);
// export const customAxios = axios.create({
//   baseURL: URL,
//   withCredentials: true, // withCredentials를 true로 설정하여 CORS 요청 시 쿠키를 포함합니다
// });

customAxios.interceptors.request.use(
  //axios 요청 직전에 발생하는 함수
  function (config) {
    return config;
  },
  //axios 요청 에러나면 발생하는 함수
  function (error) {
    return Promise.reject(error);
  }
);

customAxios.interceptors.response.use(
  //axios 올바른 response가오면 발동하는 함수
  function (response) {
    return response;
  },

  //에러 메시지가 response되면 발동되는 함수
  async function (error) {
    console.log(error);
    if (error.response?.status === 401) {
      //401 에러가 뜨면 로그인 페이지로 이동
      clearUserInfo();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);
