import { Card, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { getOrderListResponseDto } from "../../../service/order/types";
import { Id } from "../../../shared/type/Id";
import { getCurrentFormattedTime } from "../../../shared/lib/getCurrentFormattedTime";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate } from "react-router-dom";
interface Props {
  data: (getOrderListResponseDto & Id)[] | undefined;
}

const PriorityOrderList: React.FC<Props> = ({ data }) => {
  const today = "20" + getCurrentFormattedTime().slice(0, 8);
  const navigate = useNavigate();
  const handleMoveToOrderDetail = (OrderID: any) => {
    navigate(`/orders/${OrderID}`);
  };

  return (
    <Card sx={{ p: 2, height: 250, boxSizing: "border-box" }}>
      <List sx={{ overflow: "auto" }}>
        <ListSubheader sx={{ color: "#999" }}>오늘 마감건</ListSubheader>
        {data
          ?.filter((v) => v.ReceiveDate === today)
          .map((v) => (
            <ListItemButton
              key={v.id}
              sx={{ borderTop: "1px solid #e0e0e0" }}
              onClick={() => {
                handleMoveToOrderDetail(v.OrderID);
              }}
            >
              <ListItemIcon>
                <OpenInNewIcon />
              </ListItemIcon>
              <ListItemText primary={v.OrderAlias} />
            </ListItemButton>
          ))}
      </List>
    </Card>
  );
};

export default PriorityOrderList;
