import { debounce, TextField } from "@mui/material";
import { getPreProductDto } from "../../../../service/product/types";
import LinkCard from "../LinkCard";
import LinkSubTitle from "../LinkSubTitle";
import { useState, useEffect, useCallback } from "react";

interface Props {
  data: getPreProductDto;
  onChange: React.Dispatch<React.SetStateAction<getPreProductDto>>;
}

const ProductSearchTag: React.FC<Props> = ({ data, onChange }) => {
  const [text, setText] = useState<string>("");

  useEffect(() => {
    setText(data.SearchTag || "");
  }, [data.SearchTag]);

  const debouncedFetchSearchResult = useCallback(
    debounce((value) => {
      onChange((prev: getPreProductDto) => ({ ...prev, SearchTag: value }));
    }, 400),
    []
  );

  const handleChange = (e: any) => {
    const { value } = e.target;
    setText(value);
    debouncedFetchSearchResult(value);
  };

  return (
    <LinkCard>
      <LinkSubTitle title="검색 태그" required />
      <TextField
        value={text}
        onChange={handleChange}
        placeholder="ex) 데일리티셔츠, 캐주얼후드티 ..."
        helperText="검색태그를 최소 5개 입력해주세요. 검색태그는 컴마(,)로 구분됩니다."
        InputProps={{
          endAdornment: <span style={{ fontSize: "12px", color: "#BDBDBD" }}>{text ? text.split(",").length : 0}/5</span>,
        }}
        error={text.split(",").length < 5}
      />
    </LinkCard>
  );
};

export default ProductSearchTag;
