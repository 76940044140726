import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { getRocketFindListResponseDto } from "../../../service/rocket/types";
import { useGetRocketFind } from "../../../service/rocket/rocketHooks";
import { useBrandCodeStore } from "../../../store/brandStore";
import BackDropLoading from "../../../shared/ui/loading/BackDropLoading";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import _ from "lodash";
import DownloadIcon from "@mui/icons-material/Download";
import { ChangeBrandButton } from "../../side-navigation/ui/change-brand-modal/ChangeBrandButton";
import RocketItem from "./RocketItem";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { exportToExcel } from "../../../shared/lib/exportToExcel";
import { KEY_LIST, COLUMN_LIST, FILE_NAME } from "../const/const";
import RowStack from "../../../shared/ui/RowStack";
import SearchInputField from "../../../widget/form/ui/SearchInpuField";
import { inventoryApi } from "../../../service/inventory/inventoryAxios";
import { customToast } from "../../../shared/lib/customToast";

export const SUBTITLE_COLOT = "#999";
export const MAIN_CONTENT_COLOR = "#333";

const INTERVAL = 20;
const RocketTable = () => {
  const BrandCode = useBrandCodeStore((state) => state.BrandCode);
  const { data, refetch, isFetching } = useGetRocketFind({ brand: BrandCode });
  const [filters, setFilters] = useState<string>("");
  const [isLoading, setIsloding] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");
  const [location, setLocation] = useState<string>("전체");
  const [viewList, setViewList] = useState<getRocketFindListResponseDto[]>(data ? [...data.slice(0, INTERVAL)] : []);
  const [viewIndex, setViewIndex] = useState<number>(0);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const observerRef = useRef(null);

  //view index가 변경될때마다 확인하기
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        //콜백함수
        if (data && viewList.length < data.length && entries[0].isIntersecting) {
          setViewList([...viewList, ...data.slice(viewIndex, viewIndex + INTERVAL)]);
          setViewIndex(viewIndex + INTERVAL);
        }
      },
      { threshold: 0.8 }
    );

    if (observerRef.current) observer.observe(observerRef.current); //관찰시작

    // 컴포넌트가 언마운트될 때 옵저버 해제
    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current); //관찰해제
    };
  }, [viewList, viewIndex]);

  useEffect(() => {
    setViewList(data ? [...data.slice(0, INTERVAL)] : []); //스프레드로 초기화 해야 viewList가 변경될것 같았음
    setViewIndex(INTERVAL); //인덱스 초기화
  }, [data]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };
  const handleLocationChange = (e: any) => {
    setLocation(e.target.value as string);
  };


  // debounce로 상태 업데이트 지연
  const updateFilters = useCallback(
    _.debounce((value) => {
      setFilters(value);
    }, 300), // 300ms 지연
    []
  );

  // 입력이 즉각적으로 반영되도록 하고, debounce로 상태 업데이트 지연
  const onChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value); // 입력 필드에 즉시 반영
    updateFilters(value); // 상태 업데이트는 지연
  };

  //검색한 결과를 필터링하는 함수
  const filterdRow = useMemo(() => {
    if (!viewList) return [];
    if (location === "전채" && filters === "") return viewList;

    return viewList
      .filter((item: getRocketFindListResponseDto) => {
        // anyMatch 조건 간소화
        return (["BarCode", "Color", "ProductName_ko", "RackID", "Size"] as (keyof getRocketFindListResponseDto)[]).some((key) => {
          const value = item[key];
          return value?.toString().includes(filters);
        });
      })
      .filter((v) => {
        return location === "전체" || v.RackID === null ? true : v.RackID.toString().startsWith(location);
      });
  }, [viewList, filters, location]);

  //검색한 결과의 개수와 찾을 상품 수를 계산하는 함수
  const { listCount, totalFindCount, chunguiDong, samjungDong } = useMemo(() => {
    let listCount = 0;
    let totalFindCount = 0;
    let samjungDong = 0;
    let chunguiDong = 0;

    if (!!data) {
      data.forEach((item) => {
        listCount += 1;
        totalFindCount += item.Quantity;

        if (!!item.RackID) {
          if (item.RackID?.toString().startsWith("7")) {
            chunguiDong += 1;
          } else {
            samjungDong += 1;
          }
        }
      });
    }

    return { listCount, totalFindCount, chunguiDong, samjungDong };
  }, [data]);

  const handleExportToExcel = useCallback(async () => {
    setIsloding(true);
    if (data && data.length !== 0) {
      const newData = [];
      for (let i = 0; i < data.length; i++) {
        const BarCode = data[i].BarCode;
        const inventory = await inventoryApi.getInventoryByProductId({ BarCode });
        let Stock = 0;
        const RackID = inventory
          .map((v) => {
            let text = "";
            if (v.BasketID) {
              text = `${v.BasketID}(${v.Quantity})`;
            }

            if (v.RackID) {
              text = `${v.RackID}(${v.Quantity})`;
            }
            Stock += v.Quantity;
            return text;
          })
          .join(" \r\n");

        const item = { ...data[i], RackID, Stock };
        newData.push(item);
      }
      exportToExcel({ data: newData || [], keyList: KEY_LIST, columns: COLUMN_LIST, fileName: FILE_NAME });
    } else {
      customToast("info", "다운로드할 데이터가 없습니다.");
    }

    setIsloding(false);
  }, [data]);

  return (
    <>
      <Stack sx={{ margin: "0 auto", my: 10, width: "1200px" }} gap={4}>
        <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
          로켓찾기
        </Typography>
        <BackDropLoading open={isFetching || isLoading} />
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Box sx={{ width: "90px", ml: 4 }}>
                  <ChangeBrandButton />
                </Box>
                <Box sx={{ width: "120px" }}>
                  <Typography variant="caption" color={SUBTITLE_COLOT}>
                    찾을 상품 수
                  </Typography>
                  <Typography color={MAIN_CONTENT_COLOR} variant="h5">
                    {totalFindCount} 건
                  </Typography>
                </Box>
                <Box sx={{ width: "120px" }}>
                  <Typography variant="caption" color={SUBTITLE_COLOT}>
                    목록
                  </Typography>
                  <Typography color={MAIN_CONTENT_COLOR} variant="h5">
                    {listCount} 건
                  </Typography>
                </Box>
                <Box sx={{ width: "120px" }}>
                  <Typography variant="caption" color={SUBTITLE_COLOT}>
                    삼정동
                  </Typography>
                  <Typography color={MAIN_CONTENT_COLOR} variant="h5">
                    {samjungDong} 건
                  </Typography>
                </Box>
                <Box sx={{ width: "120px", mr: "auto" }}>
                  <Typography variant="caption" color={SUBTITLE_COLOT}>
                    춘의동
                  </Typography>
                  <Typography color={MAIN_CONTENT_COLOR} variant="h5">
                    {chunguiDong} 건
                  </Typography>
                </Box>

                {/* 검색창 */}

                <SearchInputField value={inputValue} onChange={onChange} />

                <IconButton>
                  <RefreshIcon onClick={() => refetch()} />
                </IconButton>
                <IconButton
                  id="basic-button"
                  aria-controls={menuOpen ? "basic-menu" : undefined}
                  aria-expanded={menuOpen ? "true" : undefined}
                  onClick={handleOpenMenu}
                >
                  <MoreVertOutlinedIcon />
                </IconButton>

                <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleCloseMenu}>
                  <MenuItem onClick={handleExportToExcel}>
                    <Tooltip
                      title={
                        <pre style={{ fontSize: "12px" }}>
                          부족 수량이 0이상인
                          <br />
                          제품만 다운로드됩니다.
                        </pre>
                      }
                      arrow
                      placement="top"
                    >
                      <>
                        <ListItemIcon>
                          <DownloadIcon />
                        </ListItemIcon>
                        엑셀 다운로드
                      </>
                    </Tooltip>
                  </MenuItem>
                </Menu>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <RowStack gap={5}>
              <FormControl sx={{ ml: 3, mr: "auto" }}>
                <RadioGroup sx={{ display: "flex", flexDirection: "row", gap: 5 }} value={location} onChange={handleLocationChange}>
                  <FormControlLabel value="전체" control={<Radio />} label="전체" />
                  <FormControlLabel value="7" control={<Radio />} label="춘의동" />
                  <FormControlLabel value="3" control={<Radio />} label="삼정동" />
                </RadioGroup>
              </FormControl>
            </RowStack>
          </Grid>
          {filterdRow?.map((item, i) => (
            <RocketItem key={i} data={item} />
          ))}
          {data && viewList.length !== data.length && (
            <Typography variant="subtitle1" ref={observerRef} sx={{ textAlign: "cetner" }}>
              더보기
            </Typography>
          )}
        </Grid>
      </Stack>
    </>
  );
};

export default RocketTable;
