import React from "react";
import { useModals } from "../../service/modal/useModals";
import { CustomButton } from "../../shared/ui/buttom/CustomButton";
import { ShowProductListSameNameModal } from "./ShowProductListSameNameModal";
import { ItemResponse } from "../../service/order/types";

interface Props {
  ProductInfo: ItemResponse;
  OrderAlias: string;
  onUpdate: () => void;
}

const ShowProductListSameNameButton: React.FC<Props> = ({ ProductInfo, OrderAlias, onUpdate }) => {
  const { closeModal, openModal } = useModals();

  const handleClose = () => {
    closeModal(ShowProductListSameNameModal);
  };

  const handleClickOpen = () => {
    openModal(ShowProductListSameNameModal, { ProductInfo, handleClose, OrderAlias, onUpdate });
  };

  return (
    <React.Fragment>
      <CustomButton onClick={handleClickOpen}>{ProductInfo.Size}</CustomButton>
    </React.Fragment>
  );
};

export default ShowProductListSameNameButton;
