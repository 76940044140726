import {
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Box,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { useState, useEffect } from "react";
import { ItemResponse } from "../../../../service/order/types";
import { useUpdateProductBulk } from "../../../../service/product/productHooks";
import Checkbox from "@mui/material/Checkbox";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY } from "../../../../config/querykey";

interface TableProps {
  data: ItemResponse[];
  isLowPriceTable?: boolean;
}
const IssueListTable: React.FC<TableProps> = ({ data, isLowPriceTable = false }) => {
  const queryClient = useQueryClient();
  const [editData, setEditData] = useState<(ItemResponse & { edit: boolean; checked: boolean })[]>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { mutate } = useUpdateProductBulk((data) => {});
  useEffect(() => {
    setEditData(data.map((v) => ({ ...v, edit: false, checked: false })));
  }, [data]);

  const handleChangeData = (BarCode: string, e: any) => {
    let { value, id } = e.target;
    value = Number(value.replace(/[^0-9]/g, "")); // 숫자로 변경    setIsEdit(true);

    if (!isNaN(value)) {
      const newData = editData?.map((v) => {
        if (v.BarCode === BarCode) {
          return { ...v, [id]: value, edit: true };
        }
        return v;
      });
      setIsEdit(true);
      setEditData(newData);
    }
  };

  const handleSubmit = () => {
    const dto: any = editData
      ?.filter((v) => v.edit)
      .map((v) => {
        let data: any = {
          BarCode: v.BarCode,
          QuotedPrice: v.QuotedPrice || undefined,
          RocketMargin: v.RocketMargin || undefined,
        };

        if (v.checked) {
          // 체크표시 있는것만 메모 추가
          data = { ...data, Memo0: (v.Memo0 || "") + "\n(마진 1000원 미만 확인 완료)" };
        }
        return data;
      });

    if (dto) {
      mutate(dto);

      

      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.order] });
    }
    setIsEdit(false);
    setEditData((prev) => prev?.map((v) => ({ ...v, edit: false })));
  };

  const handleChecked = (BarCode: string, e: any) => {
    let { checked } = e.target;
    const newData = editData?.map((v) => {
      if (v.BarCode === BarCode) {
        return { ...v, checked, edit: true };
      }
      return v;
    });
    setIsEdit(true);
    setEditData(newData);
  };

  if (data?.length === 0) {
    return <DialogContent>데이터가 없습니다.</DialogContent>;
  }

  return (
    <>
      <TableContainer sx={{ height: "550px", boxSizing: "border-box", border: "0.5px solid #DFDFDF" }}>
        <Table aria-label="simple table" sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>바코드</TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                상품명
              </TableCell>
              <TableCell align="right" sx={{ width: "100px", fontWeight: "bold" }}>
                발주서 매입가
              </TableCell>
              <TableCell align="right" sx={{ width: "100px", fontWeight: "bold" }}>
                페프 견적가
              </TableCell>
              <TableCell align="right" sx={{ width: "80px", fontWeight: "bold" }}>
                로켓 마진
              </TableCell>
              {isLowPriceTable && (
                <TableCell align="right" sx={{ width: "80px", fontWeight: "bold" }}>
                  1000원 미만 마진 확인
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {editData &&
              editData.map((v, i) => (
                <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {v.BarCode}
                  </TableCell>
                  <TableCell align="left">{v.ProductName_ko}</TableCell>
                  <TableCell align="right">{v.SupplyPrice?.toLocaleString() || "-"}</TableCell>

                  <TableCell align="right">
                    <TextField id={"QuotedPrice"} size="small" value={v.QuotedPrice} onChange={(e) => handleChangeData(v.BarCode, e)} />
                  </TableCell>
                  <TableCell align="right">
                    <TextField id={"RocketMargin"} size="small" value={v.RocketMargin} onChange={(e) => handleChangeData(v.BarCode, e)} />
                  </TableCell>
                  {isLowPriceTable && (
                    <TableCell align="right">
                      <Checkbox checked={v.checked} onChange={(e) => handleChecked(v.BarCode, e)} />
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ border: "1px solid #dfdfdf", borderTop: "0px ", p: 3 }}>
        <Typography variant="body2" color="grey" textAlign={"right"}>
          총 제품수 : {data.length}개
        </Typography>
      </Box>

      <Stack direction={"row-reverse"}>
        <Button variant="contained" sx={{ mt: 2, width: "120px", height: "50px`" }} onClick={handleSubmit} disabled={!isEdit}>
          수정
        </Button>
      </Stack>
    </>
  );
};

export default IssueListTable;
