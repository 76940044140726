import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  List,
  ListItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ShowInventoryButton from "../widget/show-inventory-modal/ui/ShowInventoryButton";
import { useEffect, useReducer } from "react";
import { rackApi } from "../service/rack/rackAxios";
import { customToast } from "../shared/lib/customToast";
import { basketApi } from "../service/basket/BasketAxios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useRackToRack, useUpdateProductWithRack } from "../service/product/productHooks";
import { moveRackToRackDto } from "../service/product/types";
import { inisialData } from "../entities/move-inventory/const/initialData";
import { moveInventoryReducer } from "../entities/move-inventory/lib/moveInventoryReducer";

const MoveInventory = () => {
  const { mutateAsync: moveInventory } = useUpdateProductWithRack(() => {});
  const { mutateAsync: moveRackToRack } = useRackToRack();
  const [state, dispatch] = useReducer(moveInventoryReducer, inisialData);

  const { leftNumber, rightNumber, leftType, rightType, leftSelected, rightSelected, leftData, rightData, history } = state;

  //처음에 초기화
  useEffect(() => {
    dispatch({ type: "INIT" });
  }, []);

  const handleGetData = (type: string) => {
    const id = type === "LEFT" ? leftNumber : rightNumber;

    if (leftNumber === rightNumber) {
      customToast("error", "동일한 랙/바구니 번호를 입력할 수 없습니다.");
    }

    if (id.length === 7) {
      rackApi
        .getRack({ RackID: id })
        .then((res) => {
          dispatch({ type: `SET_${type}_DATA`, payload: res.inventory || [] });
          dispatch({ type: `SET_${type}_TYPE`, payload: "rack" });
        })
        .catch(() => {
          customToast("error", "해당 랙 또는 바구니가 존재하지 않습니다.");
          dispatch({ type: `SET_${type}_TYPE`, payload: "" });
        });
    } else if (id.length === 6) {
      basketApi
        .getBasket({ BasketID: id })
        .then((res) => {
          dispatch({ type: `SET_${type}_DATA`, payload: res.inventory || [] });
          dispatch({ type: `SET_${type}_TYPE`, payload: "basket" });
        })
        .catch(() => {
          customToast("error", "해당 랙 또는 바구니가 존재하지 않습니다.");
          dispatch({ type: `SET_${type}_TYPE`, payload: "" });
        });
    } else {
      customToast("error", "바구니, 랙 번호는 6자리 또는 7자리로 입력해주세요.");
    }
  };

  const handleInit = () => {
    handleGetData("LEFT");
    handleGetData("RIGHT");
    dispatch({ type: "SET_SELECTED_CLEAR" });
  };

  const handleMove = async (data: {
    currentNumber: string;
    currentType: string;
    currentData: any[];
    targetNumber: string;
    targetType: string;
    targetData: any[];
    currentSelected: string[];
    targetSelected: string[];
  }) => {
    const { currentNumber, currentType, currentData, currentSelected, targetNumber, targetType, targetData, targetSelected } = data;
    let Type = "";

    if (currentSelected.length === 0) {
      customToast("error", "제품을 선택해 주세요.");
    } else if (currentType === "" || targetType === "") {
      customToast("error", "데이터를 조회해주세요.");
      return;
    } else if (currentType === "rack" && targetType === "rack") {
      Type = "RR";

      let dto: moveRackToRackDto = {
        fromRack: currentNumber,
        toRack: targetNumber,
        data: currentData.filter((v: any) => currentSelected.includes(v.BarCode)).map((v: any) => ({ Barcode: v.BarCode, Count: v.Quantity })),
      };

      await moveRackToRack(dto);
      dispatch({
        type: "SET_HISTORY",
        payload: [
          ...history,
          ...dto.data.map((v: any) => ({
            BarCode: v.Barcode,
            from: currentNumber,
            to: targetNumber,
            Quantity: v.Count,
            time: new Date().toLocaleString(),
          })),
        ],
      });
      handleInit();

      return;
    } else if (currentType === "rack" && targetType === "basket") {
      Type = "RB";
    } else if (currentType === "basket" && targetType === "rack") {
      Type = "BR";
    } else if (currentType === "basket" && targetType === "basket") {
      // 테이블로 갔다가 다시 바구니로 가야함

      const dtoList1 = currentData
        .filter((v: any) => currentSelected.includes(v.BarCode))
        .map((v: any) => ({ BarCode: v.BarCode, count: v.Quantity, Type: "BT", from: currentNumber, to: "table" }));

      const dtoList2 = dtoList1.map((v: any) => ({ ...v, Type: "TB", from: "table", to: targetNumber }));

      for (const dto of dtoList1) {
        await moveInventory(dto as any);
      }

      for (const dto of dtoList2) {
        await moveInventory(dto as any);
      }

      dispatch({
        type: "SET_HISTORY",
        payload: [
          ...history,
          ...dtoList2.map((v: any) => ({ ...v, Quantity: v.count, from: currentNumber, to: targetNumber, time: new Date().toLocaleString() })),
        ],
      });
      handleInit();
      return;
    }

    // RB BR 일경우만 진행
    if (!!Type) {
      const dtoList = currentData
        .filter((v: any) => currentSelected.includes(v.BarCode))
        .map((v: any) => ({ BarCode: v.BarCode, count: v.Quantity, Type: Type, from: currentNumber, to: targetNumber }));

      for (const dto of dtoList) {
        await moveInventory(dto as any);
      }
      dispatch({
        type: "SET_HISTORY",
        payload: [
          ...history,
          ...dtoList.map((v: any) => ({ Quantity: v.count, ...v, from: currentNumber, to: targetNumber, time: new Date().toLocaleString() })),
        ],
      });
    }

    handleInit();
  };

  return (
    <Box sx={{ height: "100%", margin: "50px auto" }}>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} gap={3}>
        <Card>
          <Stack direction="row" gap={2}>
            <TextField
              fullWidth
              placeholder="바구니, 랙번호 입력"
              value={leftNumber}
              onChange={(e) => dispatch({ type: "SET_LEFT_NUMBER", payload: e.target.value.trim() })}
            />
            <Button variant="contained" onClick={() => handleGetData("LEFT")}>
              조회
            </Button>
          </Stack>

          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button onClick={() => dispatch({ type: "SET_LEFT_SELECTED_ALL" })}>전체선택</Button>
            <Typography variant="subtitle2" color="primary" sx={{ my: 2 }}>
              선택된 항목 : {leftSelected.length} 개
            </Typography>
          </Stack>
          <TableContainer sx={{ height: 500, width: 700, boxSizing: "border-box", border: "0.5px solid #DFDFDF" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }} align={"center"}>
                    선택
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", maxWidth: "150px" }} align={"center"}>
                    상품코드
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align={"center"}>
                    상품명
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align={"center"}>
                    색상
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align={"center"}>
                    사이즈
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align={"center"}>
                    재고
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leftType ? (
                  leftData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center" sx={{ color: "#999", height: "411px" }}>
                        {leftType === "rack" ? "해당 랙에 상품이 없습니다." : "해당 바구니에 상품이 없습니다."}
                      </TableCell>
                    </TableRow>
                  ) : (
                    leftData.map((row: any) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.BarCode}>
                          <TableCell align="center">
                            <Checkbox
                              checked={leftSelected.includes(row.BarCode)}
                              onClick={() => dispatch({ type: "SET_LEFT_SELECTED", payload: row.BarCode })}
                            />
                          </TableCell>
                          <TableCell align="center">{row.BarCode}</TableCell>
                          <TableCell align="left">{row.ProductName_ko}</TableCell>
                          <TableCell align="center">{row.Color}</TableCell>
                          <TableCell align="center">{row.Size}</TableCell>
                          <TableCell align="center">
                            <ShowInventoryButton BarCode={row.BarCode} TotalCount={row.Quantity} />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center" sx={{ color: "#999", height: "411px" }}>
                      데이터를 조회하세요.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        <Stack gap={6}>
          <Button
            disabled={leftSelected.length === 0 || rightType === "" || leftType === "" || leftNumber === rightNumber}
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            sx={{ width: "150px", height: "50px" }}
            onClick={() => {
              handleMove({
                currentNumber: leftNumber,
                currentType: leftType,
                currentData: leftData,
                currentSelected: leftSelected,
                targetNumber: rightNumber,
                targetType: rightType,
                targetData: rightData,
                targetSelected: rightSelected,
              });
            }}
          >
            상품 이동
          </Button>
          <Button
            disabled={rightSelected.length === 0 || rightType === "" || leftType === "" || leftNumber === rightNumber}
            variant="contained"
            startIcon={<ArrowBackIcon />}
            sx={{ width: "150px", height: "50px" }}
            onClick={() => {
              handleMove({
                currentNumber: rightNumber,
                currentType: rightType,
                currentData: rightData,
                currentSelected: rightSelected,
                targetNumber: leftNumber,
                targetType: leftType,
                targetData: leftData,
                targetSelected: leftSelected,
              });
            }}
          >
            상품 이동
          </Button>
        </Stack>

        <Card>
          <Stack direction="row" gap={2}>
            <TextField
              fullWidth
              placeholder="바구니, 랙번호 입력"
              value={rightNumber}
              onChange={(e) => dispatch({ type: "SET_RIGHT_NUMBER", payload: e.target.value.trim() })}
            />
            <Button variant="contained" onClick={() => handleGetData("RIGHT")}>
              조회
            </Button>
          </Stack>

          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button onClick={() => dispatch({ type: "SET_RIGHT_SELECTED_ALL" })}>전체선택</Button>
            <Typography variant="subtitle2" color="primary" sx={{ my: 2 }}>
              선택된 항목 : {rightSelected.length} 개
            </Typography>
          </Stack>

          <TableContainer sx={{ height: 500, width: 700, boxSizing: "border-box", border: "0.5px solid #DFDFDF" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }} align={"center"} onClick={() => dispatch({ type: "SET_RIGHT_SELECTED_ALL" })}>
                    선택
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", maxWidth: "150px" }} align={"center"}>
                    상품코드
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align={"center"}>
                    상품명
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align={"center"}>
                    색상
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align={"center"}>
                    사이즈
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align={"center"}>
                    재고
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rightType ? (
                  rightData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center" sx={{ color: "#999", height: "411px" }}>
                        {rightType === "rack" ? "해당 랙에 상품이 없습니다." : "해당 바구니에 상품이 없습니다."}
                      </TableCell>
                    </TableRow>
                  ) : (
                    rightData.map((row: any) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.BarCode}>
                          <TableCell align="center">
                            <Checkbox
                              checked={rightSelected.includes(row.BarCode)}
                              onClick={() => dispatch({ type: "SET_RIGHT_SELECTED", payload: row.BarCode })}
                            />
                          </TableCell>
                          <TableCell align="center">{row.BarCode}</TableCell>
                          <TableCell align="left">{row.ProductName_ko}</TableCell>
                          <TableCell align="center">{row.Color}</TableCell>
                          <TableCell align="center">{row.Size}</TableCell>
                          <TableCell align="center">
                            <ShowInventoryButton BarCode={row.BarCode} TotalCount={row.Quantity} />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center" sx={{ color: "#999", height: "411px" }}>
                      데이터를 조회하세요.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Stack>
      <Card sx={{ mt: 5, height: 190 }}>
        <Typography color="#333" variant="subtitle1" sx={{ mb: 1 }}>
          재고이동 기록
        </Typography>
        <List sx={{ border: "1px solid #dfdfdf", height: "70%", overflow: "auto" }}>
          {history.map((v: any, i: number) => (
            <ListItem sx={history.length === i + 1 ? {} : { borderBottom: "1px solid #dfdfdf" }}>
              <Stack direction={"row"} alignItems={"center"} gap={5}>
                <Typography variant="subtitle1" color="#999">
                  No.{i}
                </Typography>
                <Typography variant="subtitle1" color="#666">
                  [{v.time}]
                </Typography>
                <Typography variant="subtitle1" color="primary">
                  {v.BarCode}
                </Typography>
                <Typography variant="subtitle1" color="secondary">
                  {v.Quantity} 개
                </Typography>
                <Typography variant="subtitle1" color="success">
                  {v.from} &rarr; {v.to}
                </Typography>
              </Stack>
            </ListItem>
          ))}
        </List>
      </Card>
    </Box>
  );
};

export default MoveInventory;
