import { Dialog, DialogTitle, DialogContent, Typography, Stack, TextField, Button, DialogActions, Box } from "@mui/material";
import { useRef, useState, useCallback, useEffect } from "react";
import { useBoxNameStore } from "../../../../store/boxNameStore";
import ProductInfo from "../ProductInfo";
import { searchProductResponseDto } from "../../../../service/total-order/types";
import { useMoveTableToBasket } from "../../../../service/total-order/totalOrderListHooks";
import { basketApi } from "../../../../service/basket/BasketAxios";
import { customToast } from "../../../../shared/lib/customToast";
import { CountTextStyle } from "../../style/style";
import { speakText } from "../../lib/speekText";
import { useHistoryStore } from "../../../../store/totalOrderHIstory";
import { useUserInfoState } from "../../../../store/UserInfoStore";

interface GreenBasketModalProps {
  open: boolean;
  onClose: () => void;
  info: searchProductResponseDto;
  BarCode: string;
}

export const GreenBasketModal: React.FC<GreenBasketModalProps> = ({ open, onClose, info, BarCode }) => {
  const { ToType: type } = info; //바코드로 찍은후 넘어온 제품 정보
  const countRef = useRef<HTMLInputElement>(null); //
  const boxNameRef = useRef<HTMLInputElement>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [boxName, setBoxName] = useState<string>("");
  const { boxInfo, setBoxInfo, deleteBoxName } = useBoxNameStore((status) => status);
  const { mutate: moveToBasket } = useMoveTableToBasket(() => {
    //히스토리에 저장
    setHistory({
      OrderID: null,
      OrderAlias: "적재",
      BarCode: BarCode,
      CreatedAt: new Date().toLocaleString(),
      CreatedBy: UserName,
      Count: count,
      BasketID: boxName,
      ProductName_ko: info.ProductName_ko,
      Size: info.Size,
      Color: info.Color,
      Type: "greenBasket",
    });
  });

  const [barCode, setBarCode] = useState<string>("");

  const { setHistory } = useHistoryStore((v) => v);
  const { UserName } = useUserInfoState((v) => v);

  useEffect(() => {
    if (BarCode) {
      setBarCode(BarCode);
    }
  }, []);

  // 포커스를 할 때 모달이 열리는 시간을 생각해서 약간의 딜레이가 필요하다. 모달이 열리는 순간 포커스를 하면 포커스가 씹힐 수 있다.
  const dilayFocus = useCallback((ref: any) => {
    setTimeout(() => {
      ref.current?.focus();
    }, 100);
  }, []);

  // 바구니 번호 입력 후 엔터키 입력시 수량입력으로 포커싱 됨
  const handleKeyDwonEnter = (e: any) => {
    if (e.key === "Enter") {
      handleSubmitBoxName();
    }
  };

  //시작 삭제를 등록하는 함수
  const handleCountKeyDwon = (e: any) => {
    if (e.key === "E") {
      handleSubmit();
    }

    if (e.key === "C") {
      setCount(0);
    }
  };

  // to: string;
  // BarCode: string;
  // count: number;
  // 바구니에 넣는 등록함수
  const handleSubmit = () => {
    if (count !== 0) {
      moveToBasket({
        to: boxName,
        BarCode: barCode,
        count: count,
      });

      onClose();
    } else {
      alert("수량을 입력해주세요.");
      customToast("warn", "수량을 입력해주세요.");
    }
  };

  //상자이름을 등록하는 함수
  const handleSubmitBoxName = () => {
    /**
     * 1. 바구니 이름이 유효한지 확인
     * 2. 유효하다면 전역변수에 등록하고 수량 입력으로 포커싱
     * 3. disabled 변경
     * 4. 유효하지 않다면 alert
     */

    if (boxName.length > 3) {
      basketApi
        .getBasket({ BasketID: boxName })
        .then((data) => {
          if (data.inventory.length === 0) {
            dilayFocus(countRef);
            setBoxInfo({ type, boxName });
            setDisabled(false);
          } else {
            if (window.confirm("이미 사용중인 바구니 이름입니다. 사용하시겠습니까?")) {
              dilayFocus(countRef);
              setBoxInfo({ type, boxName });
              setDisabled(false);
            } else {
              setBoxName("");
            }
          }
        })
        .catch((err) => {
          customToast("error", "사용할 수 없는 바구니 이름입니다.");
          setBoxName("");
        });
    } else {
      setBoxName("");
      customToast("error", "사용할 수 없는 바구니 이름입니다.");
    }
  };

  const handeChangeBoxName = () => {
    /**
     * 1. 등록된 바구니의 이름을 변경
     * 2. 버튼을 누르면 전역변수에 있는 바구니 이름 삭제
     * 3. boxName 초기화
     * 4. boxNameRef에 포커싱
     * 5. disabled를 false로 변경
     */

    deleteBoxName(type);
    setBoxName("");
    setCount(0);
    setDisabled(true);
    dilayFocus(boxNameRef);
  };

  // 수량 입력시 숫자만 입력되도록 함
  const handleCountChange = (e: any) => {
    /**
     * 1. ENTER 입력시 => 등록버튼 클릭
     * 2. CLEAR 입력시 => 수량 삭제
     * 3. 숫자 입력시 => 수량 입력
     * 4. 다른 번호 입력시 => 이전 수량 유지
     *
     * 문제점 : 스캐너라 스케너라 할지라도 차례대로 입력되는건데 enter 가 그대로 유지될 수 있을까?
     */

    const value = e.target.value || 0;
    if (!isNaN(parseInt(value))) {
      const result = parseInt(value);
      speakText(result);
      setCount(result);
    }
  };

  useEffect(() => {
    if (open) {
      setCount(0);
      // 등록된 바구니 이름이 없다면
      if (!boxInfo[type]) {
        /**
         * 1. 바구니 input 에 포커싱
         * 2. disabled를 true로 변경하여 수량 변경 못하게 막기
         * 3. boxName 초기화
         */
        dilayFocus(boxNameRef);
        setBoxName("");
        setDisabled(true);
      } else {
        /**
         * 1. 수량 input 에 포커싱
         * 2. disabled를 false로 변경하여 수량변경 가능하게 하기
         * 3. boxName 전역변수에 있는 값으로 초기화
         */
        setBoxName(boxInfo[type]);
        setDisabled(false);
        dilayFocus(countRef);
      }
    }
  }, [open]);

  const moveCursorToEnd = () => {
    if (countRef.current) {
      const inputElement = countRef.current;
      const valueLength = inputElement.value.length;
      inputElement.setSelectionRange(valueLength, valueLength);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "390px",
          },
        },
      }}
    >
      <Box sx={{ backgroundColor: "green", height: "30px" }} />
      <DialogTitle id="alert-dialog-title" fontSize={"18px"} fontWeight="bold" sx={{ color: "green" }}>
        초록 바구니 (적재)
      </DialogTitle>
      <DialogContent>
        <ProductInfo data={info} BarCode={barCode} />
        <Typography variant="subtitle2" color={!boxInfo[type] ? "error" : "primary"} sx={{ my: 5 }}>
          * {!boxInfo[type] ? "바구니 번호를 입력해주세요." : `수량을 입력해주세요.`}
        </Typography>
        <Stack direction="row" gap={1} sx={{ mb: 2 }}>
          <TextField
            inputRef={boxNameRef}
            id="boxNumber"
            size="small"
            fullWidth
            disabled={!disabled}
            label="바구니 번호"
            placeholder="바구니번호를 입력해주세요."
            value={boxName}
            onChange={(e) => setBoxName(e.target.value.trim())}
            onKeyDown={handleKeyDwonEnter}
          />

          {!disabled ? (
            <Button variant="outlined" onClick={handeChangeBoxName}>
              변경
            </Button>
          ) : (
            <Button variant="contained" onClick={handleSubmitBoxName}>
              등록
            </Button>
          )}
        </Stack>

        <Stack direction="row" gap={1}>
          <TextField
            inputProps={{
              style: CountTextStyle,
            }}
            disabled={disabled}
            inputRef={countRef}
            size="small"
            fullWidth
            label="수량"
            placeholder="수량을 입력해주세요."
            value={count}
            onKeyDown={handleCountKeyDwon}
            onChange={handleCountChange}
            onFocus={moveCursorToEnd} // 커서를 필드로 올릴 때 오른쪽 끝으로 이동
          />
          <Button
            disabled={disabled}
            sx={{ fontSize: "32px" }}
            variant="outlined"
            onClick={() => {
              setCount((prev) => prev + 1);
            }}
          >
            +
          </Button>
          <Button
            disabled={disabled}
            sx={{ fontSize: "32px" }}
            variant="outlined"
            onClick={() => {
              setCount((prev) => (prev === 0 ? 0 : prev - 1));
            }}
          >
            -
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <Button disabled={disabled} onClick={handleSubmit} variant="contained">
          등록
        </Button>
        <Button onClick={onClose} variant="outlined">
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
};
