import { TextField } from "@mui/material";
import LinkCard from "../LinkCard";
import LinkSubTitle from "../LinkSubTitle";
import { getPreProductDto } from "../../../../service/product/types";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";

interface ProductMemoFormProps {
  data: getPreProductDto;
  onChange: React.Dispatch<React.SetStateAction<getPreProductDto>>;
}

const ProductMemoForm: React.FC<ProductMemoFormProps> = ({ data, onChange }) => {
  const [text, setText] = useState<string>("");


  useEffect(() => {
    setText(data.PreProductMemo || "");
  }, [data.PreProductMemo]);

  const debouncedFetchSearchResult = useCallback(
    debounce((value) => {
      onChange((prev: getPreProductDto) => ({ ...prev, PreProductMemo: value }));
    }, 400),
    []
  );

  const handleChange = (e: any) => {
    const { value } = e.target;
    setText(value);
    debouncedFetchSearchResult(value);
  };

  return (
    <LinkCard>
      <LinkSubTitle title="상품메모" />
      <TextField name="PreProductMemo" value={text} onChange={handleChange} multiline rows={5} sx={{ backgroundColor: "#F1F1F1" }} />
    </LinkCard>
  );
};

export default ProductMemoForm;
