import { SIDENAV_WIDTH } from "../../../shared/const/constant";


export const TableContainerStyle = {
    p: 3,
    height: "100vh", // 일단 해제 
    minWidth: `1200px`,
    maxWidth: `calc(100vw - ${SIDENAV_WIDTH})`,
    width: "100%",
    boxSizing: "border-box",
    "& .actions": {
      color: "text.secondary",
    },
    "& .textPrimary": {
      color: "text.primary",
    },
    "& .super-app-theme--header": {
      fontWeight: "bold",
    },
  };
  