import React, { useEffect, useMemo, useState } from "react";

import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import SystemUpdateAltOutlinedIcon from "@mui/icons-material/SystemUpdateAltOutlined";
import { brandIconOptions, brandOptions } from "../../side-navigation/const/brandIconsOption";
import { getCurrentDateTime } from "../../individual-order/lib/getCurrentDateTime";
import { newProductRequestDto } from "../../../service/product/types";
import { productApi } from "../../../service/product/productAxios";
import SelectForm from "../../../widget/form/ui/SelectForm";
import { statusOptions } from "../../../shared/const/statusOptions";
import { productDatatoExcelFormmat } from "../lib/productDataToExcelformmat";
import { initialState } from "../const/const";
import { useUserInfoState } from "../../../store/UserInfoStore";
import { is } from "date-fns/locale";

interface ExportExcelButtonProps {
  params: newProductRequestDto;
}

const ExportToExcelButtonInProductTable: React.FC<ExportExcelButtonProps> = ({}) => {
  const [open, setOpen] = useState(false);
  const { UserName } = useUserInfoState((v) => v);
  const [params, setParams] = React.useState<newProductRequestDto>({ ...initialState });
  const [isAllDownLoad, setIsAllDownLoad] = useState(false);
  const handleChangeOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setParams({ ...initialState });
  }, []);

  const handleChangeParams = (e: any) => {
    const { name, value } = e.target;
    let newValue = typeof value === "string" ? value.trim().toLocaleLowerCase() : value;
    setParams((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };
  //브랜드 이름
  const brandMap = useMemo(() => {
    return brandIconOptions.reduce((acc: Record<string, string>, cur) => {
      const { brandCode, brandName } = cur;
      acc[brandCode] = brandName; // 값을 누적
      return acc; // 누적된 객체 반환
    }, {});
  }, [brandIconOptions]);

  //파일명
  const fileName = `[${getCurrentDateTime()}][${brandMap[params.brand.toUpperCase()]}] 상품목록`;

  //엑셀 다운로드 함수 => 따로 작성한 이유는 숫자때문
  const exportToExcel = () => {
    productApi.getProductExcelData({ ...params, no_pagination: "true" }).then((data: any) => {
      // console.log({ data });
      if (data.length === 0) return;
      productDatatoExcelFormmat(data, fileName);
    });
    handleChangeOpen();
  };

  return (
    <>
      <Button variant="contained" size="large" onClick={handleChangeOpen} startIcon={<SystemUpdateAltOutlinedIcon />}>
        엑셀 다운로드
      </Button>

      <Dialog open={open} onClose={handleChangeOpen}>
        <DialogTitle>엑셀 다운로드</DialogTitle>

        <DialogContent>
          <Stack gap={2} sx={{ mt: 5 }}>
            <SelectForm
              handleChange={handleChangeParams}
              id="status"
              value={params.status}
              options={statusOptions}
              sx={{ width: "100%" }}
              label="상태"
            />
            <SelectForm
              handleChange={handleChangeParams}
              id="brand"
              value={params.brand.toLocaleUpperCase()}
              options={brandOptions}
              sx={{ width: "100%" }}
              label="브랜드"
            />

            {UserName === "김범식" && (
              <Checkbox
                title="전부 다운받기"
                checked={isAllDownLoad}
                onChange={(e) => {
                  setIsAllDownLoad(e.target.checked);
                }}
              />
            )}
            <Button
              variant="contained"
              disabled={(!params.brand || !params.status) && !isAllDownLoad}
              sx={{ width: "250px", height: "80px" }}
              onClick={exportToExcel}
              startIcon={<SystemUpdateAltOutlinedIcon />}
            >
              엑셀 다운로드
            </Button>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={handleChangeOpen} variant="outlined">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportToExcelButtonInProductTable;
