import { Stack, Button, TextField } from "@mui/material";
import LinkSubTitle from "../LinkSubTitle";
import { getPreProductDto, translateDto } from "../../../../service/product/types";
import LinkCard from "../LinkCard";
import RowStack from "../../../../shared/ui/RowStack";
import { productApi } from "../../../../service/product/productAxios";

interface Props {
  data: getPreProductDto;
  onChange: React.Dispatch<React.SetStateAction<getPreProductDto>>;
}

const FiberCompositionForm: React.FC<Props> = ({ data, onChange }) => {
  const handleTranslateKotoEn = async () => {
    const dto: translateDto = {
      text: data.CottonInfo_ko,
      source: "ko",
      target: "en",
    };

    if (!!data.CottonInfo_ko) {
      await productApi.translateZh(dto).then((res) => {
        onChange((prev: getPreProductDto) => ({ ...prev, CottonInfo_en: res?.translated_text || "번역실패" }));
      });
    } else {
      customToast("info", "번역할 내용이 없습니다.");
    }
  };

  const handleTranslateEntoKo = async () => {
    const dto: translateDto = {
      text: data.CottonInfo_en,
      source: "en",
      target: "ko",
    };

    if (!!data.CottonInfo_en) {
      await productApi.translateZh(dto).then((res) => {
        onChange((prev: getPreProductDto) => ({ ...prev, CottonInfo_ko: res?.translated_text || "번역실패" }));
      });
    } else {
      customToast("info", "번역할 내용이 없습니다.");
    }
  };

  const handleChangeCottonInfo_ko = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange((prev: getPreProductDto) => ({ ...prev, CottonInfo_ko: e.target.value }));
  };

  const handleChangeCottonInfo_en = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange((prev: getPreProductDto) => ({ ...prev, CottonInfo_en: e.target.value }));
  };

  return (
    <LinkCard>
      <LinkSubTitle title="섬유의 조성" required />

      <RowStack gap={4} sx={{ alignItems: "center" }}>
        <TextField
          value={data.CottonInfo_ko}
          error={!data.CottonInfo_ko}
          fullWidth
          onChange={handleChangeCottonInfo_ko}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleTranslateKotoEn();
            }
          }}
          InputProps={{
            sx: {
              height: "80px",
            },
          }}
        />
        <Stack gap={4}>
          <Button sx={{ width: "150px" }} variant="contained" endIcon={<ArrowForwardIcon />} onClick={handleTranslateKotoEn}>
            한영번역
          </Button>
          <Button sx={{ width: "150px" }} variant="contained" startIcon={<ArrowBackIcon />} onClick={handleTranslateEntoKo}>
            영한번역
          </Button>
        </Stack>
        <TextField
          value={data.CottonInfo_en}
          error={!data.CottonInfo_en}
          fullWidth
          onChange={handleChangeCottonInfo_en}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleTranslateEntoKo();
            }
          }}
          InputProps={{
            sx: {
              height: "80px",
            },
          }}
        />
      </RowStack>
    </LinkCard>
  );
};

export default FiberCompositionForm;
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { customToast } from "../../../../shared/lib/customToast";
import { Height } from "@mui/icons-material";
