import { Button, Dialog, DialogTitle, DialogContent, Typography, DialogActions, MenuItem, ListItemIcon, TextField, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useUpdateCustomerFindOrder } from "../../../../service/customer-find-order/customerFindOrderHooks";
import SelectForm from "../../../../widget/form/ui/SelectForm";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";

import { customerOrderStatus3 } from "../../const/customerOrderStatus";
import { customToast } from "../../../../shared/lib/customToast";
import { getCurrentDateTime } from "../../../individual-order/lib/getCurrentDateTime";
interface StatusChangeButtonProps {
  selected: number[];
  onCashChange: (data: any) => any;
}

const StatusChangeButton: React.FC<StatusChangeButtonProps> = ({ selected, onCashChange }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const { mutate: updateCustomerFindOrder } = useUpdateCustomerFindOrder((data: any) => {
    onCashChange((prev: any) =>
      prev.map((item: any) => {
        if (selected.includes(item.CustomerOrderID)) {
          if (status === "발송전") {
            return { ...item, StatusMeno: "", FinishDate: "" };
          } else {
            return { ...item, StatusMeno: status, FinishDate: date };
          }
        } else {
          return item;
        }
      })
    );
  });

  useEffect(() => {
    const currentDate = getCurrentDateTime();
    setDate(currentDate.slice(0, 10));
  }, [open]);

  //실제로 오타가 나있어서 여기서도 Meno라고함
  const handleChangeStatusMeno = () => {
    const data = selected.map((CustomerOrderID) => {
      if (status === "발송전") {
        return {
          CustomerOrderID,
          StatusMeno: "",
          Status: "R",
          FinishDate: null,
        };
      } else {
        return {
          CustomerOrderID,
          StatusMeno: status,
          Status: "E", // 발송전이면 R, 다른상태라면 E
          FinishDate: date,
        };
      }
    });

    if (confirm(`[${selected.length}]개의 항목을 [${status}]로 변경하시겠습니까?`)) {
      updateCustomerFindOrder(data);
      handleChageOpen();
    } else {
      customToast("info", "취소되었습니다.");
      handleChageOpen();
    }
  };

  const handleChageOpen = () => {
    setOpen(!open);
  };

  const handleChangeStatus = (e: any) => {
    setStatus(e.target.value);
  };


  return (
    <>
      <MenuItem onClick={handleChageOpen}>
        <ListItemIcon>
          <CachedOutlinedIcon />
        </ListItemIcon>
        일괄 상태변경
      </MenuItem>

      <Dialog open={open} onClose={handleChageOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">일괄 상태변경</DialogTitle>
        <DialogContent sx={{ width: "300px" }}>
          <Stack gap={4}>
            <Typography color="#999" variant="caption">
              변경할 상태와 날짜를 선택해주세요.
            </Typography>

            <SelectForm
              value={status}
              label="상태메모"
              handleChange={handleChangeStatus}
              options={customerOrderStatus3.map((v) => ({ label: v.label, value: v.value }))}
            />
            {!!status && status !== "발송전" && (
              <TextField label="변경일" value={date} type="date" onChange={(e) => setDate(e.target.value)} fullWidth />
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={handleChangeStatusMeno} variant="contained">
            등록
          </Button>
          <Button onClick={handleChageOpen} variant="outlined">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StatusChangeButton;
