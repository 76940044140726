import { getOriginalProductResponseDto } from "../../../service/product/types";

export const product_initial_value: getOriginalProductResponseDto = {
  BarCode: "",
  QuotedPrice: 0,
  SalePrice: 0,
  OurSalePrice: 0,
  ChinaCost: 0,
  LocalDeliveryFee: 0,
  KoreaCost: 0,
  TotalCost: 0,
  DeliveryPrice: 0,
  VAT: 0,
  RocketMargin: 0,
  AccountingCost: 0,
  OurMargin: 0,
  ProductCode: "",
  IsUse: false,
  TotalCount: 0,
  Color: "",
  Color_zh: "",
  Size: "",
  Size_zh: "",
  SKU_ID: "",
  SKU_Name: null,
  OrderLink: "",
  ImageLink: [],
  ImageLink_add: [],
  Memo0: "",
  Memo00: "",
  Memo000: "",
  MemoCustomerOrder: "",
  Status: "",
  StatusUpload: "",
  CreatedAt: "",
  UpdatedAt: "",
};
