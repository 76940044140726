import CustomerFindTable from "../entities/customer-find/CustomerFindTable";

const CustomerFind = () => {
  return (
    <>
      <CustomerFindTable />
    </>
  );
};

export default CustomerFind;
