//총원가
export const calcTotalCost = (ChinaCost: number, LocalDeilyFee: number) => {
  const value = (ChinaCost + LocalDeilyFee) * 210;
  return Math.round(value * (1 + 0.06 + 0.13) + 700); // 6% 부가세, 13% 관세, 700원 택배비
};

//자사마진
export const calcOurMargin = (OurSalePrice: number, OurCommission: number, TotalCost: number) => {
  const a = OurSalePrice * ((100 - OurCommission - 10) / 100);
  return Math.round(a - TotalCost - 3000);
};

//견적가
export const calcQuotedPrice = (SalePrice: number, coupangeCommission: number) => {
  return Math.round(SalePrice * ((100 - coupangeCommission) / 100));
};

//쿠팡마진
export const calcCoupangMargin = (QuotedPrice: number, TotalCost: number) => {
  return Math.round(QuotedPrice - TotalCost - QuotedPrice * 0.1);
};
