import { Box, Card, Divider, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Stack, Tooltip, Typography } from "@mui/material";
import { useState, useMemo, useEffect, useRef, createContext } from "react";

import LabelWithContent, { MAIN_CONTENT_COLOR, SUBTITLE_COLOT } from "./LabelWithContent";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const INTERVAL = 20;
const SelectedContext = createContext<any>(null);
const CustomerFindTable = () => {
  const { data, refetch, isFetching } = useGetCustomerFindList();
  const [selected, setSelected] = useState<number[]>([]);
  const observerRef = useRef(null);
  const [filter, setFilter] = useState("");
  const [viewList, setViewList] = useState<getCustomerOrderListResponseDto[]>(data ? [...data.slice(0, INTERVAL)] : []);
  const [viewIndex, setViewIndex] = useState<number>(0);
  const [location, setLocation] = useState<string>("전체");
  const [findState, setFindState] = useState<string>("전체");

  const handleLocationChange = (e: any) => {
    setLocation(e.target.value as string);
  };

  const handleFindStateChange = (e: any) => {
    setFindState(e.target.value as string);
  };
  //view index가 변경될때마다 확인하기
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        //콜백함수
        if (data && viewList.length < data.length && entries[0].isIntersecting) {
          setViewList([...viewList, ...data.slice(viewIndex, viewIndex + INTERVAL)]);
          setViewIndex(viewIndex + INTERVAL);
        }
      },
      { threshold: 0.8 }
    );

    if (observerRef.current) observer.observe(observerRef.current); //관찰시작

    // 컴포넌트가 언마운트될 때 옵저버 해제
    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current); //관찰해제
    };
  }, [viewList, viewIndex]);

  useEffect(() => {
    setViewList(data ? [...data.slice(0, INTERVAL)] : []); //스프레드로 초기화 해야 viewList가 변경될것 같았음
    setViewIndex(INTERVAL); //인덱스 초기화
  }, [data]);

  //검색한 결과를 필터링하는 함수
  const filterdRow = useMemo(() => {
    if (!viewList) return [];
    if (location === "전채" && filter === "") return viewList;

    return viewList
      .filter((row) => {
        return Object.values(row).some((value) => {
          const str = !!value ? value.toString() : "";
          return str.includes(filter);
        });
      })
      .filter((v) => {
        switch (location) {
          case "전체":
            return true;
          case "삼정동":
            return (v.RackID?.toString()?.[0] || "") === "3";
          case "춘의동":
            return (v.RackID?.toString()?.[0] || "") !== "3" || !v.RackID;
          default:
            return true;
        }
      })
      .filter((v) => {
        switch (findState) {
          case "전체":
            return true;
          case "완료":
            return v.Quantity_Find === v.Quantity_Target;
          case "진행 중":
            return v.Quantity_Find !== v.Quantity_Target;
          default:
            return true;
        }
      });
  }, [viewList, filter, location]);

  const { totalListCount, totalQuantityToFind, totalQuantityTarget } = useMemo(() => {
    let totalListCount = 0;
    let totalQuantityToFind = 0;
    let totalQuantityTarget = 0;

    data?.forEach((item: getCustomerOrderListResponseDto) => {
      totalListCount += 1;
      totalQuantityToFind += item.Quantity_Find;
      totalQuantityTarget += item.Quantity_Target;
    });

    return { totalListCount, totalQuantityToFind, totalQuantityTarget };
  }, [filterdRow]);

  return (
    <SelectedContext.Provider value={{ selected, setSelected }}>
      <BackDropLoading open={isFetching} />

      <Stack sx={{ margin: "0 auto", my: 10, width: "1200px" }} gap={4}>
        <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
          고객 찾기
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Box sx={{ width: "120px" }}>
                  <Typography variant="caption" color={SUBTITLE_COLOT}>
                    총 목록
                  </Typography>
                  <Typography color={MAIN_CONTENT_COLOR} variant="h5">
                    {totalListCount} 건
                  </Typography>
                </Box>
                <Box sx={{ width: "120px" }}>
                  <Typography variant="caption" color={SUBTITLE_COLOT}>
                    찾을 수량
                  </Typography>
                  <Typography color={MAIN_CONTENT_COLOR} variant="h5">
                    {totalQuantityToFind} 건
                  </Typography>
                </Box>
                <Box sx={{ width: "120px", mr: "auto" }}>
                  <Typography variant="caption" color={SUBTITLE_COLOT}>
                    찾은수량
                  </Typography>
                  <Typography color={MAIN_CONTENT_COLOR} variant="h5">
                    {totalQuantityTarget} 건
                  </Typography>
                </Box>

                {/* 검색창 */}

                <SearchInputField value={filter} onChange={(e) => setFilter(e.target.value)} />

                <IconButton>
                  <RefreshIconButton onClick={() => refetch()} />
                </IconButton>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <RowStack gap={5}>
              <FormControl sx={{ ml: 3 }}>
                <RadioGroup sx={{ display: "flex", flexDirection: "row", gap: 5 }} value={findState} onChange={handleFindStateChange}>
                  <FormControlLabel value="전체" control={<Radio />} label="전체" />
                  <FormControlLabel value="완료" control={<Radio />} label="완료" />
                  <FormControlLabel value="진행 중" control={<Radio />} label="진행 중" />
                </RadioGroup>
              </FormControl>

              <FormControl sx={{ ml: 3, mr: "auto" }}>
                <RadioGroup sx={{ display: "flex", flexDirection: "row", gap: 5 }} value={location} onChange={handleLocationChange}>
                  <FormControlLabel value="전체" control={<Radio />} label="전체" />
                  <FormControlLabel value="춘의동" control={<Radio />} label="춘의동" />
                  <FormControlLabel value="삼정동" control={<Radio />} label="삼정동" />
                </RadioGroup>
              </FormControl>
            </RowStack>
          </Grid>
          {filterdRow?.map((item, i) => (
            <CustomerFindItem key={i} data={item} />
          ))}
          {data && viewList.length !== data.length && (
            <Typography variant="subtitle1" ref={observerRef} sx={{ textAlign: "cetner" }}>
              더보기
            </Typography>
          )}
        </Grid>
      </Stack>
    </SelectedContext.Provider>
  );
};
export default CustomerFindTable;

import { useModals } from "../../service/modal/useModals";
import RefreshIconButton from "../../shared/ui/buttom/RefreshIconButton";
import BackDropLoading from "../../shared/ui/loading/BackDropLoading";
import RowStack from "../../shared/ui/RowStack";
import SearchInputField from "../../widget/form/ui/SearchInpuField";
import { ShowInventoryModal } from "../../widget/show-inventory-modal/ui/ShowInventoryModal";
import { copyToClicpboard } from "../rocket/lib/copyToClipBoard";
import { useGetCustomerFindList } from "../../service/customer-find-order/customerFindOrderHooks";
import { getCustomerOrderListResponseDto } from "../../service/customer-find-order/types";
import ProgressBar from "./ProgressBar";
import { customerFindBrandOptions } from "../customer-find-order/const/customerBrandOptions";
interface CustomerFindItemProps {
  data: getCustomerOrderListResponseDto;
}

export const CustomerFindItem: React.FC<CustomerFindItemProps> = ({ data }) => {
  const { BarCode, Color, RackID, Size, Status, Quantity_Find, Quantity_Target, Detail01, Detail02 } = data;
  const { closeModal, openModal } = useModals();

  const handleClose = () => {
    closeModal(ShowInventoryModal);
  };

  const handleClickOpen = () => {
    openModal(ShowInventoryModal, { BarCode, handleClose, status });
  };

  const handleCopyClipboard = () => {
    copyToClicpboard(BarCode);
  };

  return (
    <Grid item xs={3}>
      <Card
        sx={{
          p: 6,
          backgroundColor: "#fff",
          // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.2s ease-in-out",
          "&:hover": {
            boxShadow: "0 8px 12px rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <Stack gap={5}>
          <Stack gap={2} sx={{ height: "150px" }} alignItems={"start"}>
            <RowStack sx={{ width: "100%" }}>
              <Typography variant="caption" color="#999">
                {data.OrderDate.replace("T", " ")}
              </Typography>
            </RowStack>
            <Stack direction={"row"} gap={1} alignItems={"center"} sx={{ width: "100%" }}>
              <Typography fontSize={"18px"} color="primary">
                {BarCode}
              </Typography>
              <Tooltip title={<p>클릭하면 바코드가 복사됩니다.</p>} placement="top" arrow>
                <IconButton onClick={handleCopyClipboard}>
                  <ContentCopyIcon fontSize="small" color="primary" />
                </IconButton>
              </Tooltip>
            </Stack>
            <Typography variant="h6" color="#333">
              {data.ProductName_ko}
            </Typography>
          </Stack>

          <Grid container>
            <Grid item xs={12}>
              <LabelWithContent label="색상" content={Color} />
            </Grid>
            <Grid item xs={12}>
              <LabelWithContent label="사이즈" content={Size} />
            </Grid>
            <Grid item xs={12} onClick={handleClickOpen} sx={{ cursor: "pointer" }}>
              <LabelWithContent label="대표랙" content={data.RackID || "-"} contentColor="#0b57d0" contentSx={{ textDecoration: "underline" }} />
            </Grid>

            <Grid item xs={12}>
              <ProgressBar currentCount={data.Quantity_Find} maxCount={data.Quantity_Target} />
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid item xs={12}>
              <LabelWithContent label="주문자 명" content={data.OrderPeopleName || "-"} />
            </Grid>
            <Grid item xs={12}>
              <LabelWithContent label="주문일" content={data.OrderDate.split("T")[0] || "-"} />
            </Grid>
            <Grid item xs={12}>
              <LabelWithContent label="브랜드" content={customerFindBrandOptions[data.BrandStr]} />
            </Grid>
          </Grid>
        </Stack>
      </Card>
    </Grid>
  );
};
