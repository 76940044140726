
export const dopalCategory: { [key: string]: { [key: string]: string } } = {
    여성의류: {
      맨투맨: "맨투맨",
      블라우스: "블라우스",
      원피스: "원피스",
      점프수트: "점프수트",
      상의: "상의",
      재킷: "재킷",
      야상: "야상",
      바지: "바지",
      니트: "니트",
      가디건: "가디건",
      트렌치코트: "트렌치코트",
      울코트: "울코트",
      무스탕: "무스탕",
      솜점퍼: "솜점퍼",
      쓰리피스: "쓰리피스",
      세트: "세트",
      정장세트: "정장세트",
      트레이닝세트: "트레이닝세트",
      임부복바지: "임부복바지",
      임부복원피스: "임부복원피스",
      양말: "양말",
      커플복: "커플복",
      레깅스: "레깅스",
      캐주얼: "캐주얼",
      다운패딩: "다운패딩",
      내의: "내의",
      브라팬티세트: "브라팬티세트",
      잠옷: "잠옷",
      스타킹: "스타킹",
      임부트레닝세트: "임부트레닝세트",
      라지: "라지",
      브라: "브라",
      민족의상: "민족의상",
      패딩조끼: "패딩조끼",
      핸드메이드코트: "핸드메이드코트",
      여성팬티: "여성팬티",
      헤어액세서리: "헤어액세서리",
      티셔츠: "티셔츠",
      수영복: "수영복",
      나시: "나시",
      조끼: "조끼",
      스커트: "스커트",
      반바지: "반바지",
      치마: "치마",
      목도리스카프: "목도리스카프",
      잡화: "잡화",
    },
    청제품: {
      청핫팬츠: "청핫팬츠",
      청스커트: "청스커트",
      청반바지: "청반바지",
      청바지: "청바지",
      청조끼: "청조끼",
      청셔츠: "청셔츠",
      청재킷: "청재킷",
      청원피스: "청원피스",
      청투피스: "청투피스",
    },
    "신발&가방&잡화": {
      마스크: "마스크",
      아동가방: "아동가방",
      여성플랫슈즈: "여성플랫슈즈",
      스카프: "스카프",
      여성방한화: "여성방한화",
      마크스: "마크스",
      여성장갑: "여성장갑",
      노트북가방: "노트북가방",
      악세사리: "악세사리",
      우산: "우산",
      단화: "단화",
      첼시: "첼시",
      방한화: "방한화",
      머플러: "머플러",
      지갑: "지갑",
      여행파우치: "여행파우치",
      모자: "모자",
      크로스백: "크로스백",
      미니백: "미니백",
      클러치백: "클러치백",
      토트백: "토트백",
      숄더백: "숄더백",
      슬립온: "슬립온",
      "운동화・단화": "운동화・단화",
      힐: "힐",
      롱부츠: "롱부츠",
      백팩: "백팩",
      구두: "구두",
      샌들: "샌들",
    },
    남성의류: {
      티셔츠: "티셔츠",
      트레이닝바지: "트레이닝바지",
      니트: "니트",
      잠옷: "잠옷",
      상의: "상의",
      다운패딩점퍼: "다운패딩점퍼",
      코트: "코트",
      POLO: "POLO",
      다운패딩조끼: "다운패딩조끼",
      정장자켓: "정장자켓",
      솜점퍼: "솜점퍼",
      트레이닝: "트레이닝",
      남성팬티: "남성팬티",
      트레이닝상의: "트레이닝상의",
      수영복: "수영복",
      맨투맨: "맨투맨",
      트렌치코트: "트렌치코트",
      넥타이: "넥타이",
      조끼: "조끼",
      셔츠: "셔츠",
      정장세트: "정장세트",
      재킷: "재킷",
      반바지: "반바지",
      긴바지: "긴바지",
      청긴바지: "청긴바지",
      청반바지: "청반바지",
    },
    주방용품: {
      빙수기제빙기: "빙수기제빙기",
      프라이팬: "프라이팬",
      정수기냉온수기: "정수기냉온수기",
      압력솥가마솥: "압력솥가마솥",
      주방정리소품: "주방정리소품",
      고무장갑: "고무장갑",
      식탁보: "식탁보",
      면도크림: "면도크림",
      진공포장기: "진공포장기",
      전기밥솥: "전기밥솥",
      냄비: "냄비",
      전자레인지: "전자레인지",
      주전자티포트: "주전자티포트",
      가스레인지: "가스레인지",
      도마: "도마",
      도시락통: "도시락통",
      앞치마: "앞치마",
      매트: "매트",
      면도기: "면도기",
      식품건조기: "식품건조기",
      컵: "컵",
      음식물처리기: "음식물처리기",
      홈인테리어: "홈인테리어",
      완구취미: "완구취미",
      가전디지털: "가전디지털",
      "소형(~42)TV": "TV",
      스틱가습기: "스틱가습기",
      "키보드、마우스": "키보드、마우스",
      AV: "AV",
      공기청정기: "공기청정기",
      스탠드형: "스탠드형",
      건강매트: "건강매트",
      제습기: "제습기",
      스탠드믹서: "스탠드믹서",
      핸드믹서: "핸드믹서",
      "대형(55~64)TV": "TV",
      DVD: "DVD",
      가습기: "가습기",
      USB선풍기: "USB선풍기",
      손발난로: "손발난로",
      냉온수매트: "냉온수매트",
      미니형제습기: "미니형제습기",
      분쇄기: "분쇄기",
    },
    반려동물용품: {
      반려동물: "반려동물",
      "강아지,고양이": "강아지,고양이",
    },
  };
  