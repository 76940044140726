import SearchIcon from "@mui/icons-material/Search";
import { Button, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import LinkCard from "./LinkCard";
import LinkSubTitle from "./LinkSubTitle";
import CategoryForm from "./form/CategoryForm";
import FiberCompositionForm from "./form/FiberCompositionForm";
import ProductAdditionalInfoForm from "./form/ProductAdditionalInfoForm";
import ProductInfoForm from "./form/ProductInfoForm";
import ProductOptionsForm from "./form/ProductOptionsForm";
import { getOriginalProductResponseDto, getPreProductDto } from "../../../service/product/types";
import { useEffect, useState } from "react";
import {
  useDeletePreProduct,
  useGetPreProductById,
  useUpdateIsInUse,
  useUpdatePreProduct,
  useUpdateProductBulk,
} from "../../../service/product/productHooks";
import { customToast } from "../../../shared/lib/customToast";
import BackDropLoading from "../../../shared/ui/loading/BackDropLoading";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import ProductDetailImage from "./form/ProductDetailImage";
import { preproduct_initial_value } from "../const/ProductsInitialValue";
import { product_initial_value } from "../const/preProductInitialValue";
import RowStack from "../../../shared/ui/RowStack";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RefreshIconButton from "../../../shared/ui/buttom/RefreshIconButton";
import SaveIcon from "@mui/icons-material/Save";
import ProductMemoForm from "./form/ProductMemoForm";
import ProductProcessStep from "./form/ProductProcessStep";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ProductSearchTag from "./form/ProductSearchTag";

const UpdateSingleProduct = () => {
  const location = useLocation();

  /***
   * 이미지를 담는게 4개 있어야함
   * 1. 제품이미지
   * 2. 제품 상세이미지
   * 3. 바코드 대표이미지
   * 4. 바코드 추가이미지
   *
   * 1,2 한묶음
   * 3,4 리스트로 한묶음
   */
  const [preProduct, setPreProduct] = useState<getPreProductDto>(preproduct_initial_value);
  const [products, setProducts] = useState<getOriginalProductResponseDto[]>([product_initial_value]);
  const { mutateAsync: deletePreProduct } = useDeletePreProduct();
  const id = location.pathname.split("/")[3];
  const { data, refetch, isLoading, isFetching } = useGetPreProductById({ id });
  const navigate = useNavigate();
  const { mutateAsync: updatePreProduct, isPending: p1 } = useUpdatePreProduct();
  const { mutateAsync: updateProductBulk, isPending: p2 } = useUpdateProductBulk();
  const { mutateAsync: updateIsInUse, isPending: p3 } = useUpdateIsInUse();

  useEffect(() => {
    if (data) {
      setPreProduct(data);
      setProducts(data.products);
    }
  }, [data]);

  useEffect(() => {
    updateIsInUse({ Id: id, IsInUse: true });
    return () => {
      updateIsInUse({ Id: id, IsInUse: false });
    };
  }, []);

  const handleUpdateSubmit = async () => {
    try {
      await updateProductBulk(products.map((product) => _.omit(product, ["PreProductID", "ImageLink", "ImageLink_add"])));
      await updatePreProduct({ ...preProduct });
      customToast("success", "상품이 업데이트 되었습니다.");
      refetch();
      // window.history.back();
    } catch (e) {
      customToast("error", "상품 업데이트에 실패했습니다.");
    }
  };

  const handleDeletePreProduct = async () => {
    if (confirm("정말 삭제하시겠습니까?")) {
      await deletePreProduct({ id });
      window.history.back();
    }
  };

  return (
    <>
      <BackDropLoading open={isFetching || p1 || p2} />
      <Stack gap={2} mb={5}>
        {preProduct.Id !== 0 && (
          <>
            <LinkCard>
              <RowStack>
                <Typography>
                  상품링크 :{" "}
                  <a href={preProduct.Productlink} target="_blank">
                    {preProduct.Productlink}
                  </a>
                </Typography>
                <RowStack gap={2}>
                  <Tooltip title={<p>상품 삭제</p>} arrow placement="top">
                    <IconButton color="error">
                      <DeleteOutlineIcon onClick={handleDeletePreProduct} fontSize="large" />
                    </IconButton>
                  </Tooltip>
                  <RefreshIconButton onClick={() => refetch()} />
                </RowStack>
              </RowStack>
            </LinkCard>
            <ProductProcessStep data={preProduct} onChange={setPreProduct} />
            <ProductInfoForm data={preProduct} onChange={setPreProduct} />
            <ProductMemoForm data={preProduct} onChange={setPreProduct} />
            <CategoryForm data={preProduct} onChange={setPreProduct} />
            <ProductSearchTag data={preProduct} onChange={setPreProduct} />
            <ProductOptionsForm data={products} onChange={setProducts} />
            <ProductDetailImage data={preProduct} onChange={setPreProduct} />
            <FiberCompositionForm data={preProduct} onChange={setPreProduct} />
            <ProductAdditionalInfoForm data={preProduct} onChange={setPreProduct} />
          </>
        )}
        <Stack gap={3} sx={{ position: "fixed", bottom: "10%", right: "calc(17% - 100px)", transform: "translateY(-10%)", zIndex: 1000 }}>
          {preProduct.Id !== 0 && (
            <Tooltip placement="left" arrow title={<p>저장하기</p>}>
              <Button
                variant="contained"
                size="large"
                onClick={handleUpdateSubmit}
                sx={{ borderRadius: 100, height: "80px", width: "80px" }}
                disabled={isFetching || p1 || p2}
              >
                <SaveIcon fontSize="large" />
              </Button>
            </Tooltip>
          )}

          <Tooltip placement="left" arrow title={<p>나가기</p>}>
            <Button
              variant="outlined"
              size="large"
              sx={{
                backgroundColor: "white",
                borderRadius: 100,
                height: "80px",
                width: "80px",
              }}
              onClick={() => {
                navigate("/products");
                // window.history.back();
              }}
            >
              <ExitToAppIcon fontSize="large" />
            </Button>
          </Tooltip>
          {/* <Button
            onClick={() => {
              updateIsInUse({ Id: id, IsInUse: true });
            }}
          >
            <ExitToAppIcon />
          </Button> */}
        </Stack>
      </Stack>
    </>
  );
};

export default UpdateSingleProduct;
