import { BarChart } from "@mui/x-charts";
import { getOrderListResponseDto } from "../../../service/order/types";
import { useMemo } from "react";
import { getDatesAndWeekdays } from "../lib/getDateAndWeekdays";
import { Id } from "../../../shared/type/Id";
import { Card } from "@mui/material";

interface BarChartProps {
  data: (getOrderListResponseDto & Id)[] | undefined;
}

const DailyQuantityChart: React.FC<BarChartProps> = ({ data }) => {
  const { dateList, dayList, QuantityList } = useMemo(() => {
    const datesAndWeekdays = getDatesAndWeekdays();

    const dateList = datesAndWeekdays.map((v) => v.date);
    const dayList = datesAndWeekdays.map((v) => v.day);

    const QuantityList = dateList.map((date) => {
      const sumQuantity = data?.filter((v) => v.ReceiveDate === date).reduce((acc, v) => v.sumQuantity + acc, 0);
      const sumQuantityOK = data?.filter((v) => v.ReceiveDate === date).reduce((acc, v) => v.sumQuantityOK + acc, 0);
      const sumQuantityReady = data?.filter((v) => v.ReceiveDate === date).reduce((acc, v) => v.sumQuantityReady + acc, 0);

      return { sumQuantity, sumQuantityOK, sumQuantityReady };
    });

    return { dateList, dayList, QuantityList };
  }, [data]);

  return (
    <Card sx={{ backgroundColor: "white", p: 2, boxSizing: "border-box", height: 250 }}>
      <BarChart
        xAxis={[{ scaleType: "band", data: dayList }]}
        series={[
          { data: QuantityList.map((v) => v.sumQuantity || 0), label: "총 발주수량" },
          { data: QuantityList.map((v) => v.sumQuantityOK || 0), label: "총 확정수량" },
          { data: QuantityList.map((v) => v.sumQuantityReady || 0), label: "총 준비수량" },
        ]}
        width={500}
        height={240}
        slotProps={{
          legend: {
            labelStyle: {
              fontSize: 12,
              fill: "#999",
              // fill: 'blue',
            },
          },
        }}
      />
    </Card>
  );
};

export default DailyQuantityChart;
