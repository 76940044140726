// export const seasonOptions = [
//   { label: "-", value: "-" },
//   { label: "봄", value: "봄" },
//   { label: "여름", value: "여름" },
//   { label: "가을", value: "가을" },
//   { label: "겨울", value: "겨울" },
//   { label: "봄, 가을", value: "봄, 가을" },
//   { label: "사계절", value: "사계절" },
// ];

export const seasonOptions = [
  { label: "-", value: "" },
  { label: "SPRING", value: "SPRING" },
  { label: "SUMMER", value: "SUMMER" },
  { label: "FALL", value: "FALL" },
  { label: "WINTER", value: "WINTER" },
  { label: "YEAR_ROUND", value: "YEAR_ROUND" },
];

export const seasonList = ["", "SPRING", "SUMMER", "FALL", "WINTER", "YEAR_ROUND"];
