import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  ListItemIcon,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import * as XLSX from "xlsx";
import { useRef, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { customToast } from "../../../../shared/lib/customToast";
import { useCreateCustomerFindOrder } from "../../../../service/customer-find-order/customerFindOrderHooks";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { excelDateToISOString } from "../../lib/excelDataToISOString";
import { filterInvalidObjects } from "../../lib/filterInvalidObjects";
import { customerFindBrandOptions } from "../../const/customerBrandOptions";
import ErrorList from "./UploadErrorList";

export const UploadCustomerOrderFileButton: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [result, setResult] = useState<any>(null);
  const { mutate: createCustomerFindOrder } = useCreateCustomerFindOrder((data) => {
    setResult(data || null);
  });
  const [inputCustomerBrand, setInputCustomerBrand] = useState<string>("");

  const handleChangeOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setResult(null);
  };

  const handleUploadCustomerExcelFile = (files: FileList | null) => {
    const file = files?.[0];

    //xlsx 라이브러리를 사용하여 엑셀을 읽어옴
    if (file?.type === "text/csv") {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string; // 파일
        const workbook = XLSX.read(text, { type: "binary", bookVBA: false });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rows: any[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "" });

        const i = rows[0].length === 23 ? -1 : 0; // A열로 당겨질 경우

        const result: any[] = rows
          .slice(1) // 해더값 지우기
          .filter((v) => !!v[4 + i]) //바코드 없으면 제외시킴
          .map((item) => {
            return {
              BarCode: typeof item[4 + i] === "number" ? item[4 + i] + "" : item[4 + i],
              OrderNumber: item[1 + i],
              OrderNumber_idx: item[2 + i],
              PeopleName: item[11 + i],
              PeoplePhone: item[12 + i],
              PeopleAdress: item[13 + i],
              PeopleMsg: item[14 + i],
              OrderProductName: item[15 + i],
              OrderProductOption: item[16 + i],
              Quantity_Target: item[17 + i],
              OrderPeopleName: item[18 + i],
              OrderPeoplePhone: item[19 + i],
              IncommingRoute: item[20 + i],
              Price: item[21 + i],
              BrandStr: inputCustomerBrand,
              OrderDate: typeof item[23 + i] === "string" ? item[23 + i]: excelDateToISOString(item[23 + i]),
            };
          });

        if (result.length === 0) {
          customToast("warn", "엑셀파일을 확인해주세요.");
        } else if (!filterInvalidObjects(result)) {
          customToast("error", "엑셀파일에 빈값이 있는지 확인해주세요.");
        } else {
          createCustomerFindOrder(result);
          // console.log(result);
          setOpen(false);
        }
      };
      reader.readAsText(file, "euc-kr");
    } else {
      customToast("warn", "엑셀을 선택해 주세요.");
    }

    //참조 초기화
    customerExcelUploadRef!.current!.value = "";
  };

  const handleDownLoad = () => {
    window.location.href = "https://paperlife.kr/template/customer_order_form.csv";
  };
  const customerExcelUploadRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <MenuItem onClick={handleChangeOpen}>
        <ListItemIcon>
          <FileUploadIcon />
        </ListItemIcon>
        고객 주문서 업로드
      </MenuItem>
      <ErrorList data={result} onClose={handleClose} />
      <Dialog
        open={open}
        onClose={() => {
          handleChangeOpen();
          customerExcelUploadRef!.current!.value = "";
        }}
      >
        <DialogTitle>고객 주문서 업로드</DialogTitle>

        <DialogContent>
          <Stack gap={2}>
            <FormControl>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                {Object.keys(customerFindBrandOptions).map((key) => {
                  return (
                    <FormControlLabel
                      id={key}
                      value={key}
                      control={<Radio onChange={() => setInputCustomerBrand(key)} />}
                      label={customerFindBrandOptions[key]}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>

            <label htmlFor="customerFile">
              <Button
                component="span"
                variant="contained"
                tabIndex={-1}
                startIcon={<FileUploadIcon />}
                disabled={inputCustomerBrand === ""}
                sx={{ width: "250px", height: "80px" }}
              >
                고객 주문서 업로드
              </Button>
            </label>
            <input
              ref={customerExcelUploadRef}
              style={{ display: "none" }}
              id="customerFile"
              type="file"
              accept=".csv"
              onChange={(e) => handleUploadCustomerExcelFile(e.target.files)}
            />
            <Button variant="outlined" sx={{ width: "250px", height: "80px" }} onClick={handleDownLoad} startIcon={<InsertDriveFileOutlinedIcon />}>
              양식 다운로드
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleChangeOpen}>
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
