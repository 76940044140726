import GiftSetTable from "../entities/giftset-list/ui/GiftSetTable";

const GiftSetList = () => {
  return (
    <>
      <GiftSetTable />
    </>
  );
};

export default GiftSetList;
