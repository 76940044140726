import { newProductRequestDto } from "../../../service/product/types";

export const SHOW_COLUMN = {
  BarCode: true,
  ProductCode: true,
  Size: true,
  Brand: true,
  Season: true,
  Color: true,
  CreatedAt: true,
  ProductID: true,
  ProductName_ko: true,
  UpdatedAt: true,
  Status: true,
  SKU_ID: true,
  SalePrice: true,
  QuotedPrice: true,
  RocketMargin: true,
  totalCount: true,
  LocalDeliveryFee: true,
  DeliveryPrice: true,
  OrderLink: true,
  OurMargin: true,
  Memo0: true,
  Memo00: true,
  TotalCost: true,
  KoreaCost: true,
  SupplierCompany: true,
  ChinaCost: true,
  Volume: true,
  VAT: true,
  AccountingCost: true,
};

export const HIDE_FILETER = {
  BarCode: true,
  ProductCode: true,
  Size: true,
  Brand: true,
  Season: true,
  Color: true,
  CreatedAt: true,
  ProductID: true,
  ProductName_ko: true,
  UpdatedAt: true,
  Status: true,
  SKU_ID: true,
  Memo0: true,
  Memo00: true,
  SalePrice: false,
  QuotedPrice: false,
  RocketMargin: false,
  totalCount: false,
  LocalDeliveryFee: false,
  DeliveryPrice: false,
  OrderLink: false,
  OurMargin: false,
  TotalCost: false,
  KoreaCost: false,
  SupplierCompany: false,
  ChinaCost: false,
  Volume: false,
  VAT: false,
  AccountingCost: false,
};

export const STATE_OPTION = ["메인", "도매", "단종"];

export const initialState: newProductRequestDto = {
  brand: "",
  status: "",
  page: 1,
  page_size: 32,
  sortby: "BarCode",
  direction: "desc",
  searchText: "",
};
