import SearchIcon from "@mui/icons-material/Search";
import { Button, Stack, TextField } from "@mui/material";
import LinkCard from "./LinkCard";
import LinkSubTitle from "./LinkSubTitle";
import { createPreProductRequestDto } from "../../../service/product/types";
import { useMemo, useState } from "react";
import { useCreatePreProduct } from "../../../service/product/productHooks";
import { customToast } from "../../../shared/lib/customToast";
import BackDropLoading from "../../../shared/ui/loading/BackDropLoading";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import RowStack from "../../../shared/ui/RowStack";
import { brandIconOptions } from "../../side-navigation/const/brandIconsOption";
import SelectForm from "../../../widget/form/ui/SelectForm";

const CreateSingleProduct = () => {
  /***
   * 이미지를 담는게 4개 있어야함
   * 1. 제품이미지
   * 2. 제품 상세이미지
   * 3. 바코드 대표이미지
   * 4. 바코드 추가이미지
   *
   * 1,2 한묶음
   * 3,4 리스트로 한묶음
   */
  const [brandCode, setBrandCode] = useState<string>("");
  const [link, setLink] = useState<string>("");
  const navigate = useNavigate();
  const { mutate: createPreProduct, isPending } = useCreatePreProduct((data: any) => {
    const id = data.success[0].Id;
    if (id) {
      navigate(`/products/update/${id}`);
    } else {
      customToast("error", "상품 생성에 실패했습니다.");
    }
  });

  //조회버튼 누를 때
  const handleCreatePreProduct = () => {
    if (brandCode === "") {
      customToast("info", "브랜드를 선택해주세요");
      return;
    }

    if (link && link.startsWith("https://")) {
      const dto: createPreProductRequestDto = {
        type: "1",
        links: [link],
        brand: brandCode,
        season: 5,
        category_cp: "0",
        category_d8: "0",
      };
      createPreProduct(dto);
    } else {
      customToast("info", "링크를 입력해주세요");
    }
  };

  const brandOptions = useMemo(() => {
    return brandIconOptions.map((item) => ({
      label: item.brandName,
      value: item.brandCode,
    }));
  }, [brandIconOptions]);

  return (
    <>
      <BackDropLoading open={isPending} />
      <Stack gap={2} mb={5}>
        <LinkCard>
          <LinkSubTitle title="제품 링크" required />
          <RowStack gap={2}>
            <SelectForm
              options={brandOptions}
              size="small"
              sx={{ width: "300px" }}
              value={brandCode}
              handleChange={(e) => setBrandCode(e.target.value)}
            />
            <TextField fullWidth value={link} size="small" onChange={(e) => setLink(e.target.value.trim())} />
            <Button
              onClick={handleCreatePreProduct}
              variant="contained"
              startIcon={<SearchIcon />}
              sx={{
                height: 40,
                minWidth: 150,
              }}
              disabled={isPending}
            >
              상품 생성
            </Button>
          </RowStack>
        </LinkCard>
        <RowStack justifyContent="flex-end">
          <Button
            variant="outlined"
            sx={{
              height: 40,
              minWidth: 120,
              backgroundColor: "white",
            }}
            onClick={() => navigate("/products")}
          >
            뒤로가기
          </Button>
        </RowStack>
      </Stack>
    </>
  );
};

export default CreateSingleProduct;
