import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadFileIcon from "@mui/icons-material/UploadFile";

interface ImageUploadProps {
  maxImages?: number;
  width: string;
  height: string;
  images: string[];
  onChange: (maxImages: number, acceptedFiles: File[], index?: number) => void;
  onRemoveImage: (index?: number) => void;
  index?: number;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  onRemoveImage,
  maxImages = 1,
  width = "100px",
  height = "100px",
  images,
  onChange,
  index = 0,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onChange(maxImages, acceptedFiles, index);
    },
    [maxImages, images]
  );

  const [showButtonList, setShowButtonList] = useState<boolean[]>([]);

  useEffect(() => {
    setShowButtonList(images.map(() => false));
  }, [images]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
    multiple: true,
  });

  const handleDeleteImage = () => {
    if (confirm("이미지를 삭제하시겠습니까? 이작업은 되돌릴 수 없습니다.")) {
      onRemoveImage(index);
    }
  };

  const handleDownLoadImageByFetch = async (index: number) => {
    const imageUrl = images[index];
    const response = await fetch(imageUrl + "?compress=false");
    const blob = await response.blob();

    console.log(imageUrl);

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = imageUrl.split("/").pop() || "image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(link.href);
  };

  const handleOnMouseEnter = (index: number) => {
    setShowButtonList((prev) => prev.map((v, i) => (i === index ? true : false)));
  };

  const handleOnMouseLeave = (index: number) => {
    setShowButtonList((prev) => prev.map((v, i) => (i === index ? false : false)));
  };

  return (
    <>
      <Stack justifyContent={"start"} direction={"row"} gap={2}>
        {images.map((image, i) => (
          <Box key={i} sx={{ position: "relative" }} onMouseEnter={() => handleOnMouseEnter(i)} onMouseLeave={() => handleOnMouseLeave(i)}>
            <img src={typeof image === "object" ? URL.createObjectURL(image) : image} alt={"이미지"} style={{ width, height }} />
            {i === images.length - 1 && (
              <Tooltip title={<p>이미지 삭제</p>} arrow placement="top">
                <IconButton onClick={handleDeleteImage} sx={{ position: "absolute", top: 0, right: 0, color: "red" }}>
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            )}
            {showButtonList[i] && (
              <Tooltip title={<p>이미지 다운로드</p>} arrow placement="top">
                <IconButton onClick={() => handleDownLoadImageByFetch(i)} sx={{ position: "absolute", bottom: 0, right: 0, color: "black" }}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ))}
        {images.length < maxImages && (
          <Box
            {...getRootProps()}
            style={{
              width,
              height,
              border: "2px dashed black",
              padding: "20px",
              boxSizing: "border-box",
              borderRadius: "16px",
              backgroundColor: isDragActive ? "#eef5fc" : "white",
            }}
          >
            <input {...getInputProps()} style={{ display: "none" }} />
            <Stack alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
              <UploadFileIcon sx={{ fontSize: "40px" }} />
              <Typography textAlign={"center"}>
                {isDragActive ? (
                  <p>이미지를 여기에 놓으세요...</p>
                ) : (
                  <p>
                    이미지를 드롭하거나
                    <br /> 클릭하여 선택하세요
                  </p>
                )}
              </Typography>
            </Stack>
          </Box>
        )}
      </Stack>
    </>
  );
};

export default ImageUpload;
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DownloadIcon from "@mui/icons-material/Download";
