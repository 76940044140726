import { useModals } from "../../service/modal/useModals";
import { ShowOrderItemsByBarCodeModal } from "./ShowOrderItemsByBarCodeModal";
import { Button, Typography } from "@mui/material";

interface ShowOrderItemsByBarCodeButtonProps {
  BarCode: string;
  name?: string;
}

const ShowOrderItemsByBarCodeButton: React.FC<ShowOrderItemsByBarCodeButtonProps> = ({ BarCode, name }) => {
  const { closeModal, openModal } = useModals();

  const handleClose = () => {
    closeModal(ShowOrderItemsByBarCodeModal);
  };

  const handleClickOpen = () => {
    openModal(ShowOrderItemsByBarCodeModal, { BarCode, handleClose });
  };

  return (
    <>
      {/* <CustomButton onClick={handleClickOpen}>보기</CustomButton> */}
      {!name ? (
        <Button onClick={handleClickOpen} variant="outlined">
          {" "}
          보기
        </Button>
      ) : (
        <Typography
          sx={{
            color: "#1976d2",
            cursor: "pointer",
            fontSize: "12px",
            whiteSpace: "normal",
            wordBreak: "keep-all",
            overflowWrap: "break-word",
            width: "100%",
            height: "100%",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
          }}
          onClick={handleClickOpen}
        >
          {name}
        </Typography>
      )}
    </>
  );
};

export default ShowOrderItemsByBarCodeButton;
