import NewOrderListTable from "../entities/order-list/ui/NewOrderListTable";

const OrderList = () => {
  return (
    <>
      <NewOrderListTable />
    </>
  );
};

export default OrderList;
