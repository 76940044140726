import styled from "@emotion/styled";

export const StatusBadge = styled.span<{
  color?: string;
  backgroundColor?: string;
}>`
  padding: 8px 8px 8px 25px; /* 왼쪽 여백 추가 */
  background-color: ${({ backgroundColor }) => backgroundColor || "#f1f1f1"};
  color: ${({ color }) => color || "black"};
  border-radius: 5px;
  position: relative; /* 부모 요소를 기준으로 위치 설정 */

  &::before {
    content: ""; /* 빈 요소로 생성 */
    position: absolute; /* 위치를 절대값으로 설정 */
    left: 8px; /* 왼쪽 여백 */
    top: 50%; /* 세로 가운데 정렬 */
    transform: translateY(-50%); /* 정확히 가운데 정렬 */
    opacity: 0.8; /* 투명도 조절 */
    width: 8px; /* 점의 너비 */
    height: 8px; /* 점의 높이 */
    background-color: ${({ color }) => color || "black"}; /* 점의 색상 */
    border-radius: 50%; /* 점을 동그랗게 만듦 */
    margin-right: 8px; /* 점과 텍스트 사이의 여백 */
  }
`;
