import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserInfoState } from "../store/UserInfoStore";
import { useLogin } from "../service/user/userHook";
import backgroundImage from "../assets/image/loginBackground.webp";
import { customToast } from "../shared/lib/customToast";

const isDev = process.env.REACT_APP_MODE === "DEV" ? true : false;
export const BACKGROUND_COLOR = "white";
const NEW_VERSON = process.env.REACT_APP_VERSION;

const Login = () => {
  const naivigate = useNavigate();
  const { UserName } = useUserInfoState();
  const { mutate: login } = useLogin();
  const [loginData, setLoginData] = useState({
    userName: !isDev ? "" : "김범식",
    userPhone: !isDev ? "" : "12341234",
  });

  useEffect(() => {
    //auto login
    if (!!UserName) {
      naivigate("/orders");
    }

    const OLD_VERSION = localStorage.getItem("VERSION");
    if (OLD_VERSION !== NEW_VERSON) {
      // localStorage.clear();
      localStorage.removeItem("category"); // 컬럼 초기화
      // localStorage.removeItem("TABLE_WIDTH"); // 컬럼 초기화
      localStorage.setItem("VERSION", NEW_VERSON || "");
    }
  }, []);

  const handleChangeLoginData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setLoginData({
      ...loginData,
      [id]: value.replaceAll(" ", ""),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!loginData.userName || !loginData.userPhone) {
      customToast("warn", "모든 항목을 입력해주세요.");
    } else if (!/^\d{8}$/.test(loginData.userPhone)) {
      customToast("warn", "개인식별번호는 8자리 숫자로 입력해주세요.");
    } else {
      login({ UserName: loginData.userName, Password: loginData.userPhone });
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)", // 검은색 투명도 조절 가능
          backdropFilter: "blur(8px)", // 흐림 효과
          zIndex: 1, // 배경 이미지 위에 요소를 쌓기 위해 필요
        }}
      >
        <Stack
          component={"form"}
          autoComplete="off"
          onSubmit={handleSubmit}
          sx={{
            zIndex: 10,
            margin: "0 auto",
            p: 3,
            py: 5,
            width: "300px",
            borderRadius: "10px",
            position: "relative",
            top: "45%",
            transform: "translateY(-50%)",
            backgroundColor: BACKGROUND_COLOR,
            boxShadow: 4,
          }}
          gap={2}
        >
          {/* <Box sx={{ textAlign: "center", mb: 4 }}>
          <img src={logo} alt="로고" width={300} />
          </Box> */}
          <Stack alignItems={"center"} sx={{ mb: 3 }}>
            <Typography variant="h4" color="primary" fontSize={"38px"}>
              PAPERLIFE
            </Typography>
            <Typography variant="h4">재고관리시스템</Typography>
          </Stack>
          <TextField
            sx={{ backgroundColor: BACKGROUND_COLOR }}
            label="이름"
            id="userName"
            onChange={handleChangeLoginData}
            value={loginData.userName}
          />
          <TextField
            sx={{ backgroundColor: BACKGROUND_COLOR }}
            type="password"
            label="개인식별번호"
            id="userPhone"
            placeholder="8자리 숫자를 입력해 주세요"
            onChange={handleChangeLoginData}
            value={loginData.userPhone}
          />
          <Button variant="contained" fullWidth type="submit" sx={{ height: "55px", mt: 2 }}>
            로그인
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default Login;
