import { getPreProductDto } from "../../../service/product/types";

export const preproduct_initial_value: getPreProductDto = {
  Brand: "",
  OurSalePrice: 0,
  BuyerName: "", //수입자명 페이퍼먼츠 협력사
  Category_CP: "",
  Category_CP_detail: "",
  Category_D8: "",
  CottonInfo_en: "",
  CottonInfo_ko: "",
  CreatedAt: "",
  DisplayAdress: "",
  FirstSaleSeason: "",
  Id: 0,
  ImageLink: [],
  ImageLink_sebu: [],
  Laundry: "",
  MadeCountry: "",
  ProductCode: "",
  ProductManufactureType: "",
  ProductName_ko: "",
  ProductName_zh: "",
  Productlink: "",
  Season: "",
  StyleNumber: "",
  SupplierCompany: "",
  CreateUserId: "",
  UpdatedAt: "",
  UpdateUserId: "",
  PreProductMemo: "",
  PreProductStatus: 0,
  IsInUse: false,
  UseAge: "",
  Volume: 0,
  SearchTag: "",
  purchaseadress: "",
};
