import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import NumbersIcon from "@mui/icons-material/Numbers";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { copyToClicpboard } from "../../../rocket/lib/copyToClipBoard";
import { Box, Stack, Typography, Tooltip, IconButton, Chip, Divider, Grid } from "@mui/material";
import { useMemo } from "react";
import { HistoryType, useHistoryStore } from "../../../../store/totalOrderHIstory";
import RowStack from "../../../../shared/ui/RowStack";
interface HistoryItemProps {
    data: HistoryType;
  }
  
  const itemStyle = { color: "#999", display: "flex", flexDirection: "row", gap: 2, alignItems: "center" };
  
  const HistoryItem: React.FC<HistoryItemProps> = ({ data }) => {
    const navigate = useNavigate();
    const { deleteHistory } = useHistoryStore((v) => v);
  
    const handleMove = () => {
      navigate(`/orders/${data.OrderID}?keyword=${data.BarCode}`);
    };
  
    const handleDeleteHistory = () => {
      if (confirm("정말 삭제하시겠습니까?")) deleteHistory(data.Id);
    };
  
    const handleCopy = () => {
      copyToClicpboard(data.BarCode);
    };
  
    const color = useMemo(() => {
      if (data.Type === "flex" && data.OrderAlias.includes("사전확정")) {
        return "red";
      }
  
      if (data.Type === "flex") {
        return "#0068D0";
      }
  
      if (data.Type === "box") {
        return "#A1784A";
      }
  
      if (data.Type === "greenBasket") {
        return "green";
      }
      return "";
    }, [data.Type]);
  
    return (
      <>
        <Box sx={{ p: 3, backgroundColor: "white" }}>
          <RowStack sx={{ mb: 2 }}>
            {data.Type === "greenBasket" ? (
              <Typography
                sx={{
                  ...itemStyle,
                  borderLeft: `6px solid ${color}`,
                  color: "#666",
                  pl: 2,
                  mb: 2,
                }}
              >
                {data.OrderAlias}
              </Typography>
            ) : (
              <Tooltip title={<p> 클릭시 발주서로 이동합니다.</p>} arrow placement="right">
                <Typography
                  sx={{
                    ...itemStyle,
                    borderLeft: `6px solid ${color}`,
                    color: "#666",
                    pl: 2,
                    mb: 2,
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                      color: "blue",
                    },
                  }}
                  onClick={handleMove}
                >
                  {data.OrderAlias}
                  <OpenInNewIcon />
                </Typography>
              </Tooltip>
            )}
  
            <Tooltip title={<p>삭제</p>} arrow placement="left">
              <IconButton onClick={handleDeleteHistory} sx={{ "&:hover": { color: "red" }, color: "#999" }}>
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          </RowStack>
  
          <Typography sx={{ ...itemStyle, color: "#2979ff", mb: 1 }}>
            {data.BarCode}
            <Tooltip title={<p>바코드 복사</p>} arrow placement="right">
              <ContentCopyIcon fontSize="small" onClick={handleCopy} sx={{ cursor: "pointer" }} />
            </Tooltip>
          </Typography>
          <Typography
            fontSize={"18px"}
            fontWeight={"bold"}
            sx={{ color: "#333", whiteSpace: "normal", wordBreak: "keep-all", overflowWrap: "break-word" }}
          >
            {data.ProductName_ko}
          </Typography>
          <Stack direction={"row-reverse"} gap={2} sx={{ mt: 3 }}>
            <Chip variant="outlined" label={data.Color} color="primary" />
            <Chip variant="outlined" label={data.Size} color="primary" />
          </Stack>
          <Divider sx={{ my: 4 }} />
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography variant="subtitle2" sx={itemStyle}>
                <ShoppingBasketIcon />
                {data.BasketID}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" sx={itemStyle}>
                <CheckroomIcon />
                {data.Count}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {" "}
              <Typography variant="subtitle2" sx={itemStyle}>
                <AccountCircleIcon />
                {data.CreatedBy}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              {" "}
              <Typography variant="subtitle2" sx={itemStyle}>
                <DateRangeIcon />
                {data.CreatedAt.split(". 오")[0]}.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" sx={itemStyle}>
                <AccessTimeIcon />오{data.CreatedAt.split(". 오")[1]}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {" "}
              <Typography variant="subtitle2" sx={itemStyle}>
                <NumbersIcon />
                No.{data.Id}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };
  

export default HistoryItem;
