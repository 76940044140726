import { Box, Button, Card, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import RowStack from "../../../../shared/ui/RowStack";
import { useDeleteBarcodeAlert } from "../../../../service/barcode-alert/barcodeAlertHooks";

interface Props {
  imageSrc: string;
  message: string;
  desc_text?: string;
  id?: number;
  quantity?: number;
}

const CardAlert: React.FC<Props> = ({ id, imageSrc, message, desc_text = "", quantity = 0 }) => {
  const { mutate: deleteBarCodeAlert } = useDeleteBarcodeAlert();

  const handleDelete = () => {
    if (id && confirm("정말 삭제하시겠습니까?")) {
      deleteBarCodeAlert([id]);
    }
  };
  return (
    <Box
      sx={{
        minWidth: "180px",
        maxWidth: "300px",
        px: 5,
        py: 4,
        borderRadius: "8px 8px 8px 8px",
        borderLeft: "20px solid #ffc100",
        backgroundColor: "white",
      }}
    >
      <RowStack gap={5}>
        {imageSrc ? <img src={imageSrc} alt="이미지" height="60px" /> : <img src={DEFAULT_IMG} alt="이미지" height="60px" />}
        <Stack gap={2}>
          <Typography sx={{ color: "#333", fontSize: "18px" }}>{message}</Typography>
          <Typography sx={{ color: "#999" }} variant="caption">
            {desc_text}
          </Typography>
          <Typography sx={{ color: "#999" }} variant="caption">
            수량 : {quantity.toLocaleString()}장
          </Typography>
        </Stack>
        <Tooltip sx={{ zindex: 100000 }} title={<p>알람 삭제하기</p>} arrow placement="right">
          <IconButton color="error">
            <DeleteOutlineIcon onClick={handleDelete} />
          </IconButton>
        </Tooltip>
      </RowStack>
    </Box>
  );
};

export default CardAlert;

// export const DEFAULT_IMG = "https://cdn-icons-png.flaticon.com/128/17235/17235414.png";
export const DEFAULT_IMG = "https://cdn-icons-png.flaticon.com/128/6897/6897039.png";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

// VI24010620511
