import React from "react";
import { Button, Card, Stack } from "@mui/material";
import "react-data-grid/lib/styles.css";
import { useGetBulkEstimateExcelFile, useTranslate } from "../service/product/productHooks";
import { useCreateBarcodeAlert } from "../service/barcode-alert/barcodeAlertHooks";
import { useCreateBasket } from "../service/basket/BasketHooks";
import { STEP_LIST } from "../entities/link/ui/form/ProductProcessStep";
import { StatusBadge } from "../entities/product/style/StatusBadge";
import BackDropLoading from "../shared/ui/loading/BackDropLoading";

const list = [["barcode", 1, 7521011]];

const Test: React.FC = () => {
  const { mutateAsync: createBasket } = useCreateBasket();
  const { mutateAsync: getExcelFile, isPending } = useGetBulkEstimateExcelFile();
  const { mutate: translate } = useTranslate();

  const handleTranslate = async () => {
    translate({ text: "안녕하세요", source: "ko", target: "en" });
  };

  const handleCreateBasket = async () => {
    for (let i = 622051; i <= 622251; i++) {
      console.log(i, " / 622251");
      await createBasket({ BasketID: i.toString(), IsCoupang: false, CoupangBaskID: "" });
    }
  };

  const handleGetExcelFile = async () => {
    await getExcelFile({ Brand: "PA", ischnagePreProductStatus: 1, targetPreProductStatus: 6, changePreProductStatus: 6 });
  };

  const { mutate: createBarcodeAlert } = useCreateBarcodeAlert();

  const handleCreateBarCodeAlert = async () => {
    const dto = a.map((v) => ({
      barcode: v,
      message: "(캡, 야구모자) 박스포장 상품입니다.",
      desc_text: "",
      image_url: "",
      create_user: "admin",
      quantity: 9999,
      type: "giftset",
    }));

    createBarcodeAlert(dto);
  };

  return (
    <>
      <BackDropLoading open={isPending} />
      <Card sx={{ mt: 10, width: "400xp", margin: "100px auto" }}>
        <Stack gap={5} alignItems={"center"}>
          {STEP_LIST.map((step, index) => (
            <StatusBadge color={step.color} backgroundColor={step.backgroundColor}>
              {step.title}
            </StatusBadge>
          ))}
          <Button onClick={handleGetExcelFile}>페이퍼먼츠 견적 할거 다운로드 </Button>
          <Button onClick={handleCreateBarCodeAlert} disabled>
            캡, 야구모자 상품등록
          </Button>
        </Stack>
      </Card>
    </>
  );
};

export default Test;

const a = [
  "CH24030650311",
  "CH24030650321",
  "CH24030650331",
  "CH24030650341",
  "CH24030650351",
  "PA20242299511",
  "PA20242299521",
  "PA20242299531",
  "PA20242299541",
  "PA20242299551",
  "PA20242299561",
  "PA20242299571",
  "PA20242299581",
  "PA24082299611",
  "PA24082307711",
  "PA24082307721",
  "PA24082307731",
  "PA24082307741",
  "PA24082307751",
  "PA24082307761",
  "PA24082307771",
  "PA24082307781",
  "PA24092339211",
  "PA24092339221",
  "PA24092339231",
  "PA24092339241",
  "PA24092339311",
  "PA24092339321",
  "PA24092339331",
  "PA24092339341",
  "PA24092339351",
  "PA24092340311",
  "PA24092340321",
  "PA24092340331",
  "PA24092340341",
  "PA24092340411",
  "PA24092340421",
  "PA24092340431",
  "PA24092340441",
  "PA24092340451",
  "PA24092340461",
  "PA24092341811",
  "PA24092341821",
  "PA24092341831",
  "PA24092342111",
  "PA24092342121",
  "PA24092342131",
  "PA24092342141",
  "PA24092342151",
  "PA24092342221",
  "PA24092342231",
  "PA24092342241",
  "PA24092342251",
  "PA24092342261",
  "PA24092342711",
  "PA24092342721",
  "PA24092342731",
  "PA24092342741",
  "PA24092342751",
  "PA24092342761",
  "PA24092342771",
  "PA24092342781",
  "PA24092342791",
  "PA240923427A1",
  "PA24092342911",
  "PA24092342921",
  "PA24092342931",
  "PA24092343111",
  "PA24092343121",
  "PA24092343131",
  "PA24092345111",
  "PA24092345121",
  "PA24092345131",
  "PA24092345141",
  "PA24092345151",
  "PA24092345161",
  "PA24092345171",
  "PA24092345181",
  "PA24092345311",
  "PA24092345321",
  "PA24092345331",
  "PA24092345511",
  "PA24092345521",
  "PA24092345531",
  "PA24092345611",
  "PA24092345621",
  "PA24092345631",
  "PA24092345641",
  "PA24092345651",
  "PA24092345661",
  "PA24092345671",
  "PA24092345681",
  "PA24092345711",
  "PA24092345721",
  "PA24092345731",
  "PA24092345741",
  "PA24101267411",
  "PA24101267412",
  "PA24101267413",
  "PA24101267414",
  "PA24101267415",
  "PA24101268011",
  "PA24101268021",
  "PA24101268031",
  "PA24101268041",
  "PA24101268051",
];
