import { Button, Dialog, DialogContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { getBoxNameListByOrderItemIdResponseDto } from "../../../service/total-order/types";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
interface HistoryProps {
  rows: getBoxNameListByOrderItemIdResponseDto[];
}

export const ShowHistory: React.FC<HistoryProps> = ({ rows }) => {
  const [open, setOpen] = useState<boolean>(false);
  const handeOpenChange = () => {
    setOpen(!open);
  };

  return (
    <>
      <Button variant="text" onClick={handeOpenChange}>
        내역보기
      </Button>
      <Dialog open={open} onClose={handeOpenChange}>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#eaeaea" }}>
                  <TableCell sx={{ fontWeight: "bold" }}>상자이름</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="center">
                    변동내역
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="center">
                    담당자
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    시간
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.ToObjectCode}
                    </TableCell>
                    <TableCell align="right" sx={{ color: row.Quantity > 0 ? "red" : "blue", display: "flex", alignItems: "center" }}>
                      {" "}
                      {row.Quantity > 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} {Math.abs(row.Quantity).toLocaleString()}
                    </TableCell>
                    <TableCell align="right">{row.FulFilledBy}</TableCell>
                    <TableCell align="right">{row.FulfillmentDate.slice(0, 19).split("T").join(" ")}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};
