import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, TextField, Stack, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { createBarcodeAlertDto } from "../../../../service/barcode-alert/types";
import { useUserInfoState } from "../../../../store/UserInfoStore";
import AddIcon from "@mui/icons-material/Add";
import RowStack from "../../../../shared/ui/RowStack";
import RemoveIcon from "@mui/icons-material/Remove";
import { useCreateBarcodeAlert } from "../../../../service/barcode-alert/barcodeAlertHooks";
import { customToast } from "../../../../shared/lib/customToast";
import { ALERT_ICON_LIST } from "../../const/const";

const CreateAlertButton = () => {
  const [open, setOpen] = useState(false);
  const UserName = useUserInfoState((v) => v.UserName);
  const [barcodeList, setBarCodeList] = useState<string[]>([""]);
  const { mutate: createBarcodeAlert } = useCreateBarcodeAlert();
  const [iconIndex, setIconIndex] = useState<number>(0);
  const [params, setParams] = useState<Omit<createBarcodeAlertDto, "barcode">>({
    message: "",
    desc_text: "",
    image_url: "",
    create_user: UserName,
    quantity: 9999,
    type: "giftset",
  });

  useEffect(() => {
    if (!open) {
      setBarCodeList([""]);
      setParams({
        message: "",
        desc_text: "",
        image_url: "",
        create_user: UserName,
        quantity: 9999,
        type: "giftset",
      });
    }
  }, [open]);

  const handleAddBarCode = () => {
    setBarCodeList([...barcodeList, ""]);
  };

  const handleRemoveBarCode = (index: number) => {
    setBarCodeList(barcodeList.filter((_, i) => i !== index - 1));
  };

  const handleChangeParams = (e: any) => {
    const { name, value } = e.target;
    setParams({ ...params, [name]: value });
  };

  const handleChangeOpen = () => {
    setOpen(!open);
  };

  const handleSubmit = () => {
    const dtoList: createBarcodeAlertDto[] = barcodeList
      .filter((v) => !!v)
      .map((barcode: string) => ({
        barcode,
        ...params,
      }));

    if (dtoList.length !== 0) {
      createBarcodeAlert(dtoList);
      handleChangeOpen();
    } else {
      customToast("error", "바코드를 입력해주세요.");
    }
  };

  const handleSelectIcon = (index: number) => {
    setIconIndex(index);
    setParams({ ...params, image_url: ALERT_ICON_LIST[index] });
  };

  return (
    <>
      <Button variant="contained" startIcon={<AddIcon />} onClick={handleChangeOpen} sx={{ width: "200px" }} size="large">
        바코드알림 추가
      </Button>
      <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: "16px", color: "#333" }}>
          바코드 추가
        </DialogTitle>
        <DialogContent>
          <Stack gap={2} sx={{ mb: 3 }}>
            <Stack direction="row" sx={{ flexWrap: "wrap" }} gap={2}>
              {ALERT_ICON_LIST.map((icon, index) => (
                <img
                  onClick={() => handleSelectIcon(index)}
                  src={icon}
                  alt="barcode"
                  key={index}
                  onDragStart={(e) => e.preventDefault()}
                  style={{
                    userSelect: "none",
                    boxSizing: "border-box",
                    cursor: "pointer",
                    width: "120px",
                    height: "120px",
                    border: iconIndex === index ? "5px solid #6799ff" : "1px solid #dfdfdf",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                />
              ))}
            </Stack>

            {barcodeList.map((_, i) => (
              <RowStack key={i}>
                <TextField
                  value={barcodeList[i]}
                  label="바코드"
                  name="barcode"
                  placeholder="ex) PA24111234511"
                  onChange={(e: any) => {
                    setBarCodeList((prev: any) => {
                      const list = [...prev];
                      list[i] = e.target.value.trim();
                      return list;
                    });
                  }}
                  fullWidth
                />
                <IconButton onClick={handleAddBarCode}>
                  <AddIcon />
                </IconButton>
                <IconButton onClick={() => handleRemoveBarCode(i)} disabled={i === 0}>
                  <RemoveIcon />
                </IconButton>
              </RowStack>
            ))}
            <Typography variant="caption" sx={{ ml: 1 }} color="primary">
              바코드를 한번에 여러개 추가할 수 있습니다
            </Typography>
            <TextField
              multiline
              rows={3}
              value={params.message}
              label="메시지"
              name="message"
              placeholder="알림에 표시될 메시지를 입력해주세요."
              onChange={handleChangeParams}
              sx={{ mt: 5 }}
            />
            <TextField
              multiline
              rows={3}
              value={params.desc_text}
              label="설명"
              name="desc_text"
              placeholder="알림을 추가하는 이유를 작성해주세요."
              onChange={handleChangeParams}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={handleSubmit} autoFocus variant="contained">
            등록
          </Button>
          <Button onClick={handleChangeOpen} variant="outlined">
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateAlertButton;
