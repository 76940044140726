import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { router, queryClient } from "./config";
import { QueryClientProvider } from "@tanstack/react-query";
import { ModalProvider } from "./service/modal/useModalsTest";
import "react-data-grid/lib/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ExcelDataProvider } from "./service/order/orderContext";
import { ThemeProvider } from "@mui/material";
import theme from "./config/theme";

// @font-face 규칙을 사용하여 커스텀 폰트를 추가
const customFont = `
@font-face {
  font-family: "malgun";
  src: url("./assets/font/malgun.ttf");
}
`;

// 스타일 태그를 사용하여 커스텀 폰트를 추가
const style = document.createElement("style");
style.appendChild(document.createTextNode(customFont));
document.head.appendChild(style);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ExcelDataProvider>
          <ToastContainer autoClose={2000} limit={3} position="top-center" hideProgressBar={true} />
          <ModalProvider>
            <RouterProvider router={router} />
          </ModalProvider>
        </ExcelDataProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.addEventListener("error", (event) => {
  console.log(event.error);
  console.log(event);
});
