import * as React from "react";
import {
  GridToolbar,
  GridRowModesModel,
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRowModel,
  GridRowEditStopReasons,
  GridCellEditStopReasons,
} from "@mui/x-data-grid";
import //   randomTraderName,
"@mui/x-data-grid-generator";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { dataGridStyle } from "../../../shared/style/mui-table-style/MuiTableStyle";
import { getTogglableColumns } from "../../../shared/lib/columnMenuPanel";
import FlipCameraAndroidOutlinedIcon from "@mui/icons-material/FlipCameraAndroidOutlined";
import {
  Button,
  Card,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  useDeleteProduct,
  useGetPreProductList,
  useNewGetProductList,
  useUpdatePreProduct,
  useUpdateProduct,
} from "../../../service/product/productHooks";
import { statusOptions } from "../../../shared/const/statusOptions";
import { getPreProductDto, newProductRequestDto, updateProductDto } from "../../../service/product/types";
import BrandEditCell from "../../../widget/form/ui/SelectBrandCell";
import { seasonList } from "../../../shared/const/seasonOption";
import SelectForm from "../../../widget/form/ui/SelectForm";
import ShowInventoryButton from "../../../widget/show-inventory-modal/ui/ShowInventoryButton";
import { HIDE_FILETER, initialState, SHOW_COLUMN, STATE_OPTION } from "../const/const";
import { isKeyboardEvent } from "../lib/isKeyboardEvent";
import { EditTextarea } from "./EditTextarea";
import { UpdateProductButton } from "./update-product-modal/UpdateProductButton";
import { brandIconOptions, brandOptions } from "../../side-navigation/const/brandIconsOption";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useProductListStateStore } from "../../../store/showColumnToggleStore";
import ExportToExcelButtonInProductTable from "./ExportToExcelButtonInProductTable";
import SearchInputField from "../../../widget/form/ui/SearchInpuField";
import RowStack from "../../../shared/ui/RowStack";
import RefreshIconButton from "../../../shared/ui/buttom/RefreshIconButton";
import { useUserInfoState } from "../../../store/UserInfoStore";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BulkStatusUpdateModal from "./bulk-status-update-modal/BulkStatusUpdateModal";
import CustomPagination from "./table-pagination/CustomPagination";
import { statusType } from "../type/types";
import ShowOrderItemsByBarCodeButton from "../../../widget/show-orderitems-by-barcode-modal/ShowOrderItemsByBarCodeButton";
import { productDatatoExcelFormmat } from "../lib/productDataToExcelformmat";
import { getCurrentDateTime } from "../../individual-order/lib/getCurrentDateTime";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { useColumnStore } from "../../../store/dataGridColumnStore";
import { queryClient } from "../../../config";
import BackDropLoading from "../../../shared/ui/loading/BackDropLoading";
import noImage from "../../../assets/image/no_image.png";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { STEP_LIST } from "../../link/ui/form/ProductProcessStep";
import styled from "@emotion/styled";
import { StatusBadge } from "../style/StatusBadge";
import { MultiLineStyle } from "../style/MutiLineStyle";
import { maxLineCss } from "../style/MaxLineStyle";

const imageUrl = process.env.REACT_APP_IMAGE_URL;
const DELEABLE_ROLD_ID = [101, 100];

export default function NewProductTable() {
  // const [state, setUndoableState] = useUndoableState<any>({ initialState: [], onUndo: (data: any) => {}, onRedo: (data: any) => {} });
  const { getColumns, setColumns } = useColumnStore((v) => v);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({}); // 현재 변경중인 값
  const [selected, setSelected] = React.useState<number[]>([]);
  const {
    showColumnToggle,
    setShowColumnToggle,
    viewMode,
    setViewMode,
    status,
    setStatus,
    preProductStatus,
    setPreProductStatus,
    brand,
    setBrand,
    setSearchText,
    searchText,
  } = useProductListStateStore((state) => state);
  const [params, setParams] = React.useState<newProductRequestDto>(initialState);
  const RoleID = useUserInfoState((state) => state.RoleID);
  const { mutateAsync: deleteProduct, isPending: deletePeding } = useDeleteProduct();
  const { mutateAsync: updatePreProduct } = useUpdatePreProduct((data) => {
    refetch();
  });
  const navigate = useNavigate();
  const handleChangeParams = (e: any) => {
    const { name, value } = e.target;
    let newValue = typeof value === "string" ? value.trim().toLocaleLowerCase() : value;
    setParams((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const handleChangeSearchText = (e: any) => {
    setSearchText(e.target.value);
  };

  const handleChangeStatus = (e: any) => {
    setStatus(e.target.value);
  };

  const handleChangeBrand = (e: any) => {
    setBrand(e.target.value);
  };

  const handleChangePreProductStatus = (e: any) => {
    setPreProductStatus(e.target.value);
  };

  const handleViewModeChange = (mode: "table" | "card") => {
    setViewMode(mode);
  };

  React.useEffect(() => {
    setParams((prev) => ({
      ...prev,
      page: 1, //페이지 조정
    }));
  }, [params.searchText, params.status, params.brand]);

  //도메 메인 단종 변경

  //상품 리스트
  const { data: rows, refetch, isLoading, isFetching } = useNewGetProductList({ ...params, status, brand, searchText });
  const {
    data: preRow,
    refetch: preRefetch,
    isFetching: preIsFetching,
    isLoading: preIsLoading,
  } = useGetPreProductList({ ...params, sortby: "Id", preProductStatus: preProductStatus, brand, searchText });
  const { mutate: updateProduct } = useUpdateProduct((data: any) => {
    refetch;
  });

  //편집 중지
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const updatedKeys = Object.keys(newRow).filter((key) => newRow[key] !== oldRow[key]);
    const preProductKeys = ["ProductName_ko", "Season"];

    const updatedRow = { ...newRow, isNew: false };

    const BarCode = newRow.BarCode;
    const Id = newRow.Id;

    if (preProductKeys.includes(updatedKeys[0])) {
      const dto: any = { Id, [updatedKeys[0]]: newRow[updatedKeys[0]] };
      updatePreProduct(dto);
    } else {
      const dto: updateProductDto = { BarCode, [updatedKeys[0]]: newRow[updatedKeys[0]] };
      updateProduct(dto);
    }
    // const previousValue: updateProductDto = { BarCode: newRow.BarCode, [updatedKeys[0]]: oldRow[updatedKeys[0]] };
    // setUndoableState(() => previousValue); // 상태 이력 추가

    return updatedRow;
  };

  const brandCodeToImage = React.useMemo(() => {
    const result: {
      [key: string]: {
        imagePath: string;
        brandName: string;
      };
    } = {};

    brandIconOptions.forEach((v) => {
      result[v.brandCode] = {
        imagePath: v.imagePath,
        brandName: v.brandName,
      };
    });

    return result;
  }, [brandIconOptions]);

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: "ImageLink",
      headerName: "이미지",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        return (
          <img src={row?.ImageLink ? `${imageUrl}/${row.ImageLink}` : noImage} alt={row.ProductName_ko} style={{ width: "100%", height: "100%" }} />
        );
      },
    },
    {
      field: "BarCode",
      headerName: "바코드",
      type: "string",
      width: 120,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        const { BarCode } = row;
        return <span>{BarCode}</span>;
      },
    },
    {
      field: "ProductName_ko",
      headerName: "상품명",
      width: 250,
      // align: "center",
      editable: true,
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        return (
          <div style={MultiLineStyle as any}>
            <span
              style={{
                //  textDecoration: "underline",
                whiteSpace: "normal",
                wordBreak: "keep-all",
                overflowWrap: "break-word",
              }}
            >
              {row.ProductName_ko}
            </span>
          </div>
        );
      },
    },
    {
      field: "Memo0",
      editable: true,
      headerName: "자동상품메모",
      width: 200,
      align: "left",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { Memo0 } = row;
        return (
          <div style={MultiLineStyle as any}>
            <pre>{Memo0}</pre>
          </div>
        );
      },
      renderEditCell: (params) => <EditTextarea {...params} />,
    },
    {
      field: "Memo00",

      editable: true,
      headerName: "메모",
      minWidth: 200,
      align: "left",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { Memo00 } = row;
        return (
          <div style={MultiLineStyle as any}>
            <pre>{Memo00}</pre>
          </div>
        );
      },
      renderEditCell: (params) => <EditTextarea {...params} />,
    },
    {
      field: "Season",
      headerName: "시즌",
      width: 100,
      align: "center",
      headerAlign: "center",
      editable: true,
      type: "singleSelect",
      valueOptions: ["1", "2", "3", "4", "5"],
      renderCell: ({ row }) => {
        const { Season } = row;
        return <span>{seasonList[Season]}</span>;
      },
    },
    {
      field: "Color",
      editable: true,
      headerName: "색상",
      width: 100,
      //flex: 1,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { Color } = row;
        return <span>{Color}</span>;
      },
    },
    {
      field: "Size",
      editable: true,
      headerName: "사이즈",
      width: 100,
      //flex: 1,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { Size } = row;
        return <span>{Size}</span>;
      },
    },
    {
      field: "Brand",
      headerName: "브랜드",
      width: 120,
      align: "center",
      cellClassName: "cell-divider",
      headerAlign: "center",
      renderCell: ({ row }) => {
        return (
          <RowStack>
            <img src={brandCodeToImage[row.Brand]?.imagePath || ""} alt={row.Brand} style={{ width: "30px", height: "30px" }} />
            <span>{brandCodeToImage[row.Brand]?.brandName || ""}</span>
          </RowStack>
        );
      },
      renderEditCell: ({ api, row, id, field, status }: any) => {
        return <BrandEditCell status={status} row={row} api={api} id={id} field={field} />;
      },
    },
    {
      field: "TotalCount",
      headerName: "재고",
      width: 80,
      //flex: 1,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { TotalCount, BarCode } = row;
        return <ShowInventoryButton BarCode={BarCode} TotalCount={TotalCount || 0} status={status} />;
      },
    },
    {
      headerName: "발주현황",
      field: "OrderStatus",
      align: "center",
      headerAlign: "center",
      type: "actions",
      renderCell: ({ row }) => {
        return <ShowOrderItemsByBarCodeButton BarCode={row.BarCode} />;
      },
    },
    {
      field: "Status",
      headerName: "상태",
      editable: true,
      width: 80,
      //flex: 1,
      align: "center",
      headerAlign: "center",
      type: "singleSelect",
      valueOptions: STATE_OPTION,
      renderCell: ({ row }) => {
        const color = row.Status === "메인" ? "primary" : row.Status === "도매" ? "success" : "error";
        return <Chip variant="outlined" color={color} label={row.Status} />;
      },
    },
    {
      field: "SKU_ID",
      editable: true,
      headerName: "SKU ID",
      width: 120,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: (params) => {
        const skuId = params.row.SKU_ID;
        return <span>{skuId ? skuId : "-"}</span>;
      },
    },

    {
      field: "SupplierCompany",
      headerName: "거래처",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: (params) => {
        const buy = params.row.SupplierCompany;
        return <span>{buy ? buy : "-"}</span>;
      },
    },

    {
      field: "OrderLink",
      editable: true,
      headerName: "주문링크",
      width: 200,
      // align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const OrderLink = row.OrderLink;
        const handleClick = () => window.open(OrderLink, "_blank");

        return (
          <Typography variant="caption" sx={{ position: "relative" }}>
            {OrderLink}
            {OrderLink && (
              <Tooltip placement="right" arrow title={<p>링크로 이동합니다.</p>}>
                <IconButton
                  sx={{ position: "absolute", top: -5, right: 0, backgroundColor: "white", border: "1px solid #dddddd", "&hover": "none" }}
                  onClick={handleClick}
                >
                  <OpenInNewIcon color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
        );
      },
    },
    //--------------------------------------------------------
    {
      field: "ChinaCost",
      editable: true,
      headerName: "중국원가",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { ChinaCost } = row;
        return <span>{parseInt(ChinaCost).toLocaleString()}</span>;
      },
    },
    {
      field: "LocalDeliveryFee",
      editable: true,
      headerName: "현지배송비",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { LocalDeliveryFee } = row;
        return <span>{parseInt(LocalDeliveryFee).toLocaleString()}</span>;
      },
    },
    {
      field: "TotalCost",
      editable: true,
      headerName: "총원가",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { TotalCost } = row;
        return <span>{parseInt(TotalCost).toLocaleString()}</span>;
      },
    },
    {
      field: "QuotedPrice",
      editable: true,
      headerName: "견적가",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { QuotedPrice } = row;
        return <span>{parseInt(QuotedPrice).toLocaleString()}</span>;
      },
    },
    {
      field: "SalePrice",
      editable: true,
      headerName: "판매가",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { SalePrice } = row;
        return <span>{parseInt(SalePrice).toLocaleString()}</span>;
      },
    },
    {
      field: "OurSalePrice",
      editable: true,
      headerName: "자사 판매가",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { SalePrice } = row;
        return <span>{parseInt(SalePrice).toLocaleString()}</span>;
      },
    },

    // {
    //   field: "KoreaCost",
    //   editable: true,
    //   headerName: "한국원가",
    //   width: 80,
    //   align: "center",
    //   headerAlign: "center",
    //   type: "string",
    //   renderCell: ({ row }) => {
    //     const { KoreaCost } = row;
    //     return <span>{parseInt(KoreaCost).toLocaleString()}</span>;
    //   },
    // },

    // {
    //   field: "DeliveryPrice",
    //   editable: true,
    //   headerName: "납품가",
    //   width: 80,
    //   align: "center",
    //   headerAlign: "center",
    //   type: "string",
    //   renderCell: ({ row }) => {
    //     const { DeliveryPrice } = row;
    //     return <span>{parseInt(DeliveryPrice).toLocaleString()}</span>;
    //   },
    // },
    // {
    //   field: "VAT",
    //   editable: true,
    //   headerName: "부가세",
    //   width: 80,
    //   align: "center",
    //   headerAlign: "center",
    //   type: "string",
    //   renderCell: ({ row }) => {
    //     const { VAT } = row;
    //     return <span>{parseInt(VAT).toLocaleString()}</span>;
    //   },
    // },
    {
      field: "RocketMargin",
      headerName: "로켓마진",
      editable: true,
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { RocketMargin } = row;
        return <span>{parseInt(RocketMargin).toLocaleString()}</span>;
      },
    },
    // {
    //   field: "AccountingCost",
    //   headerName: "회계원가",
    //   editable: true,
    //   width: 80,
    //   align: "center",
    //   headerAlign: "center",
    //   type: "string",
    //   renderCell: ({ row }) => {
    //     const { AccountingCost } = row;
    //     return <span>{parseInt(AccountingCost).toLocaleString()}</span>;
    //   },
    // },

    {
      field: "OurMargin",
      headerName: "자사마진",
      editable: true,
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { OurMargin } = row;
        return <span>{parseInt(OurMargin).toLocaleString()}</span>;
      },
    },
  ];

  const handleSortChange = (e: any) => {
    setParams((prev) => ({
      ...prev,
      sortby: e[0]?.field || "",
      direction: e[0]?.sort || "",
    }));
  };

  // 선택된 항목 삭제
  const handleDeleteProduct = async () => {
    const Error = [];

    const dtoList = selected.map((id) => {
      if (rows?.results[id].TotalCount !== 0) {
        Error.push((rows?.results[id] as any).BarCode);
      }
      return (rows?.results[id] as any).BarCode;
    });

    if (Error.length === 0) {
      if (confirm(`선택한 ${dtoList.length}개의 상품을 삭제하시겠습니까?\n이 작업은 되돌릴 수 없습니다.`)) {
        for (const dto of dtoList) {
          await deleteProduct(dto);
        }
      }
      queryClient.invalidateQueries();
    } else {
      alert("재고가 있는 상품은 삭제할 수 없습니다.");
    }
  };

  //선택된 항목 엑셀로 다운로드
  const handleExcelDownLoad = () => {
    const date = getCurrentDateTime();
    const dto = selected.map((id) => rows?.results[id] as any);
    productDatatoExcelFormmat(dto, `${[date]}상품목록`);
  };

  //모달창 열기
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  //status 모달창 열기
  const [statusModalOpen, setStatusModalOpen] = React.useState(false);
  const handleChangeStatusModalOpen = () => {
    setStatusModalOpen(!statusModalOpen);
  };

  //상태 일괄 변경 함수
  const handleBulkUpdateProductStatus = (status: statusType) => {
    const dtoList = selected.map((id) => (rows?.results[id] as any).BarCode);
    dtoList.forEach((dto) => {
      updateProduct({
        BarCode: dto,
        Status: status,
      });
    });
  };

  const preProductStatusOptions = STEP_LIST.map((item, index) => ({ label: item.title, value: index.toString() }));

  return (
    <>
      <BackDropLoading open={deletePeding || preIsLoading} />
      <Grid container sx={{ maxWidth: viewMode === "table" ? "99%" : "1200px", m: "20px auto" }}>
        {/* 최상단 이름과 버튼 */}
        <Grid item xs={12}>
          <RowStack gap={2} sx={{ mb: 2 }}>
            <Typography variant="h5" fontWeight={"bold"} sx={{ mr: "auto" }}>
              상품목록
            </Typography>
            {viewMode === "table" && (
              <>
                <ExportToExcelButtonInProductTable params={params} />
                <UpdateProductButton />
              </>
            )}
            {/* <CreateProductButton /> */}
            <Button
              size="large"
              onClick={() => {
                navigate("create");
              }}
              variant="contained"
            >
              상품 생성
            </Button>
          </RowStack>
        </Grid>

        <Grid item xs={12}>
          {/* 툴바 */}
          <RowStack gap={2} sx={{ backgroundColor: "white", px: 4, height: "70px", border: "1px solid #dfdfdf" }}>
            <RowStack sx={{ backgroundColor: "#eef5fc", borderRadius: "5px", px: 1, alignItems: "center", mr: "auto" }}>
              <Tooltip title={<p>상품 기준</p>} arrow>
                <IconButton onClick={() => handleViewModeChange("card")}>
                  <ViewModuleIcon color={viewMode === "card" ? "primary" : "disabled"} fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip title={<p>바코드 기준</p>} arrow>
                <IconButton onClick={() => handleViewModeChange("table")}>
                  <ViewListIcon color={viewMode === "table" ? "primary" : "disabled"} fontSize="large" />
                </IconButton>
              </Tooltip>
            </RowStack>
            {selected.length !== 0 && DELEABLE_ROLD_ID.includes(RoleID) && (
              <Button color="primary" size="large" onClick={handleOpenMenu} startIcon={<ChecklistIcon />}>
                선택한 상품 [{selected.length}]
              </Button>
            )}

            <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleCloseMenu}>
              <MenuList>
                <MenuItem onClick={handleExcelDownLoad}>
                  <ListItemIcon>
                    <SystemUpdateAltIcon />
                  </ListItemIcon>
                  엑셀 다운로드
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleChangeStatusModalOpen}>
                  <ListItemIcon>
                    <FlipCameraAndroidOutlinedIcon />
                  </ListItemIcon>
                  일괄 상태변경
                </MenuItem>
                <Divider />
                {DELEABLE_ROLD_ID.includes(RoleID) && (
                  <MenuItem onClick={handleDeleteProduct} sx={{ color: "red" }}>
                    <ListItemIcon>
                      <DeleteOutlineIcon color="error" />
                    </ListItemIcon>
                    일괄 삭제
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
            {viewMode === "table" && (
              <FormControlLabel
                label="전체보기"
                control={<Switch checked={showColumnToggle} onChange={(e) => setShowColumnToggle(e.target.checked)} />}
              />
            )}
            {viewMode === "table" && (
              <SelectForm
                handleChange={handleChangeStatus}
                id="status"
                value={status}
                options={[{ label: "전체", value: "" }, ...statusOptions]}
                sx={{ width: "100px" }}
                label="상태"
                size="small"
              />
            )}
            {viewMode === "card" && (
              <SelectForm
                handleChange={handleChangePreProductStatus}
                id="preProductStatus"
                value={preProductStatus}
                options={[{ label: "전체", value: "" }, ...preProductStatusOptions]}
                sx={{ width: "160px" }}
                label="상태"
                size="small"
              />
            )}
            <SelectForm
              handleChange={handleChangeBrand}
              id="brand"
              value={brand}
              options={[{ label: "전체", value: "" }, ...brandOptions]}
              sx={{ width: "150px" }}
              label="브랜드"
              size="small"
            />
            <SearchInputField value={searchText} id="searchText" onChange={handleChangeSearchText} />

            <RefreshIconButton
              onClick={() => {
                if (viewMode === "table") {
                  refetch();
                } else {
                  preRefetch();
                }
              }}
            />
          </RowStack>
        </Grid>
        {viewMode === "table" && (
          <Grid item xs={12}>
            <Stack sx={{ height: "85vh" }}>
              {/*테이블 */}

              <DataGrid
                checkboxSelection={DELEABLE_ROLD_ID.includes(RoleID)}
                getRowHeight={() => "auto"}
                onSortModelChange={handleSortChange}
                sx={{
                  ...dataGridStyle,
                  [`& .MuiDataGrid-cell`]: {
                    paddingTop: 1,
                    paddingBottom: 1,
                    lineHeight: "unset !important",
                    maxHeight: "none !important",
                    whiteSpace: "normal",
                  },
                }}
                onRowSelectionModelChange={(selectIdList) => {
                  setSelected(selectIdList as number[]);
                }}
                disableRowSelectionOnClick
                loading={isLoading || isFetching}
                rows={rows?.results ? rows.results : []}
                disableColumnSelector
                onColumnWidthChange={(e) => {
                  const { field, width } = e.colDef;
                  setColumns(field, width || 100);
                }}
                columns={columns.map((item) => {
                  const columnWidth = getColumns()[item.field];
                  return { ...item, cellClassName: "cell-divider common-cell", width: columnWidth ? columnWidth : item.width };
                })}
                rowModesModel={rowModesModel}
                disableColumnFilter
                disableDensitySelector
                columnVisibilityModel={showColumnToggle ? SHOW_COLUMN : HIDE_FILETER}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onCellEditStop={(params, event) => {
                  if (params.reason !== GridCellEditStopReasons.enterKeyDown) {
                    return;
                  }
                  if (isKeyboardEvent(event) && !event.ctrlKey && !event.metaKey) {
                    event.defaultMuiPrevented = true;
                  }
                }}
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: MuiDataGridNoRow,
                  pagination: CustomPagination as any,
                }}
                slotProps={{
                  pagination: {
                    count: rows?.count || 0,
                    page: params?.page || 1,
                    pageSize: params?.page_size || 100,
                    onChange: handleChangeParams,
                  } as any,
                  columnsManagement: {
                    getTogglableColumns,
                  },
                }}
              />
              <BulkStatusUpdateModal open={statusModalOpen} onClose={handleChangeStatusModalOpen} onSubmit={handleBulkUpdateProductStatus} />
            </Stack>
          </Grid>
        )}
        {/* 카드 형태 */}
        {viewMode === "card" && (
          <Grid item xs={12} sx={{ pt: 2 }}>
            <Grid container spacing={3} sx={{ minWidth: "1200px" }}>
              {preRow &&
                preRow.results.map((row: getPreProductDto, i: number) => (
                  <Grid item xs={3} key={i}>
                    <Card sx={{ p: 0 }}>
                      <Stack direction="column" alignItems="center" justifyContent="center">
                        <RowStack
                          justifyContent="center"
                          sx={{ position: "relative", backgroundColor: "black", width: "100%", height: "260px", overflow: "hidden" }}
                        >
                          {row.IsInUse && (
                            <Chip
                              label={"수정중"}
                              color="success"
                              sx={{ position: "absolute", color: "white", top: 10, right: 10, boxShadow: "0px 0px 10px 1px white" }}
                            />
                          )}

                          <img src={`${row?.ImageLink?.length > 0 ? imageUrl + "/" + row.ImageLink : noImage}`} alt="alert" width={300} />
                        </RowStack>
                        <Stack sx={{ p: 3, boxSizing: "border-box", width: "100%" }} gap={3}>
                          <Stack gap={2}>
                            {/* 브랜드 이미지 + 브랜드 명 */}
                            <RowStack justifyContent="start" gap={1} sx={{ width: "100%" }}>
                              <img src={brandCodeToImage[row.Brand]?.imagePath || ""} alt={row.Brand} style={{ width: "30px", height: "30px" }} />
                              <Typography variant="caption" color="#666">
                                {brandCodeToImage[row.Brand]?.brandName || ""} {row.ProductCode}
                              </Typography>
                            </RowStack>
                            {/* 상품명 */}
                            <Typography variant="subtitle2" fontFamily={"malgunbd"} sx={{ ...maxLineCss, minHeight: "40px" }}>
                              {row.ProductName_ko}
                            </Typography>
                            {/* 상품의 상태 */}
                            <RowStack>
                              {/* {row.PreProductStatus && <Chip label={STEP_LIST[row.PreProductStatus]} color="primary" size="small" />} */}
                              {row.PreProductStatus && (
                                <StatusBadge
                                  color={STEP_LIST[row.PreProductStatus].color}
                                  backgroundColor={STEP_LIST[row.PreProductStatus].backgroundColor}
                                >
                                  {STEP_LIST[row.PreProductStatus].title}{" "}
                                </StatusBadge>
                              )}
                            </RowStack>
                            {/* 상품메모 */}

                            <Typography variant="caption" color="#999" sx={{ ...maxLineCss, height: "40px" }}>
                              {row.PreProductMemo || ""}
                            </Typography>
                          </Stack>

                          <RowStack gap={3} sx={{ width: "100%", mt: 2 }}>
                            <Button
                              variant="outlined"
                              fullWidth
                              startIcon={<OpenInNewIcon />}
                              onClick={() => {
                                window.open(row.Productlink, "_blank");
                              }}
                            >
                              주문링크
                            </Button>
                            <Button variant="contained" fullWidth startIcon={<EditIcon />} onClick={() => [navigate(`/products/update/${row.Id}`)]}>
                              수정하기
                            </Button>
                          </RowStack>
                        </Stack>
                      </Stack>
                    </Card>
                  </Grid>
                ))}
              <Grid item xs={12}>
                <CustomPagination
                  count={preRow?.count || 0}
                  page={params?.page || 1}
                  pageSize={params?.page_size || 20}
                  onChange={handleChangeParams}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}

const MuiDataGridNoRow = () => {
  return <Stack sx={{ height: "1000px", width: "100%", justifyContent: "center", alignItems: "center" }}>검색 결과가 없습니다. </Stack>;
};
