import React from "react";
import * as XLSX from "xlsx";
import { Button } from "@mui/material";
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';

interface ExportExcelButtonProps {
  data: any[];
  fileName: string;
}

const ExportToExcelButton: React.FC<ExportExcelButtonProps> = ({ data, fileName }) => {
  const exportToExcel = () => {
    if (!data.length) return;
    const formattedData = data.map((item, index) => ({
      연번: index + 1,
      발주번호: item.IndividualOrderNumber,
      상품번호: item.SKU_ID,
      상품바코드: item.BarCode,
      상품명: item.ProductName_ko,
      쿠팡상품명:item.SKU_Name,
      사이즈: item.Size,
      컬러: item.Color,
      발주상태: item.Status,
      발주담당자메모: item.Memo1,
      청도주문메모: item.Memo2,
      기타메모: item.Memo3,
      재고현황: item.TotalCount,
      입고현황: item.IncomingCount,
      발주수량: item.Quantity,
      확정수량: item.Quantity_OK,
      박스번호: item.BoxNumberList?.map((box: any) => `${box.ToObjectCode}(${box.Quantity})`).join(", ") || "",
      준비수량: item.Quantity_Ready,
      오류수량: item.Quantity_OK - item.Quantity_Ready,
      IBC하차번호: item.IBCNumber,
      //연번 순서대로 다운로드 됨
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

    const safeFileName = `${fileName}.xlsx`;
    XLSX.writeFile(workbook, safeFileName);
  };

  return (
    <Button variant="contained" onClick={exportToExcel} startIcon={<SystemUpdateAltOutlinedIcon />}>
      발주서 다운
    </Button>
  );
};

export default ExportToExcelButton;


