import { Stack } from "@mui/material";
import UpdateSingleProduct from "../entities/link/ui/UpdateSingleProduct";

const UpdateProductForm = () => {
  return (
    <>
      <Stack sx={{ width: "1000px", mx: "auto", my: 10 }} gap={5}>
        <UpdateSingleProduct />
      </Stack>
    </>
  );
};

export default UpdateProductForm;
