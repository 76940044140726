export function getDatesAndWeekdays() {
  const today = new Date();
  const days = [];

  for (let i = 0; i <= 6; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() + i);
    const day = date.toLocaleString("ko-KR", { weekday: "long" }); // 요일 한국어
    const formattedDate = date.toISOString().split("T")[0]; // YYYY-MM-DD 형식
    days.push({ date: formattedDate, day });
  }

  return days;
}
