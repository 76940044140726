export const initialFilter = {
  IncomingCount: 0,
  BarCode: "", // pk 상품바코드
  Memo0: "",
  Memo00: "",
  Size: "",
  Color: "",
  Brand: "", //
  Center: "", //물류센터
  ReceiveDate: "",
  TotalCount: 0,
  Memo1: "",
  Memo2: "",
  Memo3: "",
  OrderLink: "", //
  ProductName_ko: "",
  OrderDate: "", //입고일자
  SKU_ID: "",
  SupplierCompany: "",
  individualOrderNumber: "",
  MemoCustomerOrder: "",
  Quantity: 0, // 발주수량
  Quantity_OK: 0, // 확정수량
  Quantity_Ready: 0, // 준비수량
  OrderItemID: 0, //pk
  OrderID: 0, //발주번호
  SKU_Name: "",
};
