import { Box, Button, Card, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import BackDropLoading from "../../../shared/ui/loading/BackDropLoading";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { useDeleteBarcodeAlert, useGetBarcodeAlertList } from "../../../service/barcode-alert/barcodeAlertHooks";
import { BarcodeAlertDto } from "../../../service/barcode-alert/types";
import { MemoizedAlertItem } from "./AlertItem";
import CreateAlertButton from "./create-barcode-alert-modal/CreateBarcodeAlertButton";
import RefreshIconButton from "../../../shared/ui/buttom/RefreshIconButton";
import RowStack from "../../../shared/ui/RowStack";
import SearchInputField from "../../../widget/form/ui/SearchInpuField";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export const SUBTITLE_COLOT = "#999";
export const MAIN_CONTENT_COLOR = "#333";
const INTERVAL = 20;
const GiftSetTable = () => {
  const [search, setSearch] = useState<string>("");
  const { data, refetch, isFetching } = useGetBarcodeAlertList({ barcode: search, type: "giftset" });
  const { mutate: deleteBarcodeAlert } = useDeleteBarcodeAlert();
  const [state, setState] = useState<string>("전체");
  const [selected, setSelected] = useState<number[]>([]);
  const observerRef = useRef(null);
  const [viewList, setViewList] = useState<BarcodeAlertDto[]>(data ? [...data.slice(0, INTERVAL)] : []);
  const [viewIndex, setViewIndex] = useState<number>(0);

  //view index가 변경될때마다 확인하기
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        //콜백함수
        if (data && viewList.length < data.length && entries[0].isIntersecting) {
          setViewList([...viewList, ...data.slice(viewIndex, viewIndex + INTERVAL)]);
          setViewIndex(viewIndex + INTERVAL);
        }
      },
      { threshold: 0.8 }
    );

    if (observerRef.current) observer.observe(observerRef.current); //관찰시작

    // 컴포넌트가 언마운트될 때 옵저버 해제
    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current); //관찰해제
    };
  }, [viewList, viewIndex]);

  useEffect(() => {
    setViewList(data ? [...data.slice(0, INTERVAL)] : []); //스프레드로 초기화 해야 viewList가 변경될것 같았음
    setViewIndex(INTERVAL); //인덱스 초기화
  }, [data]);

  const handleChangeSelected = useCallback((id: number) => {
    setSelected((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  }, []);

  const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((event.target as HTMLInputElement).value);
  };

  const handleChangeSearch = (e: any) => {
    setSearch(e.target.value.trim());
  };

  const filteredData = useMemo(() => {
    if (state === "전체") {
      return viewList;
    } else {
      return viewList?.filter((item) => {
        return item.is_active === (state === "활성");
      });
    }
  }, [viewList, state, data]);

  /**
   * 1. 전체 등록수
   * 2. 활성화 알림
   * 3. 비활성화 알림
   */
  const { totalCount, activeCount, inactiveCount } = useMemo(() => {
    let totalCount = 0;
    let activeCount = 0;
    let inactiveCount = 0;

    data?.forEach((item: BarcodeAlertDto) => {
      totalCount++;
      if (item.is_active) {
        activeCount++;
      } else {
        inactiveCount++;
      }
    });

    return { totalCount, activeCount, inactiveCount };
  }, [data]);

  const handleDeleteAlert = () => {
    deleteBarcodeAlert(selected);
    setSelected([]);
  };

  return (
    <>
      <BackDropLoading open={isFetching} />
      <Stack sx={{ margin: "0 auto", my: 10, width: "1200px" }} gap={4}>
        <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
          선물세트
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Box sx={{ width: "120px", ml: 5 }}>
                  <Typography variant="caption" color={SUBTITLE_COLOT}>
                    전체 등록수
                  </Typography>
                  <Typography color={MAIN_CONTENT_COLOR} variant="h5">
                    {totalCount} 건
                  </Typography>
                </Box>
                <Box sx={{ width: "120px" }}>
                  <Typography variant="caption" color={SUBTITLE_COLOT}>
                    활성
                  </Typography>
                  <Typography color={MAIN_CONTENT_COLOR} variant="h5">
                    {activeCount} 건
                  </Typography>
                </Box>
                <Box sx={{ width: "120px", marginRight: "auto" }}>
                  <Typography variant="caption" color={SUBTITLE_COLOT}>
                    비활성
                  </Typography>
                  <Typography color={MAIN_CONTENT_COLOR} variant="h5">
                    {inactiveCount} 건
                  </Typography>
                </Box>

                {/* 검색창 */}
                <SearchInputField onChange={handleChangeSearch} value={search} />
                <RefreshIconButton onClick={() => refetch()} />
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <RowStack gap={5}>
              <FormControl sx={{ ml: 3, mr: "auto" }}>
                <RadioGroup sx={{ display: "flex", flexDirection: "row", gap: 5 }} value={state} onChange={handleChangeState}>
                  <FormControlLabel value="전체" control={<Radio />} label="전체" />
                  <FormControlLabel value="활성" control={<Radio />} label="활성" />
                  <FormControlLabel value="비활성" control={<Radio />} label="비활성" />
                </RadioGroup>
              </FormControl>

              <Button color="error" onClick={handleDeleteAlert} startIcon={<DeleteOutlineIcon />} size="large" disabled={selected.length === 0}>
                일괄 삭제 [{selected.length}]
              </Button>

              <CreateAlertButton />
            </RowStack>
          </Grid>

          {filteredData?.map((item) => (
            <MemoizedAlertItem onSelect={handleChangeSelected} selected={selected.includes(item.id)} key={item.id} data={item} />
          ))}
          {data && viewList.length !== data.length && (
            <Typography variant="subtitle1" ref={observerRef} sx={{ textAlign: "cetner" }}>
              더보기
            </Typography>
          )}
        </Grid>
      </Stack>
    </>
  );
};

export default GiftSetTable;
