import { useGetOrderList } from "../../../service/order/orderHooks";
import _ from "lodash";
import "../style/OrderListTable.css";
import { Grid, Tab, Tabs, Typography } from "@mui/material";
import { useBrandCodeStore } from "../../../store/brandStore";

import { CreateOrderButton } from "./create-order-modal/CreateOrderButton";
import { useOrderStatusStore } from "../../../store/orderStatus";
import BackDropLoading from "../../../shared/ui/loading/BackDropLoading";
import RowStack from "../../../shared/ui/RowStack";
import { brandIconOptions } from "../../side-navigation/const/brandIconsOption";
import DailyQuantityChart from "./DailyQuantityChart";
import PriorityOrderList from "./PriorityOrderList";
import OrderSummaryCard from "./OrderSummaryCard";
import OrderListTable2 from "./OrderListTable2";

/// 발주서 목록 테이블

export const ORDER_LIST_TABLE_COLUMNS_VALUES = [570, 120, 120, 120, 120, 120, 120, 120, 120, 120];

export default function NewOrderListTable() {
  const { BrandCode, setBrandCode } = useBrandCodeStore((s) => s);
  const { status } = useOrderStatusStore((v) => v);
  const { data: orderList, refetch, isFetching } = useGetOrderList({ brand: BrandCode, status: status === "전체" ? "" : status });
  const handleBrandChange = (e: any, newValue: any) => {
    setBrandCode(newValue);
  };

  return (
    <>
      <BackDropLoading open={isFetching} />
      <Grid container spacing={3} sx={{ width: "1200px", m: "20px auto" }}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={"bold"}>
            발주서 목록
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Tabs value={BrandCode} onChange={handleBrandChange} variant="fullWidth" centered sx={{ mt: 2 }}>
            {brandIconOptions
              .filter((v) => v.brandCode !== "CA")
              .map((brand) => (
                <Tab key={brand.brandCode} value={brand.brandCode} label={brand.brandName} sx={{ fontSize: "14px" }} />
              ))}
          </Tabs>
        </Grid>
        <Grid item xs={5}>
          <DailyQuantityChart data={orderList} />
        </Grid>
        <Grid item xs={3}>
          <PriorityOrderList data={orderList} />
        </Grid>
        <Grid item xs={4}>
          <OrderSummaryCard data={orderList} />
        </Grid>
        <Grid item xs={12}>
          <RowStack sx={{ mt: 2 }}>
            <div />
            <CreateOrderButton />
          </RowStack>
        </Grid>
        <Grid item xs={12}>
          <OrderListTable2
            data={orderList}
            refetch={() => {
              refetch();
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
