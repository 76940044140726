import { Stack } from "@mui/material";
import CreateSingleProduct from "../entities/link/ui/CreateSingleProduct";

const CreateProductForm = () => {
  return (
    <>
      <Stack sx={{ width: "1000px", mx: "auto", my: 10 }} gap={5}>
        <CreateSingleProduct />
      </Stack>
    </>
  );
};

export default CreateProductForm;
