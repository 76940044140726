import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  OutlinedInput,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useEffect, useState } from "react";
import { useGetOrderItemsByBarCode, useUpdateOrderItem } from "../../service/order/orderHooks";
import RowStack from "../../shared/ui/RowStack";
import RefreshIconButton from "../../shared/ui/buttom/RefreshIconButton";
import { getOrderItemsByBarCodeResponseDto } from "../../service/order/types";
import _ from "lodash";

interface ShowOrderItemsByBarCodeModalProps {
  open: boolean;
  handleClose: () => void;
  BarCode: string;
}

export const ShowOrderItemsByBarCodeModal: React.FC<ShowOrderItemsByBarCodeModalProps> = ({ open, handleClose, BarCode }) => {
  const { data, refetch } = useGetOrderItemsByBarCode({ BarCode });
  const { mutateAsync: updateOrderItem } = useUpdateOrderItem();
  const [rows, setRows] = useState<getOrderItemsByBarCodeResponseDto[]>([]);
  const handleMoveToIndividualOrder = (OrderID: number, BarCode: string) => {
    const url = `/orders/${OrderID}?keyword=${BarCode}`;
    window.open(url, "_blank"); // '_blank'는 새 창을 열도록 지정합니다.
  };

  useEffect(() => {
    refetch();
    if (data) {
      setRows(_.cloneDeep(data));
    }
  }, [open, data]);

  const handleUpdateOrderItem = async (index: number) => {
    const { OrderItemID, Quantity_OK } = rows[index];
    await updateOrderItem({
      OrderItemID,
      Quantity_OK,
    });
    refetch();
  };

  const handleChangeRows = (e: any, index: number) => {
    const value = parseInt(e.target.value);
    const temp = [...rows];
    temp[index].Quantity_OK = value;
    temp[index].Quantity_Error = value - temp[index].Quantity_Ready;
    setRows(temp);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1100px",
            height: "620px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <RowStack>
          <Typography variant="subtitle1" color="#333">
            발주 정보
          </Typography>
          <RefreshIconButton
            onClick={() => {
              refetch();
            }}
          />
        </RowStack>
      </DialogTitle>

      <DialogContent sx={{ width: "1050px" }}>
        <TableContainer sx={{ height: 430, boxSizing: "border-box", border: "0.5px solid #DFDFDF" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }} align={"left"} style={{ minWidth: 80 }}>
                  발주번호
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align={"left"} style={{ minWidth: 80 }}>
                  입고일
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ minWidth: 41 }}>
                  발주수량
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ minWidth: 60 }}>
                  확정수량
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ minWidth: 41 }}>
                  준비수량
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ minWidth: 41 }}>
                  오류수량
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ width: "20%" }}>
                  청도 주문메모
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ width: "20%" }}>
                  기타메모
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align={"center"} style={{ width: "20%" }}>
                  수정
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                rows.map((row, i) => {
                  return (
                    <TableRow>
                      <TableCell
                        align={"center"}
                        onClick={() => {
                          handleMoveToIndividualOrder(row["OrderID"], row["BarCode"]);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <Stack direction="row" alignItems={"center"} gap={1} sx={{ color: "#6799ff", "&:hover": { color: "blue" } }}>
                          {row["IndividualOrderNumber"]}
                          <OpenInNewIcon />
                        </Stack>
                      </TableCell>
                      <TableCell align={"center"}>{row.ReceiveDate}</TableCell>
                      <TableCell align={"center"} className="orange-header" sx={{ width: "40px" }}>
                        {row.Quantity}
                      </TableCell>
                      <TableCell align={"center"} className="orange-header" sx={{ width: "60px" }}>
                        <OutlinedInput
                          value={row.Quantity_OK}
                          type="number"
                          onChange={(e) => handleChangeRows(e, i)}
                          sx={{ backgroundColor: "white" }}
                        />
                      </TableCell>
                      <TableCell align={"center"} className="yellow-header" sx={{ width: "40px" }}>
                        {row.Quantity_Ready}
                      </TableCell>
                      <TableCell align={"center"} className="blue-header11" sx={{ width: "40px" }}>
                        {row.Quantity_Error}
                      </TableCell>
                      <TableCell align={"center"}>{row.Memo2}</TableCell>
                      <TableCell align={"center"}>{row.Memo3}</TableCell>
                      <TableCell align={"center"}>
                        <Button variant="contained" disabled={data[i].Quantity_OK == rows[i].Quantity_OK} onClick={() => handleUpdateOrderItem(i)}>
                          수정
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <Button onClick={handleClose} variant="outlined" size="large">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};
