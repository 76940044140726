import { IconButton, Step, StepLabel, Stepper } from "@mui/material";
import LinkCard from "../LinkCard";
import LinkSubTitle from "../LinkSubTitle";
import { getPreProductDto } from "../../../../service/product/types";
import RowStack from "../../../../shared/ui/RowStack";

export const STEP_LIST = [
  {
    title: "상품 등록중",
    description: "상품 등록중입니다.",
    color: "",
    backgroundColor: "",
  },
  {
    title: "검토요청",
    description: "검토요청중입니다.",
    color: "#F141E5",
    backgroundColor: "#FFEEFD",
  },
  {
    title: "최저가 검색",
    description: "최저가 검색중입니다.",
    color: "#388e3c",
    backgroundColor: "#e8f5e9",
  },
  {
    title: "확인완료",
    description: "대표님 확인건 입니다.",
    color: "#F141E5",
    backgroundColor: "#FFEEFD",
  },
  {
    title: "상세페이지 제작",
    description: "상세페이지 제작중입니다.",
    color: "#6799ff",
    backgroundColor: "#eef5fc",
  },
  {
    title: "상표권 추가",
    description: "견적대기중입니다.",
    color: "red",
    backgroundColor: "#FFE3E3",
  },
  {
    title: "견적대기",
    description: "견적대기중입니다.",
    color: "#f57c00",
    backgroundColor: "#fff3e0",
  },
  {
    title: "견적승인대기",
    description: "견적승인대기중입니다.",
    color: "",
    backgroundColor: "",
  },
];

interface ProductProcessStepProps {
  data: getPreProductDto;
  onChange: React.Dispatch<React.SetStateAction<getPreProductDto>>;
}

const ProductProcessStep: React.FC<ProductProcessStepProps> = ({ data, onChange }) => {
  const handleChangeStep = (value: number) => {
    onChange((prev: getPreProductDto) => ({ ...prev, PreProductStatus: value }));
  };

  const handleNextStep = () => {
    onChange((prev: getPreProductDto) => ({ ...prev, PreProductStatus: Math.min(5, (data.PreProductStatus || 0) + 1) }));
  };

  const handlePrevStep = () => {
    onChange((prev: getPreProductDto) => ({ ...prev, PreProductStatus: Math.max(0, (data.PreProductStatus || 0) - 1) }));
  };

  return (
    <LinkCard>
      <LinkSubTitle title="진행상황" />
      <RowStack>
        <IconButton onClick={handlePrevStep}>
          <ArrowBackIosIcon />
        </IconButton>
        <Stepper activeStep={data.PreProductStatus} alternativeLabel sx={{ width: "100%" }}>
          {STEP_LIST.map((item, i) => (
            <Step key={i} sx={{ cursor: "pointer" }}>
              <StepLabel onClick={() => handleChangeStep(i)} sx={{ cursor: "pointer" }}>
                {item.title}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <IconButton onClick={handleNextStep}>
          <ArrowForwardIosIcon />
        </IconButton>
      </RowStack>
    </LinkCard>
  );
};

export default ProductProcessStep;
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { title } from "process";
import { color } from "html2canvas/dist/types/css/types/color";
