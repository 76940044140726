import { Stack, Typography, useTheme } from "@mui/material";

interface Props {
  title: string;
  required?: boolean;
}

export default function LinkSubTitle({ title, required = false }: Props) {
  const theme = useTheme();
  
  return (
    <Stack flexDirection={"row"} gap={1} borderLeft={"4px solid"} borderColor={"#6799ff"} pl={1} mb={1}>
      <Typography>{title}</Typography>
      {required && <Typography color={theme.palette.primary.main}>*</Typography>}
    </Stack>
  );
}
