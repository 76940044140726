import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { customerFindOrderApi } from "./customerFindOrderAxios";
import { QUERY_KEY } from "../../config/querykey";
import {
  createCustomerOrderRequestDto,
  deleteCustomerOrderDto,
  getCustomerOrderListRequestDto,
  getCustomerOrderListResponseDto,
  updateCustomerOrderRequestDto,
} from "./types";
import { customToast } from "../../shared/lib/customToast";
import { useCustomerBrandStore } from "../../store/customerBrandStore";
import { tableSortFunc } from "../../shared/lib/tableSortFunction";

function addOneDay(dateStr: string): string {
  // YYYYMMDD 형식을 YYYY-MM-DD로 변환
  const formattedDateStr = `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(6, 8)}`;
  // 날짜 문자열을 Date 객체로 변환
  const date = new Date(formattedDateStr);
  // 하루를 더함
  date.setDate(date.getDate() + 1);
  // 연도, 월, 일 포맷으로 변환하여 반환
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}${month}${day}`;
}

export const useGetCustomerFindOrderList = (data: getCustomerOrderListRequestDto, isEdit: boolean) => {
  const { sortType } = useCustomerBrandStore((v) => v);
  return useQuery<getCustomerOrderListResponseDto[], Error>({
    queryKey: [QUERY_KEY.customerFindOrder, data.BrandStr, data.StartDate, data.EndDate],
    queryFn: async () => customerFindOrderApi.getCustomerOrderList({ ...data, EndDate: addOneDay(data.EndDate) }),
    select: (data) => data.sort((a, b) => tableSortFunc(a, b, sortType)),
    refetchInterval: isEdit ? false : 10 * 1000,
  });
};

export const useCreateCustomerFindOrder = (func: (data: any) => void) => {
  const queryClinet = useQueryClient();
  return useMutation<any, Error, createCustomerOrderRequestDto[]>({
    mutationFn: async (body: createCustomerOrderRequestDto[]) => customerFindOrderApi.createCustomerOrder(body),
    onSuccess: (data) => {
      func(data);
      queryClinet.invalidateQueries({ queryKey: [QUERY_KEY.customerFindOrder] });
      customToast("success", "주문이 성공적으로 등록되었습니다.");
    },
    onError: (error: any) => {
      queryClinet.invalidateQueries({ queryKey: [QUERY_KEY.customerFindOrder] });
      alert(Object.keys(error.response.data).reduce((acc, key) => acc + error.response.data[key] + "\n", ""));
    },
  });
};

export const useUpdateCustomerFindOrder = (success: (data: any) => void) => {
  const queryClient = useQueryClient();
  return useMutation<updateCustomerOrderRequestDto[], Error, updateCustomerOrderRequestDto[]>({
    mutationFn: async (body: updateCustomerOrderRequestDto[]) => customerFindOrderApi.updateCustomerOrder(body),
    onSuccess: (data) => {
      success(data);
    },
    onError: (error) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.customerFindOrder] });
      customToast("error", "주문수정에 실패했습니다. 잠시후 다시 시도해주세요.");
    },
  });
};

export const useDeleteCustomerFindOrder = (querydto: { brandStr: string; StartDate: string; EndDate: string }) => {
  const queryClient = useQueryClient();
  return useMutation<deleteCustomerOrderDto[], Error, deleteCustomerOrderDto[]>({
    mutationFn: async (body: deleteCustomerOrderDto[]) => customerFindOrderApi.deleteCustomerOrderDelete(body),
    onSuccess: (data) => {
      queryClient.setQueryData(
        [QUERY_KEY.customerFindOrder, querydto.brandStr, querydto.StartDate, querydto.EndDate],
        (oldData: getCustomerOrderListResponseDto[] | undefined) => {
          //true 만 리턴됨
          return oldData
            ? oldData.filter((v1: getCustomerOrderListResponseDto) => {
                return data
                  ? !data.some((v2: deleteCustomerOrderDto) => v1.OrderNumber_idx === v2.OrderNumber_idx && v1.CustomerOrderID === v2.CustomerOrderID)
                  : true;
              })
            : [];
        }
      );
      customToast("success", `${data.length}개의 주문정보가 삭제되었습니다.`);
    },
    onError: (error) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.customerFindOrder] });
      customToast("error", "주문삭제에 실패했습니다. 잠시후 다시 시도해주세요.");
    },
  });
};


export const useGetCustomerFindList = () => {
  return useQuery<getCustomerOrderListResponseDto[], Error>({
    queryKey: [QUERY_KEY.customerFindOrder,"findList"],
    queryFn: () => customerFindOrderApi.getCustomerFindList(),
  });
}