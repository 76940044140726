/**
 *
 * 숫자가 주어지면 거기까지가는 카테고리 경로를 찾아주는 함수
 *
 * @param target
 * @param data
 * @param path
 * @returns
 */
export function findPath(target: any, data: any, path: any[] = []): any {
  for (const key in data) {
    if (typeof data[key] === "object" && data[key] !== null) {
      // 재귀 호출로 탐색
      const result = findPath(target, data[key], [...path, key]);
      if (result) {
        return result; // 경로를 찾았으면 반환
      }
    } else {
      if (data[key] === target) {
        return [...path, key]; // 목표값을 찾았을 때 경로 반환
      }
    }
  }
  return null; // 목표값을 찾지 못한 경우 null 반환
}
