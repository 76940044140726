import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  Checkbox,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import DataGrid, { textEditor } from "react-data-grid";
import { useRef, useState, useMemo, useEffect, useCallback } from "react";
import BackDropLoading from "../../../shared/ui/loading/BackDropLoading";
import { CenterCellRander } from "../../total-order/style/style";
import AutoMultiCell from "../../../shared/ui/grid-cell/AutoMultiCell";
import {
  useDeleteCustomerFindOrder,
  useGetCustomerFindOrderList,
  useUpdateCustomerFindOrder,
} from "../../../service/customer-find-order/customerFindOrderHooks";
import { MultiRowCell } from "../../../shared/lib/multiRowCell";
import { customToast } from "../../../shared/lib/customToast";
import ShowOderPeopleButton from "./order-people-info-modal/ShowOderPeopleButton";
import { getCurrentFormattedTime } from "../../../shared/lib/getCurrentFormattedTime";
import { deleteCustomerOrderDto, getCustomerOrderListResponseDto } from "../../../service/customer-find-order/types";
import ShowInventoryButton from "../../../widget/show-inventory-modal/ui/ShowInventoryButton";
import DownloadIcon from "@mui/icons-material/Download";
import { UploadCustomerOrderFileButton } from "./upload-customer-order-file-modal/UploadCustomerOrderFileButton";
import { UploadInvoiceFileButton } from "./upload-invoice-file-modal/UploadInvoiceFileButton";
import { autoRowHeight } from "../../../shared/lib/autoRowHeight";
import { CustomTableContainer } from "../../../widget/custom-table-container/ui/CustomTableContainer";
import { invoiceExcelDownloader } from "../lib/invoiceExcelDownLoader";
import { dopalExcelDownLoader } from "../../../shared/lib/dopalExcelDownLoader";
import { useLocalStorage } from "../../../service/localStorage/useLocalStorage";
import _ from "lodash";
import { handleCopy, handlePaste } from "../../../shared/lib/dataGridTableCopyPaste";
import StatusChangeButton from "./status-change-modal/StatusChangeButton";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import SelectForm from "../../../widget/form/ui/SelectForm";
import { useCustomerBrandStore } from "../../../store/customerBrandStore";
import { customerFindBrandOptions } from "../const/customerBrandOptions";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import "../style/style.css";
import { customerOrderStatus, customerOrderStatus2 } from "../const/customerOrderStatus";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { tableSortFunc } from "../../../shared/lib/tableSortFunction";
import { Alertbox } from "./AlertBox";
import DateRangeButton from "./DateRageButton";
import { MarginSummaryButton } from "./margin-summary-modal/MarginSummaryButton";
import { useUserInfoState } from "../../../store/UserInfoStore";
import SearchInputField from "../../../widget/form/ui/SearchInpuField";
import NumberCell from "../../../shared/ui/grid-cell/NumberCell";

interface SortType {
  columnKey: string;
  direction: "ASC" | "DESC";
}

//크기조절

const CUSTOMER_FIND_ORDER_TABLE_COLUMNS = [
  50, 80, 80, 80, 70, 50, 100, 120, 50, 40, 80, 40, 40, 40, 40, 100, 40, 40, 200, 200, 70, 100, 180, 100, 100, 110, 50, 50,
];

const CustomerFindOrderTable = () => {
  const {
    brandStr,
    endDate: EndDate,
    setBrandStr,
    setSortType,
    sortType,
    startDate: StartDate,
    statusMenoFilter,
    setStatusMenoFilter,
  } = useCustomerBrandStore((v) => v);

  const { UserName } = useUserInfoState((v) => v);
  const [isEdit, setIsEdit] = useState(false);
  const [smenuAnchorEl, setSMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [widthList, setWidthList] = useLocalStorage("CUSTOMER_FIND_ORDER_TABLE_COLUMNS", CUSTOMER_FIND_ORDER_TABLE_COLUMNS);
  const selectedRowId = useRef<number>();
  const { data, refetch, isLoading } = useGetCustomerFindOrderList(
    {
      BrandStr: brandStr,
      StartDate,
      EndDate,
    },
    isEdit // 현재 수정중인지 아닌지  표시
  );

  const [filters, setFilters] = useState<string>("");
  const { mutate: deleteCustomerFindeOrder } = useDeleteCustomerFindOrder({ brandStr, StartDate, EndDate });
  const { mutate: updateCustomerFindOrder } = useUpdateCustomerFindOrder((data: any) => {
    customToast("info", "수정되었습니다.");
  });
  const [selected, setSelected] = useState<number[]>([]);

  //데이터를 rows에 담아둠
  const [rows, setRows] = useState<getCustomerOrderListResponseDto[]>(data || []);

  useEffect(() => {
    //예전 데이터 기준으로 reducer 돌기
    if (data) {
      //n번
      const keyValueMap = new Map<number, getCustomerOrderListResponseDto>();
      const usedKeySet = new Set<number>();
      data.forEach((item) => {
        keyValueMap.set(item.CustomerOrderID, item); // 조회를 빠르게 하기 위해 map에 넣어둠
      });

      // n번
      //값 업데이트 + 삭제하기
      const newRows: getCustomerOrderListResponseDto[] = rows.reduce((acc: any[], item) => {
        const id = item.CustomerOrderID;
        if (keyValueMap.has(id)) {
          usedKeySet.add(id);
          acc.push(keyValueMap.get(id) || {});
        }
        return acc;
      }, []);

      // n번 새로운 값 추가하기 (추가된 값이 있을 경우)
      data.forEach((item) => {
        if (!usedKeySet.has(item.CustomerOrderID)) {
          newRows.push(item);
        }
      });
      setRows(newRows);
    }
  }, [data]);

  //정렬순서 변경

  const handleChangeRows = (func: (data: any) => any) => {
    setRows(func);
  };
  useEffect(() => {
    setSelected([]);
  }, [brandStr, StartDate, EndDate]);

  //몇번째 줄이 수정중인지 파악함
  const handleSelectedRow = (cell: any) => {
    selectedRowId.current = cell.rowIdx;
  };

  //수정된 값을 서버로 보내어 업데이트함
  const handleUpdateCustomerInfo = async (newData: any, oldData: any) => {
    /**
     * newData : 새롭게 변경된 rows
     * oldData : 변경되기 전 row
     */
    const numberList = ["OrderCount", "CancelCount", "Quantity_Find", "마진"]; // 숫자로 들어가야만 하는 값들
    const CustomerOrderID = newData[oldData.indexes[0] || 0]["CustomerOrderID"];
    const key: any = oldData.column.key; // 키 값
    const index = oldData?.indexes?.[0] || 0; // index
    const value = numberList.includes(key) ? parseInt(newData[index][key]) || 0 : newData[index][key]; //값
    updateCustomerFindOrder([{ CustomerOrderID, [key]: value }]);
    setIsEdit(false);
    setRows(newData);
    // return newData;
  };

  //정렬할 데이터의 상태를 받아오는 함수
  const handleChangeSortType = (data: any) => {
    setIsEdit(false);

    const sortColumn = data as SortType[];
    // 이미 가지고 있는 정렬 컬럼이면 방향을 바꾸고 아니면 새로운 컬럼으로 정렬
    if (!sortColumn[0]) {
      setSortType({ ...sortType, direction: sortType.direction === "ASC" ? "DESC" : "ASC" });
    } else if (sortType.columnKey === sortColumn[0].columnKey) {
      setSortType({
        columnKey: sortType.columnKey,
        direction: sortType.direction === "ASC" ? "DESC" : "ASC",
      });
    } else {
      setSortType(sortColumn[0]);
    }
  };

  //필터를 적용함
  const filteredRows = useMemo(() => {
    const tempStatusMenoFilter = statusMenoFilter === "발송전" ? "" : statusMenoFilter;

    if (!rows) return [];
    if (filters === "" && tempStatusMenoFilter === "전체") return rows;
    const lowerCaseFilter = filters.toLowerCase();
    return rows
      .filter((row) => {
        return Object.values(row).some((value) => {
          const str = value ? value.toString().toLowerCase() : "";
          return str.includes(lowerCaseFilter);
        });
      })
      .filter((row) => {
        return row.StatusMeno === tempStatusMenoFilter || tempStatusMenoFilter === "전체";
      });
  }, [rows, filters, statusMenoFilter]);

  useEffect(() => {
    setRows((prev) => [...prev.sort((a, b) => tableSortFunc(a, b, sortType))]);
  }, [sortType]);

  // Summary Row 생성 => 여기서 요약을 만들고 column 의 renderSummaryCell에서 뽑는다.
  const summaryRows = useMemo((): any[] => {
    return [
      {
        totalCount: filteredRows?.length || 0,
      },
    ];
  }, [rows, filteredRows]);

  //컬럼 테이블 생성
  const columns: any[] = [
    {
      frozen: true,
      key: "체크박스",
      name: "체크박스",
      renderHeaderCell: () => {
        return (
          <Checkbox
            checked={(rows.length || 0) !== 0 && selected.length === (rows?.length || 0)}
            onChange={(e) => {
              if ((rows.length || 0) !== 0 && selected.length === (rows?.length || 0)) {
                setSelected([]);
              } else {
                setSelected(() => (rows ? rows.map((v) => v.CustomerOrderID) : []));
              }
            }}
          />
        );
      },
      headerCellClass: "center-header skyblue-header",
      renderCell: ({ row }: any) => {
        return (
          <Stack justifyContent={"space-around"} alignItems={"center"} sx={{ height: "100%" }}>
            <Checkbox
              className="center-header"
              checked={selected.includes(row.CustomerOrderID)}
              onChange={(e) => {
                setSelected((pre) =>
                  pre.includes(row.CustomerOrderID) ? [...pre.filter((v) => v !== row.CustomerOrderID)] : [...pre, row.CustomerOrderID]
                );
              }}
            />
          </Stack>
        );
      },
      renderSummaryCell: () => {
        return (
          <Typography textAlign={"center"} sx={{ lineHeight: "34px" }}>
            {selected.length}
          </Typography>
        );
      },
    },

    {
      key: "StatusMeno",
      headerCellClass: "skyblue-header",
      width: 110,
      name: "상태메모",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{row[column.key]}</AutoMultiCell>;
      },
      renderSummaryCell: ({ row }: any) => {
        return "총 : " + row.totalCount + " 개";
      },
    },
    {
      key: "FinishDate",
      name: "상태변경일",
      renderHeaderCell: () => <AutoMultiCell>상태 변경일</AutoMultiCell>,
      headerCellClass: "center-header",
      width: 80,
    },
    {
      key: "BrandStr",
      headerCellClass: "skyblue-header",
      width: 110,
      name: "브랜드",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{customerFindBrandOptions[row[column.key]]}</AutoMultiCell>;
      },
    },
    {
      key: "IncommingRoute",
      name: "주문경로",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{row[column.key]}</AutoMultiCell>;
      },
      width: 70,
    },
    {
      key: "OrderDate",
      name: "발주일",
      renderHeaderCell: () => <AutoMultiCell>발주일</AutoMultiCell>,
      headerCellClass: "center-header",
      minWidth: 100,
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{row[column.key].split("T")[0]}</AutoMultiCell>;
      },
    },
    {
      key: "OrderNumber_idx",
      name: "품목별 주문번호",
      renderHeaderCell: () => <AutoMultiCell>품목별 주문번호</AutoMultiCell>,
      headerCellClass: "center-header",
      width: 100,
      renderCell: ({ row }: any) => {
        return <ShowOderPeopleButton data={row} isError={row.OrderProductName !== row.ProductName_ko} />;
      },
    },
    {
      key: "BarCode",
      name: "상품코드",
      headerCellClass: "center-header",
      width: 120,
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{row[column.key]}</AutoMultiCell>;
      },
    },
    {
      key: "ProductName_ko",
      name: "상품명",
      renderHeaderCell: () => <AutoMultiCell>상품명</AutoMultiCell>,
      headerCellClass: "center-header",
      minWidth: 200,
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{row[column.key]}</AutoMultiCell>;
      },
    },

    {
      key: "Size",
      name: "사이즈",
      width: 40,
    },
    {
      key: "Color",
      name: "컬러",
      width: 80,
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{row[column.key]}</AutoMultiCell>;
      },
    },

    {
      key: "Quantity_Target",
      renderHeaderCell: () => <AutoMultiCell>찾을 수량</AutoMultiCell>,
      headerCellClass: "center-header",
      width: 40,
      renderCell: ({ row, column }: any) => {
        return <CenterCellRander>{row[column.key]}</CenterCellRander>;
      },
    },
    {
      key: "Quantity_Find",
      renderHeaderCell: () => <AutoMultiCell>찾은 수량</AutoMultiCell>,
      headerCellClass: "center-header skyblue-header",
      width: 40,

      renderCell: ({ row, column }: any) => {
        return <CenterCellRander>{row[column.key]}</CenterCellRander>;
      },
      renderEditCell: textEditor,
    },
    {
      key: "TotalCount",
      name: "재고",
      width: 40,
      headerCellClass: "center-header",
      renderCell: ({ row }: any) => {
        const { BarCode, TotalCount } = row;
        return <ShowInventoryButton BarCode={BarCode} TotalCount={TotalCount} />;
      },
    },
    {
      key: "MissingCount",
      name: "부족수량",
      renderHeaderCell: () => <AutoMultiCell>부족 수량</AutoMultiCell>,
      headerCellClass: "center-header",
      width: 40,
      renderCell: ({ row, column }: any) => {
        return <CenterCellRander>{row[column.key]}</CenterCellRander>;
      },
    },

    {
      key: "Detail_chungdo",
      name: "청도주문메모",
      renderHeaderCell: () => <AutoMultiCell>청도 주문메모</AutoMultiCell>,
      headerCellClass: "center-header skyblue-header",
      width: 100,
      ...MultiRowCell(),
    },

    {
      key: "OrderCount",
      name: "오더 수량",
      renderHeaderCell: () => <AutoMultiCell>오더 수량</AutoMultiCell>,
      width: 40,
      headerCellClass: "center-header skyblue-header",
      renderEditCell: textEditor,
      renderCell: ({ row, column }: any) => {
        return <CenterCellRander>{row[column.key]}</CenterCellRander>;
      },
    },
    {
      key: "CancelCount",
      name: "취소 수량",
      renderHeaderCell: () => <AutoMultiCell>취소 수량</AutoMultiCell>,
      headerCellClass: "center-header skyblue-header",
      width: 40,
      renderEditCell: textEditor,
      renderCell: ({ row, column }: any) => {
        return <CenterCellRander>{row[column.key]}</CenterCellRander>;
      },
    },
    {
      key: "Detail01",
      headerCellClass: "skyblue-header",
      name: "메모1",
      width: 200,
      ...MultiRowCell(),
    },

    {
      key: "Detail02",

      headerCellClass: "skyblue-header",
      name: "메모2",
      width: 200,
      ...MultiRowCell(),
    },
    {
      key: "PeopleName",
      headerCellClass: "skyblue-header",
      name: "수령인",
      width: 70,
      ...MultiRowCell(),
    },
    {
      key: "PeoplePhone",
      name: "수령인 전화번호",

      renderHeaderCell: () => <AutoMultiCell>수령인 전화번호</AutoMultiCell>,
      headerCellClass: "center-header skyblue-header",
      width: 100,
      // renderCell: ({ row, column }: any) => {
      //   return <AutoMultiCell>{row[column.key]}</AutoMultiCell>;
      // },
      ...MultiRowCell(),
    },
    {
      key: "PeopleAdress",
      name: "수령인 주소",
      renderHeaderCell: () => <AutoMultiCell>수령인 주소</AutoMultiCell>,
      headerCellClass: "center-header skyblue-header ",
      width: 180,
      // renderCell: ({ row, column }: any) => {
      //   return <AutoMultiCell>{row[column.key]}</AutoMultiCell>;
      // },
      ...MultiRowCell(),
    },
    {
      key: "PeopleMsg",
      name: "배송메시지",
      width: 100,
      renderHeaderCell: () => <AutoMultiCell>배송메시지</AutoMultiCell>,
      headerCellClass: "center-header",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{row[column.key]}</AutoMultiCell>;
      },
    },
    {
      key: "CJ_Number",
      name: "운송장번호",
      width: 100,
      renderHeaderCell: () => <AutoMultiCell>운송장 번호</AutoMultiCell>,
      headerCellClass: "center-header skyblue-header",
      ...MultiRowCell(),
    },

    {
      key: "Price",
      name: "상품 구매금액",
      renderHeaderCell: () => <AutoMultiCell>상품 구매금액</AutoMultiCell>,
      headerCellClass: "center-header",
      renderCell: ({ row, column }: any) => {
        return <NumberCell>{row[column.key]?.toLocaleString() || "-"}</NumberCell>;
      },
    },
    {
      key: "마진",
      renderHeaderCell: () => <AutoMultiCell>마진</AutoMultiCell>,
      headerCellClass: "center-header",
      renderCell: ({ row }: any) => {
        return <NumberCell>{row["마진"]?.toLocaleString() || "-"}</NumberCell>;
      },
    },
  ];

  const bulkInvoiceDispatch = () => {
    const submitData = rows
      .filter((v) => selected.includes(v.CustomerOrderID))
      .map((item: getCustomerOrderListResponseDto) => {
        return [item.OrderNumber, item.OrderNumber_idx, item.CJ_Number, item.PeopleName];
      });

    const result = [["주문번호", "품목별 주문번호", "운송장번호", "주문자"], ...submitData];
    invoiceExcelDownloader(result, `송장 뿌리기 [${getCurrentFormattedTime()}] .csv`);
  };

  const handleInvoiceExcelDownLoad = () => {
    const submitData = rows
      .filter((v) => selected.includes(v.CustomerOrderID))
      .map((item: getCustomerOrderListResponseDto) => {
        return [
          item.PeopleName,
          item.PeoplePhone,
          item.PeopleAdress,
          item.PeopleMsg,
          item.OrderProductName,
          `${item.Color}, ${item.Size}`,
          item.Quantity_Target,
        ];
      });

    invoiceExcelDownloader(submitData, `CJ 등록양식 [${getCurrentFormattedTime()}] .xlsx`);
  };

  //도8주문서 다운로드
  const handleExcelDownload = () => {
    const dopalData: any[] = [];

    rows.forEach((item: getCustomerOrderListResponseDto, i: number) => {
      if (item.MissingCount > 0) {
        dopalData.push({
          순위: dopalData.length + 1,
          발주번호: "",
          센터명: "",
          SKUID: "",
          바코드번호: item.BarCode,
          "상품명(옵션)": item.OrderProductName + " " + item.OrderProductOption,
          발주수량: "",
          확정수량: "",
          주문할수량: item.MissingCount,
        });
      }
    });

    // 도팔 주문서 다운로드
    if (dopalData.length !== 0) {
      customToast("info", "도8 주문서 다운로드");
      dopalExcelDownLoader(dopalData, `도8 주문서 [${getCurrentFormattedTime()}].xlsx`);
    } else {
      customToast("warn", "부족수량이 0 이상인 제품이 없습니다.");
    }
  };

  const handleColumnResize = useCallback(
    _.throttle((index, width) => {
      setWidthList((prev: number[]) => {
        const newWidthList = [...prev];
        newWidthList[index] = width;
        return newWidthList;
      });
    }, 400),
    [setWidthList, widthList]
  );

  const handleBulkDelete = () => {
    const Dto: deleteCustomerOrderDto[] = rows
      .filter((v) => selected.includes(v.CustomerOrderID))
      .map((v) => ({ OrderNumber_idx: v.OrderNumber_idx, CustomerOrderID: v.CustomerOrderID }));

    if (Dto.length !== 0 && confirm(`${Dto.length}개의 항목을 정말 삭제하시겠습니까?`)) {
      deleteCustomerFindeOrder(Dto);
      setSelected([]);
    }
    handleCloseSMenu();
  };

  const menuOpen = Boolean(menuAnchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const smenuOpen = Boolean(smenuAnchorEl);
  const handleOpenSmenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSMenuAnchorEl(event.currentTarget);
  };
  const handleCloseSMenu = () => {
    setSMenuAnchorEl(null);
  };

  const handleCustomerBrandChange = (e: SelectChangeEvent) => {
    setBrandStr(e.target.value as string);
  };
  const customerBrandOptions = useMemo(() => {
    return Object.keys(customerFindBrandOptions).map((key) => ({
      value: key,
      label: customerFindBrandOptions[key],
    }));
  }, [customerFindBrandOptions]);
  const statusColorOptions = useMemo(() => {
    const result: { [key: string]: string } = {};
    customerOrderStatus.forEach((v) => {
      result[v.value] = v.color;
    });
    return result;
  }, [customerOrderStatus]);

  const handleStatusMenoFilterChange = (e: SelectChangeEvent) => {
    setStatusMenoFilter(e.target.value as string);
  };

  // 겹치는 목록 리스트
  const duplicatedOrderNumberIndexList: string[] = useMemo(() => {
    const dataset = new Set<string>();
    const result = new Set<string>();

    rows.forEach((v) => {
      const value = v.OrderNumber_idx + " " + v.BarCode;
      if (dataset.has(value)) {
        result.add(value);
      } else {
        dataset.add(value);
      }
    });

    return Array.from(result);
  }, [rows]);


  return (
    <>
      <BackDropLoading open={isLoading} />
      <CustomTableContainer>
        <Stack>
          <Stack direction="row" gap={2} sx={{ p: 3, pt: 5, mb: 2, backgroundColor: "white", border: "1px solid #d9d9d9" }} alignItems={"center"}>
            {/* 타이틀 */}
            <Typography fontWeight="bold" fontSize="22px" sx={{ marginRight: "auto", ml: 3 }}>
              고객주문찾기
            </Typography>

            {/* 선택된 영역 더보기 버튼 */}
            {selected.length !== 0 && (
              <Button
                id="basic-button"
                aria-controls={smenuOpen ? "basic-menu" : undefined}
                aria-expanded={smenuOpen ? "true" : undefined}
                onClick={handleOpenSmenu}
                disabled={selected.length === 0}
                endIcon={<KeyboardArrowDownIcon />}
              >
                선택된 항목 [{selected.length || 0}]
              </Button>
            )}
            <Menu anchorEl={smenuAnchorEl} open={smenuOpen} onClose={handleCloseSMenu}>
              <MenuList>
                <StatusChangeButton selected={selected} onCashChange={handleChangeRows} />
                <Divider />
                <MenuItem onClick={bulkInvoiceDispatch}>
                  <ListItemIcon>
                    <VerticalAlignBottomIcon />
                  </ListItemIcon>
                  일괄 송장뿌리기
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleInvoiceExcelDownLoad}>
                  <ListItemIcon>
                    <DownloadIcon />
                  </ListItemIcon>
                  CJ 등록양식 출력
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleBulkDelete} sx={{ color: "red" }}>
                  <ListItemIcon>
                    <DeleteOutlineIcon color="error" />
                  </ListItemIcon>
                  일괄삭제
                </MenuItem>
              </MenuList>
            </Menu>

            {/* 검색창 */}
            <SearchInputField onChange={(e) => setFilters(e.target.value)} value={filters} />
            <SelectForm
              value={statusMenoFilter}
              label="상태메모"
              handleChange={handleStatusMenoFilterChange}
              options={customerOrderStatus2}
              fullWidth={false}
              size="small"
              sx={{ width: "150px" }}
            />
            {/* 날짜 */}
            {/* 브랜드 */}
            <SelectForm
              value={brandStr}
              label="브랜드"
              handleChange={handleCustomerBrandChange}
              options={customerBrandOptions}
              fullWidth={false}
              size="small"
              sx={{ width: "200px" }}
            />

            <DateRangeButton />

            {/* 새로고침 */}
            <IconButton
              onClick={() => {
                refetch();
              }}
            >
              <RefreshIcon />
            </IconButton>

            {/* 더보기 버튼 */}
            <IconButton
              id="basic-button"
              aria-controls={menuOpen ? "basic-menu" : undefined}
              aria-expanded={menuOpen ? "true" : undefined}
              onClick={handleOpenMenu}
            >
              <MoreVertOutlinedIcon />
            </IconButton>

            <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleCloseMenu}>
              <MenuList>
                <UploadCustomerOrderFileButton />

                <Divider />
                <UploadInvoiceFileButton />
                <Divider />
                <MenuItem onClick={handleExcelDownload}>
                  <Tooltip
                    title={
                      <pre style={{ fontSize: "12px" }}>
                        부족 수량이 0이상인
                        <br />
                        제품만 다운로드됩니다.
                      </pre>
                    }
                    arrow
                    placement="top"
                  >
                    <>
                      <ListItemIcon>
                        <DownloadIcon />
                      </ListItemIcon>
                      주문서 다운로드
                    </>
                  </Tooltip>
                </MenuItem>
                {admin.includes(UserName) && (
                  <>
                    <Divider />
                    <MarginSummaryButton />
                  </>
                )}
              </MenuList>
            </Menu>
          </Stack>
          {widthList.length !== 0 && (
            <DataGrid
              className="rdg-light"
              onSortColumnsChange={handleChangeSortType}
              style={{ height: "88vh", paddingBottom: "20px" }}
              rowHeight={(row) => autoRowHeight(row, 50)}
              onSelectedCellChange={handleSelectedRow}
              onRowsChange={handleUpdateCustomerInfo}
              columns={columns.map((column, i) => ({
                ...column,
                width: widthList[i],
              }))}
              sortColumns={sortType ? [sortType] : []}
              rows={filteredRows}
              topSummaryRows={summaryRows} // 하단 요약 행 추가
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
              onColumnResize={handleColumnResize}
              onCopy={handleCopy}
              onPaste={handlePaste}
              rowClass={(row: any) => {
                return `${statusColorOptions[row.StatusMeno?.slice(0, 6)]}-background`;
              }}
              onCellClick={(e) => {
                setIsEdit(true);
              }}
            />
          )}
        </Stack>
        {duplicatedOrderNumberIndexList.length !== 0 && <Alertbox list={duplicatedOrderNumberIndexList} />}
      </CustomTableContainer>
    </>
  );
};
export default CustomerFindOrderTable;

const admin = ["김범식", "방재희", "김선미", "문다연"];
