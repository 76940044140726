import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type ShowColumnToggle = {
  showColumnToggle: boolean;
  viewMode: string;
  preProductStatus: string;
  status: string;
  brand: string;
  searchText: string;
};

type ShowColumnToggleActions = {
  setShowColumnToggle: (data: boolean) => void;
  setViewMode: (data: string) => void;
  setStatus: (data: string) => void;
  setPreProductStatus: (data: string) => void;
  setBrand: (data: string) => void;
  setSearchText: (data: string) => void;
};

export const useProductListStateStore = create(
  persist<ShowColumnToggle & ShowColumnToggleActions>(
    (set) => ({
      showColumnToggle: false,
      viewMode: "table",
      preProductStatus: "",
      status: "",
      brand: "",
      searchText: "",
      setSearchText: (data: string) => {
        set({
          searchText: data,
        });
      },
      setBrand: (data: string) => {
        set({
          brand: data,
        });
      },
      setViewMode: (data: string) => {
        set({
          viewMode: data,
        });
      },
      setShowColumnToggle: (data: boolean) => {
        set({
          showColumnToggle: data,
        });
      },
      setStatus: (data: string) => {
        set({
          status: data,
        });
      },
      setPreProductStatus: (data: string) => {
        set({
          preProductStatus: data,
        });
      },
    }),
    {
      name: "PRODUCT_TABLE_HIDDEN_COLUMNS",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
