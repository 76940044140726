
export const KEY_LIST = [

    "IndividualOrderNumber",
    "SKU_ID",
    "BarCode",
    "ProductName_ko",
    "Size",
    "Color",
    "Status",
    "Memo0",//자동상품메모
    "Memo1",
    "Memo2",
    "Memo3",
    "TotalCount",
    "IncomingCount",
    "Quantity",
    "Quantity_OK",
    "BoxNumberList",
    "Quantity_Error",
    "Quantity_Ready",
    "IBCNumber",
  ];


  export const COLUMNS_LIST = [
    "발주번호",
    "SKU_ID",
    "상품바코드",
    "상품명",
    "사이즈",
    "컬러",
    "발주상태",
    "자동상품메모",
    "발주담당자메모",
    "청도주문메모",
    "기타메모",
    "재고현황",
    "입고현황",
    "발주수량",
    "확정수량",
    "박스번호",
    "준비수량",
    "오류수량",
    "IBC하차번호",
  ];
  

  export const INDIVIDUAL_ORDER_TABLE_COLUMNS: { [key: string]: number[] } = {
    flex: [50, 50, 50, 60, 180, 50, 50, 40, 60, 110, 110, 120, 110, 40, 40, 40, 40, 120, 40, 40, 40, 300, 60], 
    normal: [50, 50, 50, 60, 180, 50, 50, 60, 110, 110, 120, 110, 40, 40, 40, 40, 120, 40, 40, 60], 
    pre: [50, 50, 50, 60, 180, 50, 50, 60, 110, 110, 120, 110, 40, 40, 40, 40, 40, 120, 40, 40, 300, 60], 
  };