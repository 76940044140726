import { Stack } from "@mui/material";
import { ReactNode } from "react";

interface RowStackProps {
  sx?: any;
  children?: ReactNode;
  gap?: number;
  onClick?: any;
  justifyContent?: string;
}

const RowStack: React.FC<RowStackProps> = ({ sx = {}, children, gap = 0, onClick = () => {}, justifyContent = "space-between" }) => {
  return (
    <Stack onClick={onClick} direction="row" justifyContent={justifyContent}  gap={gap} alignItems="center" sx={sx}>
      {children}
    </Stack>
  );
};

export default RowStack;
