import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { brandIconOptions } from "../entities/side-navigation/const/brandIconsOption";

// const brandCodeToBrandName = () => {
//   const result: { [key: string]: string } = {};
//   brandIconOptions.forEach((v) => {
//     result[v.brandCode] = v.brandName;
//   });
//   return result;
// }

type brandCodeType = {
  BrandCode: string;
  BrandName: string;
  BrandImage: string;
};

type BrandCodeActions = {
  setBrandCode: (data: string) => void;
  clearBrandCode: () => void;
  getBrandCode: () => { BrandCode: string };
};

export const useBrandCodeStore = create(
  persist<brandCodeType & BrandCodeActions>(
    (set, get) => ({
      BrandCode: "PA",
      BrandName: "페이퍼먼츠",
      BrandImage: `../../../assets/image/brand-logo/p_icon001.png`,
      setBrandCode: (data: string) => {
        const brandName = brandIconOptions.find((v) => v.brandCode === data)?.brandName;
        const brandImage = brandIconOptions.find((v) => v.brandCode === data)?.imagePath;
        set({
          BrandCode: data,
          BrandName: brandName || "",
          BrandImage: brandImage || "",
        });
      },
      clearBrandCode: () => {
        set({
          BrandCode: "",
          BrandName: "",
          BrandImage: "",
        });
      },
      getBrandCode: () => {
        return {
          BrandCode: get().BrandCode,
        };
      },
    }),
    {
      name: "ATTERLIC_BRAND_INFO",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const { clearBrandCode, getBrandCode, setBrandCode } = useBrandCodeStore.getState();
