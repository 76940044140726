import { Grid, Card, Stack, Tooltip, IconButton, Typography, Divider, Checkbox, Switch, Menu, MenuItem, styled } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { copyToClicpboard } from "../lib/copyToClipBoard";
import { BarcodeAlertDto } from "../../../service/barcode-alert/types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useEffect, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { convertToKST } from "../lib/convertToKST";
import { useDeleteBarcodeAlert, useUpdateBarcodeAlert } from "../../../service/barcode-alert/barcodeAlertHooks";
import UpdateBarcodeAlertButton from "./update-barcode-alert-modal/UpdateBarcodeAlertButton";
import { DEFAULT_IMG } from "../../total-order/ui/modal/CardAlert";
import RowStack from "../../../shared/ui/RowStack";
interface Props {
  selected: boolean;
  onSelect: (id: number) => void;
  data: BarcodeAlertDto;
}

export const AlertItem: React.FC<Props> = ({ data, selected, onSelect }) => {
  const { id, barcode, create_at, create_user, desc_text, image_url, is_active, message, quantity } = data;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isActive, setIsActive] = useState<boolean>(!!is_active);
  const { mutate: deleteBarcodeAlert } = useDeleteBarcodeAlert();
  const { mutate: updateBarcodeAlert } = useUpdateBarcodeAlert(() => {});
  const open = Boolean(anchorEl);

  useEffect(() => {
    setIsActive(!!is_active);
  }, [is_active]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCopyClipboard = () => {
    copyToClicpboard(barcode || "");
  };

  const ClampedTypography = styled(Typography)({
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    // WebkitLineClamp: more ? 100 : 1,
    WebkitLineClamp: 3,
    overflow: "hidden",
  });

  const handleDelete = () => {
    deleteBarcodeAlert([id]);
  };

  const handleUpdateIsActive = () => {
    setIsActive(!isActive);
    updateBarcodeAlert([{ id, is_active: !isActive }]);
  };

  return (
    <Grid item xs={3}>
      <Card
        sx={{
          p: 6,
          backgroundColor: "#fff",
          // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.2s ease-in-out",
          "&:hover": {
            boxShadow: "0 8px 12px rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <Stack direction={"row"} alignItems="center">
          <Checkbox sx={{ marginRight: "auto" }} checked={selected} onChange={() => onSelect(id)} />
          <Tooltip title={<p>알람 활성화 상태를 나타냅니다.</p>} placement="top" arrow>
            <Switch checked={isActive} onChange={handleUpdateIsActive} />
          </Tooltip>
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <UpdateBarcodeAlertButton data={data} />
            <Divider />
            <MenuItem onClick={handleDelete} sx={{ color: "red" }}>
              <DeleteOutlineIcon sx={{ mr: 2 }} />
              삭제하기
            </MenuItem>
          </Menu>
        </Stack>
        <Stack gap={5} sx={{ pt: 4 }}>
          <img src={image_url ? image_url : DEFAULT_IMG} alt="alertImage" style={{ width: "60px", height: "60px" }} />
          <Stack gap={1} alignItems={"start"}>
            <Stack direction={"row"} gap={1} alignItems={"center"} sx={{ width: "100%" }}>
              <Typography fontSize={"18px"} color="primary">
                {barcode}
              </Typography>
              <Tooltip title={<p>클릭하면 바코드가 복사됩니다.</p>} placement="top" arrow>
                <IconButton onClick={handleCopyClipboard}>
                  <ContentCopyIcon fontSize="small" color="primary" />
                </IconButton>
              </Tooltip>
            </Stack>
            <ClampedTypography variant="subtitle1" color="#333">
              {message}
            </ClampedTypography>
            <ClampedTypography variant="subtitle2" color="#777">
              {desc_text}
            </ClampedTypography>
          </Stack>
          <Divider />

          <Stack justifyContent={"space-between"} sx={{ color: "#999" }} gap={2}>
            <RowStack sx={{ justifyContent: "start" }} gap={7}>
              <Typography variant="caption" sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <AccountCircleOutlinedIcon />
                {create_user}
              </Typography>
              <Typography variant="caption" sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <NumbersIcon />
                {quantity?.toLocaleString() || 0}장
              </Typography>
            </RowStack>
            <Typography variant="caption" sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <AccessTimeOutlinedIcon />
              {create_at ? convertToKST(create_at) : ""}
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );
};

export const MemoizedAlertItem = React.memo(AlertItem);
import NumbersIcon from "@mui/icons-material/Numbers";
