import { Stack, StackProps } from "@mui/material";
import React from "react";

interface Props extends StackProps {
  children: React.ReactNode;
}

export default function LinkCard({ children, ...rest }: Props) {
  return (
    <Stack
      bgcolor={"white"}
      borderRadius={2}
      py={7}
      px={7}
      gap={3}
      {...rest} // 추가된 속성을 Spread
    >
      {children}
    </Stack>
  );
}
