import { Card, Grid, Stack, Typography } from "@mui/material";
import { getOrderListResponseDto } from "../../../service/order/types";
import { Id } from "../../../shared/type/Id";
import { useMemo } from "react";
import CountUp from "../../../shared/ui/count-up/CountUp";

interface Props {
  data: (getOrderListResponseDto & Id)[] | undefined;
}

const OrderSummaryCard: React.FC<Props> = ({ data }) => {
  const { totalQuantity, totalQuantityOK, totalQuantityReady, totalOrderList } = useMemo(() => {
    let totalOrderList = 0;
    let totalQuantity = 0;
    let totalQuantityOK = 0;
    let totalQuantityReady = 0;

    if (!!data) {
      data.forEach((v: getOrderListResponseDto & Id) => {
        totalOrderList += 1;
        totalQuantity += v.sumQuantity;
        totalQuantityOK += v.sumQuantityOK;
        totalQuantityReady += v.sumQuantityReady;
      });
    }

    return { totalOrderList, totalQuantity, totalQuantityOK, totalQuantityReady };
  }, [data]);

  const cardOption = {
    boxSize: "border-box",
    height: 86,
    pt: 4,
  };

  return (
    <Grid container spacing={3} sx={{ height: 250, boxSizing: "border-box" }}>
      <Grid item xs={6}>
        <Card sx={cardOption}>
          <Stack gap={2}>
            <Typography variant="caption" color="#999">
              총 발주수량
            </Typography>
            <CountUp duration={500} end={totalQuantity} variant="h3" />
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card sx={cardOption}>
          <Stack gap={2}>
            <Typography variant="caption" color="#999">
              발주서 항목 수
            </Typography>
            <CountUp duration={500} end={totalOrderList} variant="h3" />
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card sx={cardOption}>
          <Stack gap={2}>
            <Typography variant="caption" color="#999">
              총 확정수량
            </Typography>
            <CountUp duration={500} end={totalQuantityOK} variant="h3" />
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card sx={cardOption}>
          <Stack gap={2}>
            <Typography variant="caption" color="#999">
              총 준비수량
            </Typography>
            <CountUp duration={500} end={totalQuantityReady} variant="h3" />
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
};

export default OrderSummaryCard;
